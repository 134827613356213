export const WAREHOUSE_TYPES = {
  APPLE: 'apple',
  PC: 'pc',
  MONITOR: 'monitor',
  CHAIR: 'chair',
  OTHER: 'other',
};

export const warehouseTypeOptions = [
  {
    value: WAREHOUSE_TYPES.APPLE,
    label: 'Apple'
  },
  {
    value: WAREHOUSE_TYPES.PC,
    label: 'PC'
  },
  {
    value: WAREHOUSE_TYPES.MONITOR,
    label: 'Monitor'
  },
  {
    value: WAREHOUSE_TYPES.CHAIR,
    label: 'Chair'
  },
  {
    value: WAREHOUSE_TYPES.OTHER,
    label: 'Other'
  }
];