import moment from 'moment';
import { useCallback, useState } from 'react';
import {formatDate} from '../helpers';


const useFormSubmit = (form, onSubmit, onSuccess, onError) => {
  const [ submitting, setSubmitting ] = useState(false);
  const handleSubmit = useCallback( values => {
    setSubmitting(true);
    for (const [key, value] of Object.entries(values)) {
      if (moment.isMoment(value)) {
        values[key] = formatDate(value);
      }
    }
    (async () => {
      const {error} = await onSubmit(values);
      setSubmitting(false);
      if (!error) {
        onSuccess();
      } else {
        if (onError) {
          onError(error);
        }
        if (error?.response?.data?.errors) {
          form.setFields(error.response.data.errors.map(error => ({...error, value: form.getFieldValue(error.name)})));
        }
      }
    })();
  }, [form, onSubmit, onSuccess]);

  return {
    submitting,
    handleSubmit
  }
}

export default useFormSubmit;
