const routes = {
  /* Auth */
  auth_login: {
    key: 'auth_login',
    title: 'Login',
    path: '/login',
  },
  auth_forgot_password: {
    key: 'auth_forgot_password',
    title: 'Recover password',
    path: '/forgot-password',
  },
  auth_reset_password: {
    key: 'auth_reset_password',
    title: 'New password',
    path: '/reset-password/:token',
  },
  auth_my_account: {
    key: 'auth_my_account',
    title: 'My Account',
    path: '/my-account',
  },
  /* Dashboard */
  dashboard: {
    key: 'dashboard',
    title: 'Dashboard',
    path: '/',
  },
  /* Dashboard */
  dashboard_hr: {
    key: 'dashboard_hr',
    title: 'Dashboard HR',
    path: '/hr',
  },
  /* WorkLog & Jobs */
  work_log_week_simple: {
    key: 'work_log_week_simple',
    title: 'WorkLog Week',
    path: '/work-log-simple/week',
  },
  work_log_week_consolidated: {
    key: 'work_log_week_consolidated',
    title: 'WorkLog Week',
    path: '/work-log-consolidated/week',
  },
  jobs_create: {
    key: 'jobs_create',
    title: 'Create job',
    path: '/work-log/jobs/create',
  },
  jobs_edit: {
    key: 'jobs_edit',
    title: 'Edit job',
    path: '/work-log/jobs/:id/edit',
  },
  work_log_total: {
    key: 'work_log_total',
    title: 'WorkLog Total',
    path: '/work-log/total',
  },
  /* Employees */
  employees: {
    key: 'employees',
    title: 'Employees',
    path: '/employees',
  },
  employees_archive: {
    key: 'employees_archive',
    title: 'Employees (Archive)',
    path: '/employees/archive',
  },
  employees_balances: {
    key: 'employees_balances',
    title: 'Employees (balances)',
    path: '/employees/balances',
  },
  employees_overtimes: {
    key: 'employees_overtimes',
    title: 'Employees (overtimes)',
    path: '/employees/overtimes',
  },
  employees_create: {
    key: 'employees_create',
    title: 'Create employee',
    path: '/employees/create',
  },
  employees_view: {
    key: 'employees_view',
    title: 'View employee',
    path: '/employees/:id',
  },
  employees_vacations: {
    key: 'employees_vacations',
    title: 'Scheduled Vacations',
    path: '/employees/:id',
  },
  employees_vacation_logs: {
    key: 'employees_vacation_logs',
    title: 'Vacation logs',
    path: '/employees/:id/logs',
  },
  employees_vacation_logs_create: {
    key: 'employees_vacation_logs_create',
    title: 'Create vacation log',
    path: '/employees/:id/logs/create',
  },
  employees_vacation_logs_edit: {
    key: 'employees_vacations_log_edit',
    title: 'Edit vacation log',
    path: '/employees/:id/logs/:log_id/edit',
  },
  employees_projects: {
    key: 'employees_projects',
    title: 'Projects',
    path: '/employees/:id/projects',
  },
  employees_comment: {
    key: 'employees_comment',
    title: 'Comment',
    path: '/employees/:id/comment',
  },
  employees_warehouses: {
    key: 'employees_warehouses',
    title: 'Warehouse',
    path: '/employees/:id/warehouses',
  },
  employees_edit: {
    key: 'employees_edit',
    title: 'Edit employee',
    path: '/employees/:id/edit',
  },
  /* Avatars */
  avatars: {
    key: 'avatars',
    title: 'Avatars',
    path: '/avatars',
  },
  avatars_create: {
    key: 'avatars_create',
    title: 'Create avatar',
    path: '/avatars/create',
  },
  avatars_edit: {
    key: 'avatars_edit',
    title: 'Edit avatar',
    path: '/avatars/:id/edit',
  },
  avatars_meta: {
    key: 'avatars_meta',
    title: 'Avatar meta',
    path: '/avatars/:id/meta',
  },
  avatars_meta_edit: {
    key: 'avatars_meta_edit',
    title: 'Edit avatar meta',
    path: '/avatars/:id/meta/edit',
  },
  avatars_passwords: {
    key: 'avatars_passwords',
    title: 'Avatar passwords',
    path: '/avatars/:id/passwords',
  },
  /* Vacations */
  vacations: {
    key: 'vacations',
    title: 'Scheduled vacations',
    path: '/vacations',
  },
  vacations_log: {
    key: 'vacations_log',
    title: 'Vacation log',
    path: '/vacations/log',
  },
  vacations_create: {
    key: 'vacations_create',
    title: 'Scheduled vacation',
    path: '/vacations/create',
  },
  vacations_log_create: {
    key: 'vacations_log_create',
    title: 'Log vacation',
    path: '/vacations/log/create',
  },
  vacations_edit: {
    key: 'vacations_edit',
    title: 'Edit vacation',
    path: '/vacations/:id/edit',
  },
  vacations_log_edit: {
    key: 'vacations_log_edit',
    title: 'Log vacation',
    path: '/vacations/log/:id/edit',
  },
  /* Clients */
  clients: {
    key: 'clients',
    title: 'Clients',
    path: '/clients',
  },
  clients_create: {
    key: 'clients_create',
    title: 'Create client',
    path: '/clients/create',
  },
  clients_edit: {
    key: 'clients_edit',
    title: 'Edit client',
    path: '/clients/:id/edit',
  },
  /* Applicants */
  applicants: {
    key: 'applicants',
    title: 'Applicants',
    path: '/applicants',
  },
  applicants_create: {
    key: 'applicants_create',
    title: 'Create applicant',
    path: '/applicants/create',
  },
  applicants_edit: {
    key: 'applicants_edit',
    title: 'Edit applicant',
    path: '/applicants/:id/edit',
  },
  applicants_comments: {
    key: 'applicants_comments',
    title: 'Applicants Comments',
    path: '/applicants/:id/comments'
  },
  /* Passwords */
  passwords: {
    key: 'passwords',
    title: 'Passwords',
    path: '/passwords',
  },
  passwords_create: {
    key: 'passwords_create',
    title: 'Create password',
    path: '/passwords/create',
  },
  passwords_edit: {
    key: 'passwords_edit',
    title: 'Edit password',
    path: '/passwords/:id/edit',
  },
  /* Users */
  users: {
    key: 'users',
    title: 'Users',
    path: '/users',
  },
  users_archive: {
    key: 'users_archive',
    title: 'Users archive',
    path: '/users/archive',
  },
  users_create: {
    key: 'users_create',
    title: 'Create user',
    path: '/users/create',
  },
  users_edit: {
    key: 'users_edit',
    title: 'Edit user',
    path: '/users/:id/edit',
  },
  /* Employee cipher link pages */
  employee_reports: {
    key: 'reports_create',
    title: 'Create report',
    path: '/reports/:cipher'
  },
  employee_reports_edit: {
    key: 'reports_edit',
    title: 'Edit report',
    path: '/reports/:cipher/work-logs/:id'
  },
  employee_personal_info: {
    key: 'personal_info',
    title: 'Personal info',
    path: '/personal-info/:cipher'
  },
  /* Holidays */
  holidays: {
    key: 'holidays',
    title: 'Holidays',
    path: '/holidays'
  },
  holidays_create: {
    key: 'holidays_create',
    title: 'Create Holiday',
    path: '/holidays/create'
  },
  holidays_edit: {
    key: 'holidays_edit',
    title: 'Edit Holiday',
    path: '/holidays/:id/edit'
  },
  faqs: {
    key: 'faqs',
    title: 'Faqs',
    path: '/faqs'
  },
  faqs_create: {
    key: 'faqs_create',
    title: 'Create Faq',
    path: '/faqs/create'
  },
  faqs_edit: {
    key: 'faqs_edit',
    title: 'Edit Faq',
    path: '/faqs/:id/edit'
  },
  /* Warehouses */
  warehouses: {
    key: 'warehouses',
    title: 'Warehouses',
    path: '/warehouses',
  },
  warehouses_create: {
    key: 'warehouses_create',
    title: 'Create warehouse',
    path: '/warehouses/create',
  },
  warehouses_edit: {
    key: 'warehouses_edit',
    title: 'Edit warehouse',
    path: '/warehouses/:id/edit',
  },
  wh: {
    key: 'wh',
    title: 'Wh',
    path: '/wh',
  },
}

export default routes;
