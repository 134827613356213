import {Spin, Typography} from 'antd';
import {useParams} from 'react-router-dom';
import routes from '../../../../../constants/routes';
import useFormLoader from '../../../../../hooks/useFormLoader';
import useFormHandlers from '../../../../../hooks/useFormHandlers';
import VacationLogForm from '../../../../../components/VacationLogForm';
import {fetchVacationLog, updateVacationLog} from '../../../../../store/vacation_logs/actions';

const VacationLogsEdit = () => {
  const { id, log_id: logId } = useParams();

  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
    action: updateVacationLog,
    successMessage: 'Log vacation updated successfully!',
    successPath: routes.employees_vacation_logs.path.replace(':id', id),
    actionParams: [logId]
  });
  const { data, loading } = useFormLoader(fetchVacationLog, logId);

  return (
    <>
      <Typography.Title level={3}>Log vacation</Typography.Title>
      { loading ? (
        <Spin/>
      ) : (
        <VacationLogForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          initialValues={data}
          employeeSelectDisabled={true}
        />
      ) }
    </>
  )
}

export default VacationLogsEdit;