import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CommentForm from '../CommentForm';
import SearchSelect from '../../SearchSelect';
import {Button, Col, InputNumber, Menu, Row, Space, Tooltip} from 'antd';
import {formatSelectInitialOption, hasAccessByRole} from '../../../helpers';
import {DollarCircleOutlined, ProjectTwoTone, ToolTwoTone} from '@ant-design/icons';
import {searchEmployeeOptions} from '../../../store/employees/actions';
import {ADMIN, PM_L1, PM_L2, PM_L3} from "../../../constants/roles";
import {accountSelector} from "../../../store/auth/selectors";


const MenuDropDown = (workLog, handleSelect, handleSubmit, handleSuccess, handleShowModal, handleDropdownVisibleChange) => {
  const dispatch = useDispatch();
  const handleSearch = (input => input.length > 1 && dispatch(searchEmployeeOptions(input)));

  const initialOptionManager = formatSelectInitialOption(workLog.manager);
  const initialOptionDeveloper = formatSelectInitialOption(workLog.employee);
  const { role } = useSelector(accountSelector);

  return (
    <Menu>
      {hasAccessByRole([ADMIN, PM_L1, PM_L2, PM_L3], role) ? (
      <><Menu.Item key='1'>
        <Row>
          <Col span={22} offset={2}>
            <Button block type='primary' onClick={() => {
              handleShowModal();
              handleDropdownVisibleChange();
            }}>
              Details
            </Button>
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item key='2'>
        <Space>
          <Tooltip title='Manager'>
            <ProjectTwoTone style={{fontSize: '16px'}}/>
          </Tooltip>
          <SearchSelect
            placeholder='Manager'
            onSearch={handleSearch}
            defaultOption={initialOptionManager}
            defaultValue={workLog.manager.id}
            onSelect={ id => {
              if (workLog.manager.id !== id) {
                return handleSelect({manager_id: id});
              }
            }}
            style={{ width: 250 }}
          />
        </Space>
      </Menu.Item>
      <Menu.Item key='3'>
        <Space>
          <Tooltip title='Employee'>
            <ToolTwoTone style={{fontSize: '16px'}}/>
          </Tooltip>
          <SearchSelect
            placeholder='Employee'
            onSearch={handleSearch}
            defaultOption={initialOptionDeveloper}
            defaultValue={workLog.employee.id}
            onSelect={ id => {
              if (workLog.employee.id !== id) {
                return handleSelect({employee_id: id});
              }
            }}
            style={{ width: 250 }}
          />
        </Space>
      </Menu.Item>
      <Menu.Item key='4'>
        <Space>
          <Tooltip title='Rate'>
            <DollarCircleOutlined style={{fontSize: '16px'}}/>
          </Tooltip>
          <InputNumber
            step="0.01"
            name='rate'
            defaultValue={workLog.rate}
            style={{ width: 250 }}
            placeholder='Rate'
            onChange={
              rate => {
                if (workLog.rate !== rate) {
                  return handleSelect({rate: rate});
                }
              }
            }
          />
        </Space>
      </Menu.Item>
      </>):(<></>)}
      <Menu.Item key='5'>
        <Row>
          <Col span={22} offset={2}>
            <CommentForm
              onSubmit={handleSubmit}
              onSuccess={handleSuccess}
            />
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  );
}

export default MenuDropDown;