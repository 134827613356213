import React, { Fragment, useCallback, useMemo } from 'react';
import { Button, Menu, PageHeader } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import Logo from '../Logo';
import routes from '../../constants/routes';
import navMenuItems from '../../constants/navMenuItems';
import { logout } from '../../store/auth/actions';
import { accountSelector } from '../../store/auth/selectors';
import {searchEmployeeOptions} from "../../store/employees/actions";
import SearchSelect from "../SearchSelect";
import withRole from "../../hoc/withRole";
import {ADMIN, HR_L1, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2} from "../../constants/roles";


const NavBar = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { role } = useSelector(accountSelector);
  const selectedKeys = useMemo(() => {
    const pathToFind = location.pathname || '/';
    const key = Object.keys(routes).find(key => pathToFind === routes[key].path);
    return [key];
  }, [location.pathname]);

  const handleMenuClick = useCallback(key => history.push(routes[key].path), [history]);
  const handleLogoutClick = useCallback(() => dispatch(logout()), [dispatch]);
  const handleLogoClick = useCallback(() => handleMenuClick(navMenuItems[role][0].key), [handleMenuClick, role]);
  const handleSearchEmployee = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [dispatch]);
  const handleChangeEmployee = useCallback(employee_id => employee_id && history.push(
      routes.employees_view.path.replace(':id', employee_id)
  ), [history]);
  const SearchEmployeeSelect = withRole([ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2, HR_L1 ])(SearchSelect);
  return (
    <PageHeader
      title={<Logo onClick={handleLogoClick}/>}
      extra={
        <>
          <SearchEmployeeSelect
            placeholder='Employee'
            onSearch={handleSearchEmployee}
            onChange={handleChangeEmployee}
            style={{width: 180}}
          />
          <Button key='user-profile' type='primary' onClick={() => handleMenuClick(routes.auth_my_account.key)}>
            My Account
          </Button>
          <Button key='logout' onClick={handleLogoutClick}>Logout</Button>
        </>
      }
    >
      <Menu onClick={(e) => handleMenuClick(e.key)}  selectedKeys={selectedKeys} mode='horizontal'>
        { navMenuItems[role].map(({ key, title, submenu }, index) => (
          <Fragment key={index}>
            { submenu ? (
              <Menu.SubMenu key={index} title={title} onTitleClick={() => handleMenuClick(submenu[0].key)}>
                { submenu.map(({ key, title }) => (
                  <Menu.Item key={key}>
                    {title}
                  </Menu.Item>
                )) }
              </Menu.SubMenu>
            ) : (
              <Menu.Item key={key}>
                {title}
              </Menu.Item>
            ) }
          </Fragment>
        )) }
      </Menu>
    </PageHeader>
  )
};

export default NavBar;