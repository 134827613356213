import { APP_TABLE_DEFAULT_SIZE } from '../constants/config';


const mapUrlSearchParams = (pagination, filters, sorter) => ({
  sort_by: sorter.order && sorter.field,
  order_by: sorter.order?.slice(0, -3), // 'ascend' -> 'asc', 'descend' -> 'desc'
  page: pagination?.current > 1 ? pagination.current : null,
  per_page: pagination?.pageSize !== APP_TABLE_DEFAULT_SIZE ? pagination.pageSize : null
});


export default mapUrlSearchParams;