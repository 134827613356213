import { createAction } from 'redux-smart-actions';

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchVacationLogsList = createAction('VACATION_LOGS/FETCH_LIST',(searchParams = '') => ({
  request: {
    url: `/vacation-logs?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
    cache: 5,
  },
}));

/**
 * @param {Object} data
 * @param {number} data.employee_id
 * @param {string} data.type
 * @param {string} data.date
 * @param {string} data.comment
 */
export const createVacationLog = createAction('VACATION_LOGS/CREATE', data => ({
  request: {
    url: '/vacation-logs',
    method: 'POST',
    data
  },
}));

/**
 * @param {number} id
 */
export const fetchVacationLog = createAction('VACATION_LOGS/FETCH', id => ({
  request: {
    url: `/vacation-logs/${id}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 * @param {Object} data
 * @param {number} data.employee_id
 * @param {string} data.type
 * @param {string} data.date
 * @param {string} data.comment
 */
export const updateVacationLog = createAction('VACATION_LOGS/UPDATE', (id, data) => ({
  request: {
    url: `/vacation-logs/${id}`,
    method: 'PUT',
    data,
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 */
export const deleteVacationLog = createAction('VACATION_LOGS/DELETE', id => ({
  request: {
    url: `/vacation-logs/${id}`,
    method: 'DELETE',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {string} employeeCipherLink
 * @param {URLSearchParams} [searchParams]
 */
export const fetchVacationLogsListByCipher = createAction('VACATION_LOGS/FETCH_LIST/CIPHER', (cipher, searchParams= '') => ({
  request: {
    url: `/profile/${cipher}/vacation-logs?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: cipher + searchParams.toString(),
  },
}));

export const fetchMissedVacationLogsList = createAction('VACATION_LOGS/MISSED/FETCH_LIST', () => ({
  request: {
    url: `/vacation-logs/missed`,
    method: 'GET',
  }
}));

/**
 * @param {string} employeeCipherLink
 * @param {URLSearchParams} [searchParams]
 */
export const fetchWarehousesListByCipher = createAction('WAREHOUSES/FETCH_LIST/CIPHER', (cipher, searchParams= '') => ({
  request: {
    url: `/profile/${cipher}/warehouses?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: cipher + searchParams.toString(),
  },
}));