import React, { useCallback, useMemo } from 'react';
import {useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import useColumns from './hooks/useColumns';
import routes from '../../constants/routes';
import { Button, Space, Table, Spin } from 'antd';
import usePagination from '../../hooks/usePagination';
import SearchSelect from '../../components/SearchSelect';
import useSearchParams from '../../hooks/useSearchParams';
import {accountSelector} from '../../store/auth/selectors';
import { joinSearchString } from '../work_log/tableHelpers';
import mapUrlSearchParams from '../../utils/mapUrlSearchParams';
import { searchAvatarOptions } from '../../store/avatars/actions';
import { useQuery, useDispatchRequest } from '@redux-requests/react';
import {deletePassword, fetchPasswordsList, searchUrlOptions} from '../../store/passwords/actions';


const PasswordsPage = () => {
  const query = '';
  const dispatch = useDispatchRequest();
  const { role } = useSelector(accountSelector);
  const [ searchParams, setSearchParams ] = useSearchParams();

  /* URLs filter */
  const arrayOfUrlIds = searchParams.getAll('urls[]');
  const stringUrlIds = useMemo(() => joinSearchString(arrayOfUrlIds, 'urls'), [arrayOfUrlIds]);

  const handleSearchUrl= useCallback(input => input.length > 1 && dispatch(searchUrlOptions(input)), [ dispatch ]);
  const handleChangeUrl = useCallback(urls => setSearchParams({ 'urls[]': urls }), [setSearchParams]);
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  /* Avatar filter */
  const arrayOfAvatarIds = searchParams.getAll('avatar_ids[]');
  const stringAvatarIds = useMemo(() => joinSearchString(arrayOfAvatarIds, 'avatar_ids'), [arrayOfAvatarIds]);
  const newAvatarIds = useMemo(() => arrayOfAvatarIds.map( id => (+id)), [arrayOfAvatarIds]);

  const handleSearchAvatar = useCallback(input => input.length > 1 && dispatch(searchAvatarOptions(input)), [ dispatch ]);
  const handleChangeAvatar = useCallback(ids => setSearchParams({ 'avatar_ids[]': ids }), [setSearchParams]);


  /* Avatars data */
  const { data: urls, loading: urlsLoading } = useQuery({
    type: searchUrlOptions,
    action: searchUrlOptions,
    variables: [query, stringUrlIds],
    requestKey: query + stringUrlIds,
    autoLoad: !!arrayOfUrlIds.length
  });

  /* Avatars data */
  const { data: avatars, loading: avatarsLoading } = useQuery({
    type: searchAvatarOptions,
    action: searchAvatarOptions,
    variables: [query, stringAvatarIds],
    requestKey: query + stringAvatarIds,
    autoLoad: !!arrayOfAvatarIds.length
  });

  /* Main data */
  const { data, loading, load } = useQuery({
    type: fetchPasswordsList,
    action: fetchPasswordsList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
  });

  const pagination = usePagination(data?.meta);
  const handleDelete = useCallback(id => dispatch(deletePassword(id)).then(() => load()), [dispatch, load]);
  const columns = useColumns(handleDelete, role);

  return (
    <>
      <div className='section-filters'>
        <Space>
          <SearchSelect
              mode='multiple'
              placeholder='Avatar'
              onSearch={handleSearchAvatar}
              onChange={handleChangeAvatar}
              value={avatarsLoading ? <Spin size="small" /> : newAvatarIds}
              defaultOption={avatars}
              style={{ width: 150 }}
          />
          <SearchSelect
            mode='multiple'
            placeholder='Url'
            onSearch={handleSearchUrl}
            onChange={handleChangeUrl}
            value={urlsLoading ? <Spin size="small" /> : arrayOfUrlIds}
            defaultOption={urls}
            style={{ width: 220 }}
            maxTagCount='responsive'
            allowClear
          />
        </Space>
        <Link to={routes.passwords_create.path} style={{ marginLeft: 'auto' }}>
          <Button type='primary'>Create</Button>
        </Link>
      </div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.data}
        pagination={pagination}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
        scroll={{ x: 1300 }}
      />
    </>
  );
};

export default PasswordsPage;