import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useMemo} from 'react';
import useColumns from './hooks/useColumns';
import {useQuery} from '@redux-requests/react';
import {Link, useParams} from 'react-router-dom';
import routes from '../../../../constants/routes';
import usePagination from '../../../../hooks/usePagination';
import {typeOptions} from '../../../../constants/vacations';
import useSearchParams from '../../../../hooks/useSearchParams';
import mapUrlSearchParams from '../../../../utils/mapUrlSearchParams';
import {deleteVacationLog} from '../../../../store/vacation_logs/actions';
import {
  fetchEmployeeVacationBalance,
  fetchEmployeeSickLeaveBalance,
  fetchEmployeeVacationLogsList
} from '../../../../store/employees/actions';
import {formatDate} from '../../../../helpers';
import {hasAccessByRole} from '../../../../helpers';
import {accountSelector} from '../../../../store/auth/selectors';
import {ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1} from '../../../../constants/roles';
import {Button, Card, Col, DatePicker, Divider, Row, Select, Space, Spin, Statistic, Table} from 'antd';


const VacationLogs = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const { role } = useSelector(accountSelector);
  const isAccessRole = useMemo(() => hasAccessByRole([ADMIN, TECH_L1, PM_L1, PM_L2, PM_L3], role), []);
  const isAdmin = useMemo(() => hasAccessByRole([ADMIN], role), []);
  const [searchParams, setSearchParams] = useSearchParams();

  const from = searchParams.get('from');
  const fromDate = useMemo(() => from && moment(from), [from]);
  const to = searchParams.get('to');
  const toDate = useMemo(() => to && moment(to), [to]);
  const handleChangeTypes = useCallback(types => setSearchParams({['types[]']: types}), [setSearchParams]);
  const handleChangeFrom = useCallback(
    fromDate => setSearchParams({from: fromDate ? formatDate(fromDate) : null}),
    [setSearchParams]
  );
  const handleChangeTo = useCallback(
    toDate => setSearchParams({to: toDate ? formatDate(toDate) : null}),
    [setSearchParams]
  );
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const {data: vacationLogs, loading: vacationLogsLoading, load: vacationLogsLoad} = useQuery({
    type: fetchEmployeeVacationLogsList,
    action: fetchEmployeeVacationLogsList,
    variables: [id, searchParams],
    requestKey: id + searchParams.toString(),
    autoLoad: true,
  });

  const {data: vacationBalance, load: vacationBalanceLoad} = useQuery({
    type: fetchEmployeeVacationBalance,
    action: fetchEmployeeVacationBalance,
    variables: [id],
    requestKey: id,
    autoLoad: true,
  });

  const {
    data: sickLeaveBalance,
    loading: sickLeaveBalanceLoading,
    pristine: sickLeaveBalancePristine,
    load: sickLeaveBalanceLoad
  } = useQuery({
    type: fetchEmployeeSickLeaveBalance,
    action: fetchEmployeeSickLeaveBalance,
    variables: [id],
    requestKey: id,
    autoLoad: true,
  });

  const pagination = usePagination(vacationLogs?.meta);
  const handleDelete = useCallback(id => dispatch(deleteVacationLog(id)).then(() => {
    vacationLogsLoad().catch(console.log);
    vacationBalanceLoad().catch(console.log);
    sickLeaveBalanceLoad().catch(console.log);
  }), [dispatch, vacationLogsLoad, vacationBalanceLoad, sickLeaveBalanceLoad]);
  const columns = useColumns(id, handleDelete, isAccessRole, isAdmin).filter(column => !column.hidden);

  if (sickLeaveBalanceLoading || sickLeaveBalancePristine) {
    return <Spin/>;
  }

  return (
    <>
      <Divider />
      <div>
        <Row gutter={16}>
          <Col span={12}>
            <Card>
              <Statistic
                  title="Sick leave"
                  value={sickLeaveBalance}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                  title="Available days"
                  value={vacationBalance}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <Divider />
      <div className='section-filters'>
        <Space>
          <Select
            placeholder='Type'
            value={searchParams.getAll('types[]')}
            options={typeOptions}
            onChange={handleChangeTypes}
            mode='multiple'
            maxTagCount='responsive'
            style={{width: 220}}
            allowClear
          />
          <DatePicker value={fromDate} placeholder='From' onChange={handleChangeFrom}/>
          <DatePicker value={toDate} placeholder='To' onChange={handleChangeTo}/>
        </Space>
        {isAccessRole && <Link
          to={routes.employees_vacation_logs_create.path.replace(':id', id)}
          style={{ marginLeft: 'auto' }}>
          <Button type='primary'>Create</Button>
        </Link>}
      </div>
      <Table
        columns={columns}
        loading={vacationLogsLoading}
        dataSource={vacationLogs?.data}
        pagination={pagination}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
        scroll={{ x: 1300 }}
      />
    </>
  )
}

export default VacationLogs;