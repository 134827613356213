import moment from 'moment';
import React, { useMemo } from 'react';
import { Button, Space } from 'antd';
import remarkGfm from 'remark-gfm';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import routes from '../../../constants/routes';
import { TIME_FORMAT, REPORT_ALLOWED_RANGE_DAYS } from '../../../constants/config';
import { CalendarOutlined, UserOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import {formatDate} from '../../../helpers';

const useColumns = (job, cipher, jobOpened) => useMemo(() => {
  const maxDate = moment().endOf('day');
  const minDate = maxDate.clone().subtract(REPORT_ALLOWED_RANGE_DAYS, 'days');

  return [
    {
      dataIndex: 'report',
      key: 'report',
      render: report => <ReactMarkdown remarkPlugins={[remarkGfm]} >{report}</ReactMarkdown>,
      className: 'ant-descriptions-item'
    },
    {
      key: 'info',
      render: (_, { date, employee, time }) => (
        <Space direction='vertical' size={4}>
          <Space><CalendarOutlined /> {formatDate(moment(date))}</Space>
          <Space><UserOutlined /> {employee.full_name}</Space>
          <Space><ClockCircleOutlined /> {formatDate(moment('00:00', TIME_FORMAT).add(Math.round(time * 60), 'minutes'), TIME_FORMAT)}</Space>
        </Space>
      ),
      width: 210,
      className: 'ant-descriptions-item'
    },
    {
      dataIndex: 'id',
      key: 'actions',
      render: (id, { employee, date }) => {
        const cantEdit = jobOpened
          ? !moment(date).isBetween(minDate, maxDate) || job.employee.id !== employee.id
          : !jobOpened;

        return (
          <Link to={routes.employee_reports_edit.path.replace(':cipher', cipher).replace(':id', id)}>
            <Button type='link' size='small' icon={<EditOutlined/>} disabled={cantEdit}/>
          </Link>
        )
      },
      align: 'right',
      width: 40,
      className: 'ant-descriptions-item'
    }
  ];
}, [job, cipher, jobOpened]);

export default useColumns;