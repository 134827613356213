import React, { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@redux-requests/react';
import {
  avgWeeklyRate,
  formatToFixed,
  getAvgRateJob,
  getAvgRateJobConsolidated,
  getAvgRateWl,
  getAvgRateWlConsolidated,
  getTotalWeek,
  getTotalWeekConsolidated,
} from '../tableHelpers';
import {
  formatDate,
  getDate,
  getLabel, getStartOfMonth,
  hasAccessByRole,
} from '../../../helpers';
import {Button, DatePicker, Descriptions, Popover, Space, Table} from 'antd';
import {fetchJobsTotalList} from '../../../store/jobs/actions';
import useSearchParams from '../../../hooks/useSearchParams';
import mapUrlSearchParams from '../../../utils/mapUrlSearchParams';
import WorkLogTableFilter from '../../../components/WorkLogTableFilter/groupBy';
import '../styles/style.scss';
import {ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2} from "../../../constants/roles";
import { useSelector } from "react-redux";
import { accountSelector } from "../../../store/auth/selectors";
import {GROUPS, platformOptions} from "../../../constants/jobs";
import {domains} from "../../../constants/employees";
import {Link} from "react-router-dom";
import routes from "../../../constants/routes";
import moment from "moment";
import {EditOutlined, InfoCircleOutlined} from "@ant-design/icons";
import _ from "lodash";

export const EditableContext = React.createContext(null);
const { RangePicker } = DatePicker;

const WorkLogWeekConsolidatedPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  /* hook add default dates range (current week) */
  useEffect(() => {
    if (!searchParams.has('start_date') || !searchParams.has('end_date')) {
      setSearchParams({start_date: formatDate(getStartOfMonth()), end_date: formatDate(moment())});
    }
  }, [searchParams, setSearchParams]);

  const startDate = searchParams.get('start_date');
  const endDate = searchParams.get('end_date');

  const groupBy = searchParams.get('group_by');


  /* filter onChange */
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );
  const handleChangeRangePicker = useCallback(
    ([startDate, endDate]) => {
      setSearchParams({start_date: formatDate(startDate), end_date: formatDate(endDate)})
    },
    [setSearchParams]
  );

  const {data, loading} = useQuery({
    type: fetchJobsTotalList,
    action: fetchJobsTotalList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
  });

  const AvatarLink = avatar => {
    const { role } = useSelector(accountSelector);
    return hasAccessByRole([ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2], role) ? (
      <Link to={routes.avatars_meta.path.replace(':id', avatar.id)}>{avatar.short_name}</Link>) : avatar.short_name;
  };

  const EditJobLink = (props) => {
    const { role } = useSelector(accountSelector);
    return hasAccessByRole([ADMIN, PM_L1, PM_L2, PM_L3], role) ? (<Link {...props}>{props.children}</Link>) : null;
  };

  const {role, employee: {id: uid}} = useSelector(accountSelector);
  const hasAccess = hasAccessByRole([ADMIN, PM_L1, PM_L2, PM_L3], role);

  useEffect(() => {
    if (role === PM_L2 || role === PM_L3) {
      setSearchParams({'manager_ids[]': uid, 'group_by': GROUPS.MANAGER})
    }
    else {
      !groupBy && setSearchParams({'group_by': GROUPS.PLATFORM});
    }
  }, [groupBy, setSearchParams]);

  const jobsList = useMemo(() => {
    let _groupBy = (dataToGroupOn, fieldNameToGroupOn, fieldNameForGroupName, fieldNameForChildren) => {
      return _.chain(dataToGroupOn)
        .groupBy(fieldNameToGroupOn)
        .toPairs()
        .map(function (currentItem) {
          let newItem = _.zipObject([fieldNameForGroupName, fieldNameForChildren], currentItem);
          newItem.groupByKey = fieldNameToGroupOn;
          return newItem;
        })
        .value();
    }
    let res = [];
    switch (groupBy) {
      case 'type':
      case 'platform': {
        res = _groupBy(data?.data, groupBy, 'groupBy', 'data');
      } break;
      case 'job_status': {
        res = _groupBy(data?.data, 'status', 'groupBy', 'data');
      } break;
      case 'avatar': {
        res = _groupBy(data?.data, groupBy+'.short_name', 'groupBy', 'data');
      } break;
      case 'client':
      case 'employee':
      case 'manager': {
        res = _groupBy(data?.data, groupBy+'.full_name', 'groupBy', 'data');
      } break;
      case 'officer': {
        res = _groupBy(data?.data, 'employee.'+groupBy+'.full_name', 'groupBy', 'data');
      } break;
    }
    return res;
  }, [data?.data]);

  const expandedRowRender = (row) => {
    let columns = [
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
      },
      {
        title: 'Platform',
        dataIndex: 'platform',
        key: 'platform',
        sorter: true,
        render: (platform) => getLabel(platformOptions, platform)
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (type) => getLabel(domains, type),
      },
      {
        title: 'Avatar',
        dataIndex: 'avatar.short_name',
        key: 'avatar',
        render: (short_name, {avatar}) => AvatarLink(avatar),
      },
      {
        title: 'Client',
        dataIndex: 'client.full_name',
        key: 'client',
        render: (full_name, {client} ) => client?.full_name ?? '',
      },
      {
        title: 'Job name',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Manager',
        dataIndex: 'manager.full_name',
        key: 'manager',
        render: (full_name, {manager} ) => (
          manager && <Link to={`${routes.employees_view.path.replace(':id', manager.id)}`}>{manager?.full_name}</Link>
        ),
      },
      {
        title: 'Employee',
        dataIndex: 'employee.full_name',
        key: 'employee',
        render: (full_name, {employee}) => (
          employee && <Link to={`${routes.employees_view.path.replace(':id', employee.id)}`}>{employee?.full_name}</Link>
        ),
      },
      {
        title: 'Officer',
        dataIndex: 'employee.officer.full_name',
        key: 'employee',
        render: (full_name, {employee}) => (
          employee && <Link to={`${routes.employees_view.path.replace(':id', employee?.officer?.id)}`}>{employee?.officer?.full_name}</Link>
        ),
      },
      {
        title: 'Total',
        dataIndex: 'work_logs',
        key: 'total',
        render: (work_logs) => {
          return formatToFixed(work_logs.reduce((total, { time }) => total + time, 0));
        },
        width: 50,
        align: 'center',
      },
      {
        title: ' Avg',
        dataIndex: 'rate',
        key: 'rate',
        render: (rate, {work_logs}) => {
          return avgWeeklyRate(work_logs);
        },
        align: 'center',
        className: 'hidden',
      },
      {
        title: ' Rate',
        dataIndex: 'rate',
        key: 'rate',
        render: (rate) => {
          return formatToFixed(rate);
        },
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'actions',
        render: (id, { cipher_link, origin_id, comment,
          start_date, end_date}) => {
          if (cipher_link) {
            return (
              <Space>
                <EditJobLink to={routes.jobs_edit.path.replace(':id', origin_id ?? id)}>
                  <Button type='link' size='small' icon={<EditOutlined />} />
                </EditJobLink>
                <Popover content={<Descriptions style={{ width: 500 }} size="small" layout="vertical" bordered>
                  <Descriptions.Item label="Comment" span={3}>{comment}</Descriptions.Item>
                  <Descriptions.Item label="Start date">{start_date}</Descriptions.Item>
                  <Descriptions.Item label="End date">{end_date}</Descriptions.Item>
                </Descriptions>} title="Job Info" trigger="click"  placement="left">
                  <Button type='text' size='small' icon={<InfoCircleOutlined />} />
                </Popover>
              </Space>
            );
          }
        },
        align: 'center',
        fixed: 'right'
      }
    ]
    if(!hasAccess) {
      columns = columns.filter(col => col.dataIndex !== 'rate')
    }
    columns = columns.filter(col => col.dataIndex !== row.groupByKey);
    return <Table
      columns={columns}
      loading={loading}
      dataSource={row.data}
      pagination={false}
      onChange={handleChangeTable}
      rowKey='id'
      size='small'
      bordered
      summary={data => {
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={8} align="left">Total</Table.Summary.Cell>
              <Table.Summary.Cell>
                {getTotalWeek(data)}
              </Table.Summary.Cell>
              {hasAccess && <Table.Summary.Cell>
                {getAvgRateJob(data)}
              </Table.Summary.Cell>}
              {hasAccess && <Table.Summary.Cell>
                {getAvgRateWl(data)}
              </Table.Summary.Cell>}
              <Table.Summary.Cell />
            </Table.Summary.Row>
          </>
        );
      }}

      className='worklog_table'
    />
  }



  let columns = [
    {
      title: groupBy,
      dataIndex: 'groupBy',
      key: 'groupBy',
      sorter: true,
      render(text, record) {
        return {
          children: text + ' (' + record?.data.length + ')'
        };
      }
    },
    {
      title: 'Total',
      dataIndex: 'work_logs',
      key: 'total',
      render: (text, {data}) => {
        return getTotalWeek(data)
      },
      align: 'center',
    },
    {
      title: ' Avg',
      dataIndex: 'rate',
      key: 'rate',
      render: (text, {data}) => {
        return getAvgRateWl(data);
      },
      align: 'center',
      className: 'hidden',
      hidden: true
    },
    {
      title: ' Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (text, {data}) => {
        return getAvgRateJob(data);
      },
      align: 'center',
      className: 'hidden',
      hidden: true
    },
  ]
  if(!hasAccess) {
    columns = columns.filter(col => col.dataIndex !== 'rate')
  }


  return (
    <>
      <WorkLogTableFilter
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        rightSectionFilters={
          <RangePicker style={{width: 225}} value={[getDate(startDate), getDate(endDate)]} onChange={handleChangeRangePicker} allowClear={false}/>
        }
      />
      <Table
        columns={columns}
        loading={loading}
        dataSource={jobsList}
        pagination={false}
        onChange={handleChangeTable}
        rowKey='groupBy'
        size='small'
        bordered
        expandable={{ expandedRowRender }}
        className='worklog_table'
        summary={data => {

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>Total</Table.Summary.Cell>
                <Table.Summary.Cell>
                  {getTotalWeekConsolidated(data)}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {getAvgRateWlConsolidated(data)}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {getAvgRateJobConsolidated(data)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}

      />
    </>
  )
};

export default WorkLogWeekConsolidatedPage;