import authToken from '../../utils/authToken';
import { createAction } from 'redux-smart-actions'

/**
 * Custom action for manual set SET_LOGGED_IN
 */
export const setLoggedIn = createAction('AUTH/SET_LOGGED_IN');

/**
 * @param {Object} data
 * @param {string} data.email
 * @param {string} data.password
 */
export const login = createAction('AUTH/LOGIN', data => ({
  request: {
    url: '/auth/login',
    method: 'POST',
    data
  },
  meta: {
    noAuth: true,
    onSuccess: (response, action, store) => {
      authToken.set(response.data.token);
      return response;
    }
  }
}));

export const logout = createAction('AUTH/LOGOUT', () => ({
  request: {
    url: '/auth/logout',
    method: 'POST'
  },
  meta: {
    onSuccess: (response, action, store) => {
      authToken.clear();
      return response;
    }
  }
}));

/**
 * @param {Object} data
 * @param {string} data.email
 */
export const sendResetPasswordLink = createAction('AUTH/RESET_PASSWORD',data => ({
  request: {
    url: '/auth/password/forgot',
    method: 'POST',
    data
  },
  meta: {
    noAuth: true,
  }
}));

/**
 * @param {Object} data
 * @param {string} data.token
 * @param {string} data.password
 * @param {string} data.password_token
 */
export const newPassword = createAction('AUTH/NEW_PASSWORD',data => ({
  request: {
    url: '/auth/password/reset',
    method: 'POST',
    data
  },
  meta: {
    noAuth: true,
  }
}));

export const fetchAccount = createAction('AUTH/FETCH_ACCOUNT', () => ({
  request: {
    url: '/me',
    method: 'GET',
  },
}));

export const updateAccount = createAction('AUTH/UPDATE_ACCOUNT', data => ({
  request: {
    url: '/me',
    method: 'PUT',
    data
  },
}));