import React from 'react';
import {Button, DatePicker} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {addWeek, getDate, subtractWeek} from '../../../helpers';


const FilterWeek = (startDate, handleChangeWeek) => (
  <>
    <DatePicker
      picker='week'
      value={getDate(startDate)}
      onChange={handleChangeWeek}
      allowClear={false}
      style={{width: 130}}
    />
    <Button
      icon={<LeftOutlined/>}
      onClick={() => handleChangeWeek(subtractWeek(startDate))}
    />
    <Button
      icon={<RightOutlined/>}
      onClick={() => handleChangeWeek(addWeek(startDate))}
    />
  </>
);

export default FilterWeek;