import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useColumns from './hooks/useColumns';
import routes from '../../constants/routes';
import { useQuery } from '@redux-requests/react';
import usePagination from '../../hooks/usePagination';
import { typeOptions } from '../../constants/vacations';
import SearchSelect from '../../components/SearchSelect';
import useSearchParams from '../../hooks/useSearchParams';
import {joinSearchString} from '../work_log/tableHelpers';
import {Button, DatePicker, Form, Select, Table} from 'antd';
import mapUrlSearchParams from '../../utils/mapUrlSearchParams';
import {searchEmployeeOptions} from '../../store/employees/actions';
import { deleteVacation, fetchVacationsList } from '../../store/vacations/actions';
import {formatDate} from '../../helpers';


const VacationsPage = () => {
  const query = '';
  const dispatch = useDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const from = searchParams.get('from');
  const fromDate = useMemo(() => from && moment(from), [from]);
  const to = searchParams.get('to');
  const toDate = useMemo(() => to && moment(to),[to]);

  const arrayOfEmployeeIds = searchParams.getAll('employee_ids[]');

  const stringEmployeeIds = useMemo(() => joinSearchString(arrayOfEmployeeIds, 'employee_ids'), [arrayOfEmployeeIds]);
  const newEmployeeIds = useMemo(() => arrayOfEmployeeIds.map( id => (+id)), [arrayOfEmployeeIds]);

  const handleSearchEmployee = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [ dispatch ]);
  const handleChangeEmployee = useCallback(employee_ids => setSearchParams({ 'employee_ids[]': employee_ids }), [setSearchParams]);

  const handleChangeTypes = useCallback(types => setSearchParams({ 'types[]': types }), [setSearchParams]);
  const handleChangeFrom = useCallback(
    fromDate => setSearchParams({ from: fromDate ? formatDate(fromDate) : null }),
    [setSearchParams]
  );
  const handleChangeTo = useCallback(
    toDate => setSearchParams({ to: toDate ? formatDate(toDate) : null }),
    [setSearchParams]
  );
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const { data: employees } = useQuery({
    type: searchEmployeeOptions,
    action: searchEmployeeOptions,
    variables: [query, stringEmployeeIds],
    requestKey: query + stringEmployeeIds,
    autoLoad: !!arrayOfEmployeeIds.length,
  });

  const { data, loading, load } = useQuery({
    type: fetchVacationsList,
    action: fetchVacationsList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
  });
  const pagination = usePagination(data?.meta);
  const handleDelete = useCallback(id => dispatch(deleteVacation(id)).then(() => load()), [dispatch, load]);
  const columns = useColumns(handleDelete);

  return (
    <>
      <div className='section-filters'>
        <Form
            layout="inline"
            className="components-table-demo-control-bar"
        >
          <Form.Item>
            <SearchSelect
              mode='multiple'
              placeholder='Employee'
              onSearch={handleSearchEmployee}
              onChange={handleChangeEmployee}
              value={newEmployeeIds}
              defaultOption={employees}
              style={{ width: 220 }}
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Type'
              value={searchParams.getAll('types[]')}
              options={typeOptions}
              onChange={handleChangeTypes}
              mode='multiple'
              maxTagCount='responsive'
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <DatePicker value={fromDate} placeholder='From' onChange={handleChangeFrom}  style={{ width: 220 }}/>
          </Form.Item>
          <Form.Item>
            <DatePicker value={toDate} placeholder='To' onChange={handleChangeTo}  style={{ width: 220 }}/>
          </Form.Item>
        </Form>
        <Link to={routes.vacations_create.path} style={{ marginLeft: 'auto' }}>
          <Button type='primary'>Create</Button>
        </Link>
      </div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.data}
        pagination={pagination}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
        scroll={{ x: 1300 }}
      />
    </>
  );
};

export default VacationsPage;