import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../constants/routes';
import {hasAccessByRole} from '../../../helpers';
import Password from '../../../components/Password';
import {ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2} from '../../../constants/roles';
import {Button, Popconfirm, Popover, Space, Typography} from 'antd';
import {DeleteOutlined, EditOutlined, InfoCircleOutlined} from '@ant-design/icons';


const useColumns = (onConfirmDelete, role) => useMemo(() => ([
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    key: 'avatar',
    sorter: true,
    render: ({id, short_name}) => hasAccessByRole([ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2], role)
      ? <Link to={routes.avatars_meta.path.replace(':id', id)}>{short_name}</Link>
      : short_name,
  },
  {
    title: 'Url',
    dataIndex: 'url',
    key: 'url',
    sorter: true,
  },
  {
    title: 'Login',
    dataIndex: 'login',
    key: 'login',
    sorter: true,
    render: login => <Typography.Text copyable>{login}</Typography.Text>
  },
  {
    title: 'Password',
    dataIndex: 'password',
    key: 'password',
    render: (_, password ) => <Password {...password} />
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: (id, {additional_info} ) => (
      <Space>
        <Link to={routes.passwords_edit.path.replace(':id', id)}>
          <Button type='link' size='small' icon={<EditOutlined />}/>
        </Link>
        <Popconfirm title='Are you sure?' onConfirm={() => onConfirmDelete(id)}>
          <Button type='link' size='small' icon={<DeleteOutlined />}/>
        </Popconfirm>
        <Popover content={<>{additional_info}</>} title="Additional info" trigger="click"  placement="left">
          <Button type='text' size='small' icon={<InfoCircleOutlined />} />
        </Popover>
      </Space>
    ),
    width: 125,
    align: 'center',
    fixed: 'right'
  }
]), [onConfirmDelete, role]);


export default useColumns;