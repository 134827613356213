import {Spin, Typography} from 'antd';
import {useParams} from 'react-router-dom';
import routes from '../../../../constants/routes';
import useFormLoader from '../../../../hooks/useFormLoader';
import useFormHandlers from '../../../../hooks/useFormHandlers';
import AvatarMetaForm from '../../../../components/AvatarMetaForm';
import {fetchAvatarMeta, saveAvatarMeta} from '../../../../store/avatars_meta/actions';


const AvatarsMetaEditPage = () => {
  const {id} = useParams();
  const [handleSubmit, handleSuccess, handleCancel] = useFormHandlers({
    action: saveAvatarMeta,
    successMessage: 'Avatar meta saved successfully!',
    successPath: routes.avatars.path,
    actionParams: [id]
  });
  const {data, loading} = useFormLoader(fetchAvatarMeta, id);

  return (
    <>
      <Typography.Title level={3}>Edit avatar Meta</Typography.Title>
      {loading ? (
        <Spin/>
      ) : (
        <AvatarMetaForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          initialValues={data}
        />
      )}
    </>
  )
};

export default AvatarsMetaEditPage;