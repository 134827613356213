import { Button, Form, Input } from 'antd';
import useFormSubmit from '../../hooks/useFormSubmit';


const validationRules = {
  password: [
    { required: true }
  ],
  password_confirmation: [
    { required: true },
    ({ getFieldValue }) => ({
      validator: (_, value) => (!value || getFieldValue('password') === value)
        ? Promise.resolve()
        : Promise.reject('The two passwords that you entered do not match.')
    })
  ]
};

// TODO: add required current password in API.
const ResetPasswordForm = ({ onSubmit, onSuccess, token }) => {
  const [ form ] = Form.useForm();
  const { submitting, handleSubmit } = useFormSubmit(form, onSubmit, onSuccess);

  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      onFinish={handleSubmit}
    >
      <Form.Item name='token' initialValue={token} hidden>
        <Input/>
      </Form.Item>

      <Form.Item name='password' rules={validationRules.password}>
        <Input.Password placeholder='Password'/>
      </Form.Item>

      <Form.Item name='password_confirmation' rules={validationRules.password_confirmation}>
        <Input.Password placeholder='Confirm password'/>
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit' loading={submitting} block>Save</Button>
      </Form.Item>
    </Form>
  )
}

export default ResetPasswordForm;