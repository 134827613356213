import { createAction } from 'redux-smart-actions';

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchUsersList = createAction('USERS/FETCH_LIST',(searchParams = '') => ({
  request: {
    url: `/users?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
  },
}));

export const fetchDeletedUsersList = createAction('USERS/FETCH_DELETED_LIST',(searchParams = '') => ({
  request: {
    url: `/users?archive=1&${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
  },
}));

/**
 * @param {Object} data
 * @param {number} data.employee_id
 * @param {string} data.role
 * @param {string} data.email
 */
export const createUser = createAction('USERS/CREATE', data => ({
  request: {
    url: '/users',
    method: 'POST',
    data
  },
}));

/**
 * @param {number} id
 */
export const fetchUser = createAction('USERS/FETCH', id => ({
  request: {
    url: `/users/${id}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));


/**
 * @param {number} id
 * @param {Object} data
 * @param {number} data.employee_id
 * @param {string} data.role
 * @param {string} data.email
 */
export const updateUser = createAction('USERS/UPDATE', (id, data) => ({
  request: {
    url: `/users/${id}`,
    method: 'PUT',
    data,
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 */
export const deleteUser = createAction('USERS/DELETE', id => ({
  request: {
    url: `/users/${id}`,
    method: 'DELETE',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

export const restoreUser = createAction('USERS/RESTORE', id => ({
  request: {
    url: `/users/${id}/restore`,
    method: 'POST',
  },
  meta: {
    requestKey: id.toString(),
  },
}));
