import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import routes from '../../../constants/routes';
import {getLabel} from '../../../helpers';
import {typeOptions} from '../../../constants/vacations';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';


const useColumns = (onConfirmDelete) => useMemo(() => ([
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    width: 120,
    render: (_, { employee: { id, first_name, last_name } }) => <Link to={routes.employees_view.path.replace(':id', id)}>{first_name} {last_name}</Link>
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: type => getLabel(typeOptions, type),
    sorter: true,
    width: 40,
  },
  {
    title: 'From',
    dataIndex: 'from',
    key: 'from',
    sorter: true,
    width: 40,
  },
  {
    title: 'To',
    dataIndex: 'to',
    key: 'to',
    sorter: true,
    width: 40,
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: id => (
      <Space size='small'>
        <Link to={routes.vacations_edit.path.replace(':id', id)}>
          <Button type='link' size='small' icon={<EditOutlined />}/>
        </Link>
        <Popconfirm title='Are you sure?' onConfirm={() => onConfirmDelete(id)}>
          <Button type='link' size='small' icon={<DeleteOutlined />}/>
        </Popconfirm>
      </Space>
    ),
    width: 30,
    align: 'center',
    fixed: 'right'
  }
]), [onConfirmDelete]);


export default useColumns;