import React from 'react';
import {Table} from 'antd';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import useColumns from './hooks/useColumns';
import {useQuery} from '@redux-requests/react';
import {fetchJobList} from '../../../../store/employees/actions';
import {accountSelector} from "../../../../store/auth/selectors";


const Projects = () => {
  const {id} = useParams();
  const { role } = useSelector(accountSelector);
  const {data, loading} = useQuery({
    type: fetchJobList,
    action: fetchJobList,
    variables: [id],
    requestKey: id,
    autoLoad: true,
    autoReset: true,
  });

  const columns = useColumns(role);

  return (
    <div style={{marginTop: 16}}>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.data}
        pagination={false}
        rowKey='id'
        size='small'
        scroll={{ x: 1300 }}
      />
    </div>
  )
}

export default Projects;