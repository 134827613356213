import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {Button, Popconfirm, Space} from 'antd';
import {getLabel} from '../../../../../helpers';
import routes from '../../../../../constants/routes';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {typeOptions} from "../../../../../constants/vacationLogs";



const useColumns = (employeeId, onConfirmDelete, isAccessRole, isAdmin) => useMemo(() => ([
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: true,
    fixed: 'left',
    render: type => getLabel(typeOptions, type),
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: true,
  },
  {
    title: 'Modifier',
    dataIndex: 'usage',
    key: 'usage',
    sorter: true,
    render: usage => usage > 0 ? `+${usage}` : usage
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: id => (
      <Space size='small'>
        <Link to={routes.employees_vacation_logs_edit.path
          .replace(':id', employeeId)
          .replace(':log_id', id)}
        >
          <Button type='link' size='small' icon={<EditOutlined/>}/>
        </Link>
        {isAdmin && <Popconfirm title='Are you sure?' onConfirm={() => onConfirmDelete(id)}>
          <Button type='link' size='small' icon={<DeleteOutlined />}/>
        </Popconfirm>}
      </Space>
    ),
    width: 90,
    align: 'center',
    hidden: !isAccessRole,
  }
]), [employeeId, onConfirmDelete, isAccessRole]);


export default useColumns;