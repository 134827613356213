import { Typography } from 'antd';
import useFormHandlers from '../../../hooks/useFormHandlers';
import routes from '../../../constants/routes';
import VacationForm from '../../../components/VacationForm';
import { createVacation } from '../../../store/vacations/actions';


const VacationsCreatePage = () => {
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
      action: createVacation,
      successMessage: 'Vacation added successfully!',
      successPath: routes.vacations.path
  });

  return (
    <>
      <Typography.Title level={3}>Create vacation</Typography.Title>
      <VacationForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
      />
    </>
  )
}

export default VacationsCreatePage;