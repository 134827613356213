import React, {useCallback, useMemo} from 'react';
import {Switch, useParams, useHistory, useLocation, Link} from 'react-router-dom';
import Comments from './comments';
import Projects from './projects';
import {useSelector} from 'react-redux';
import VacationLogs from './vacation_logs';
import routes from '../../../constants/routes';
import {getLabel, hasAccessByRole} from '../../../helpers';
import VacationLogsEdit from './vacation_logs/edit';
import ScheduledVacations from './sheduled_vacations';
import VacationLogCreate from './vacation_logs/create';
import useFormLoader from '../../../hooks/useFormLoader';
import LinkSpecial from '../../../components/LinkSpecial';
import PrivateRoute from '../../../components/PrivateRoute';
import {accountSelector} from '../../../store/auth/selectors';
import { fetchEmployee } from '../../../store/employees/actions';
import {Avatar, Button, Card, Descriptions, Menu, Spin, Typography} from 'antd';
import {ADMIN, HR_L1, OM, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2} from '../../../constants/roles';
import withRole from "../../../hoc/withRole";
import Meta from "antd/es/card/Meta";
import {enlevels, offices, roles} from "../../../constants/employees";
import Warehouses from "./warehouses";

const ScheduledVacationsAccessRoles = [ADMIN, TECH_L1, TECH_L2, PM_L1, PM_L2, PM_L2, PM_L3, HR_L1];
const VacationLogsAccessRoles = [ADMIN, TECH_L1, TECH_L2, PM_L1, PM_L2, PM_L2, PM_L3, HR_L1];
const VacationLogCreateAccessRoles = [ADMIN, TECH_L1, PM_L1, PM_L2, PM_L2, PM_L3];
const VacationLogsEditAccessRoles = [ADMIN, TECH_L1, PM_L1, PM_L2, PM_L2, PM_L3];
const ProjectsAccessRoles = [ADMIN, TECH_L1, TECH_L2, PM_L1, PM_L2];
const WarehouseAccessRoles = [ADMIN, OM, HR_L1];
const CommentsAccessRoles = [ADMIN, PM_L1, HR_L1];

const EmployeesViewPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const { data, loading } = useFormLoader(fetchEmployee, id);

  const { role } = useSelector(accountSelector);
  const handleMenuClick = useCallback(key => history.push(routes[key].path.replace(':id', id)), [history, id]);
  const menuItems = useMemo(() => (
    [
      {...routes.employees_vacations, isAccessRole: hasAccessByRole(ScheduledVacationsAccessRoles, role)},
      {...routes.employees_vacation_logs, isAccessRole: hasAccessByRole(VacationLogsAccessRoles, role)},
      {...routes.employees_projects, isAccessRole: hasAccessByRole(ProjectsAccessRoles, role)},
      {...routes.employees_comment, isAccessRole: hasAccessByRole(CommentsAccessRoles, role)},
      {...routes.employees_warehouses, isAccessRole: hasAccessByRole(WarehouseAccessRoles, role)}
    ]
  ), []);

  const selectedKeys = useMemo(() => {
    const pathToFind = location.pathname.replace( /\d+/g, ':id') || '/';
    const menuItem = menuItems.find( menuItem => pathToFind === menuItem.path);
    if (menuItem) {
      return [menuItem.key]
    }
  }, [location.pathname]);

  if (loading) {
    return <Spin/>;
  }

  const filterMenuItems = menuItems.map(({ key, title , isAccessRole}) => (
    isAccessRole && <Menu.Item key={key}>{title}</Menu.Item>
  ));
  const EditEmployeeLink = withRole([ADMIN, HR_L1])(Link);
  return (
    <>
      <Card>
        <Meta
            avatar={<Avatar size={90} src={data.avatar}/>}
            title={
              <><Typography.Title level={3}>{`${data.first_name} ${data.last_name}`}</Typography.Title>
              <EditEmployeeLink to={routes.employees_edit.path.replace(':id', data.id)}>
                  <Button type='primary'>Edit</Button>
              </EditEmployeeLink></>
            }

        />
      </Card>
        <Descriptions column={{ xxl: 3, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} bordered>
          <Descriptions.Item label='Phone number'>
            <LinkSpecial mode='phone'>{data.phone}</LinkSpecial>
          </Descriptions.Item>
          <Descriptions.Item label='Email'>
            <LinkSpecial mode='email'>{data.email}</LinkSpecial>
          </Descriptions.Item>
          <Descriptions.Item label='Mattermost account'>
            <LinkSpecial mode='im'>{data.im_nickname}</LinkSpecial>
          </Descriptions.Item>
          <Descriptions.Item label='Personal page'>
            <LinkSpecial mode='personal'>{data.cipher_link}</LinkSpecial>
          </Descriptions.Item>
          <Descriptions.Item label='Start working'>{data.started_at}</Descriptions.Item>
          <Descriptions.Item label='Birthday'>{data.birthday}</Descriptions.Item>
          <Descriptions.Item label='Domain'>{data.domain}</Descriptions.Item>
          <Descriptions.Item label='Role'>{getLabel(roles, data.role)}</Descriptions.Item>
          <Descriptions.Item label='Office'>{getLabel(offices, data.office)}</Descriptions.Item>
          <Descriptions.Item label='English level'>{getLabel(enlevels, data.en_level)}</Descriptions.Item>
          <Descriptions.Item label='English score'>{data.en_score}</Descriptions.Item>
        </Descriptions>

      <Menu onClick={(e) => handleMenuClick(e.key)} selectedKeys={selectedKeys} mode='horizontal'>
        {filterMenuItems}
      </Menu>
      <Switch>
        <PrivateRoute exact path={routes.employees_vacations.path} component={ScheduledVacations}
                      roles={ScheduledVacationsAccessRoles}/>
        <PrivateRoute exact path={routes.employees_vacation_logs.path} component={VacationLogs}
                      roles={VacationLogsAccessRoles}/>
        <PrivateRoute exact path={routes.employees_vacation_logs_create.path} component={VacationLogCreate}
                      roles={VacationLogCreateAccessRoles}/>
        <PrivateRoute exact path={routes.employees_vacation_logs_edit.path} component={VacationLogsEdit}
                      roles={VacationLogsEditAccessRoles}/>
        <PrivateRoute exact path={routes.employees_projects.path} component={Projects}
                      roles={ProjectsAccessRoles}/>
        <PrivateRoute exact path={routes.employees_comment.path} component={Comments}
                      roles={CommentsAccessRoles}/>
        <PrivateRoute exact path={routes.employees_warehouses.path} component={Warehouses}
                      roles={WarehouseAccessRoles}/>
      </Switch>
    </>
  );
}

export default EmployeesViewPage;