import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import routes from '../../../../constants/routes';
import {getLabel, hasAccessByRole} from '../../../../helpers';
import {typeOptions} from '../../../../constants/vacationLogs';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {ADMIN} from "../../../../constants/roles";


const useColumns = (onConfirmDelete, role) => useMemo(() => ([
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    width: 120,
    render: (_, { employee: { id, first_name, last_name } }) => <Link to={routes.employees_view.path.replace(':id', id)}>{first_name} {last_name}</Link>
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: true,
    render: type => getLabel(typeOptions, type),
    width: 40,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: true,
    width: 40,
  },
  {
    title: 'Modifier',
    dataIndex: 'usage',
    key: 'usage',
    sorter: true,
    render: usage => usage > 0 ? `+${usage}` : usage,
    width: 30,
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: id => (
      <Space size='small'>
        <Link to={routes.vacations_log_edit.path.replace(':id', id)}>
          <Button type='link' size='small' icon={<EditOutlined />}/>
        </Link>
        {hasAccessByRole([ADMIN], role)?(<Popconfirm title='Are you sure?' onConfirm={() => onConfirmDelete(id)}>
          <Button type='link' size='small' icon={<DeleteOutlined />}/>
        </Popconfirm>):(<></>)}
      </Space>
    ),
    width: 20,
    align: 'center',
    fixed: 'right',
  }
]), [onConfirmDelete]);


export default useColumns;