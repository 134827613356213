import {useMemo} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {Button, Typography} from 'antd';
import {CommentOutlined} from '@ant-design/icons';
import routes from '../../../../../constants/routes';
import {ADMIN, PM_L1} from '../../../../../constants/roles';
import {formatToFixed} from '../../../../work_log/tableHelpers';
import {BASE_URL, IM_BASE_URL} from '../../../../../constants/config';
import {formatDate, formatTimeFromHours, hasAccessByRole} from '../../../../../helpers';


const useColumns = (role) => useMemo(() => ([
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
  },
  {
    title: 'Profile',
    dataIndex: 'avatar',
    key: 'avatar',
    render: ({id, short_name}) => hasAccessByRole([ADMIN, PM_L1], role)
      ? <Link to={routes.avatars_meta.path.replace(':id', id)}>{short_name}</Link>
      : {short_name},
  },
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    render: client => client?.full_name
  },
  {
    title: 'Job name',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Manager',
    dataIndex: 'managers',
    key: 'managers',
  },
  {
    title: 'From',
    dataIndex: 'from',
    key: 'from',
    render: from => from ? formatDate(moment(from)) : ''
  },
  {
    title: 'To',
    dataIndex: 'to',
    key: 'to',
    render: to =>  to ? formatDate(moment(to)) : ''
  },
  {
    title: 'Total',
    dataIndex: 'total_time',
    key: 'total_time',
    render: total_time => formatTimeFromHours(total_time)
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Rate',
    dataIndex: 'avg_rate',
    key: 'avg_rate',
    render: avg_rate =>  formatToFixed(avg_rate)
  },
  {
    title: '',
    dataIndex: 'cipher_link',
    key: 'cipher_link',
    render: cipher_link => (
      <Typography.Link copyable={{text: `${BASE_URL}/reports/${cipher_link}`}}/>
    ),
    width: 50
  },
  {
    title: '',
    dataIndex: 'im_channel_id',
    key: 'im_channel_id',
    render: im_channel_id => <Button
      type='link'
      size='small'
      target='_blank'
      href={`${IM_BASE_URL}/messages/channels/${im_channel_id}`}
      icon={<CommentOutlined/>}
    />,
    width: 50,
  }
]), [role]);

export default useColumns;