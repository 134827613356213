import {useCallback, useEffect, useMemo} from 'react';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import useColumns from './hooks/useColumns';
import routes from '../../constants/routes';
import withRole from '../../hoc/withRole';
import { useQuery } from '@redux-requests/react';
import {Button, Form, Select, Table} from 'antd';
import usePagination from '../../hooks/usePagination';
import useSearchParams from '../../hooks/useSearchParams';
import SearchSelect from '../../components/SearchSelect';
import {joinSearchString} from '../work_log/tableHelpers';
import {ADMIN, HR_L1, HR_L2} from '../../constants/roles';
import {accountSelector} from '../../store/auth/selectors';
import mapUrlSearchParams from '../../utils/mapUrlSearchParams';
import { searchEmployeeOptions} from '../../store/employees/actions';
import { domains, statuses, platforms } from '../../constants/applicants';
import {deleteApplicant, fetchApplicantsList, searchApplicantOptions} from '../../store/applicants/actions';

const ApplicantCreateLink = withRole([ADMIN, HR_L1, HR_L2])(Link);
const ApplicantDomainsFilter = withRole([ADMIN, HR_L1, HR_L2])(Select);
const ApplicantsPage = () => {
  const query = '';
  const dispatch = useDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { role } = useSelector(accountSelector);

  const arrayOfResponsiblePersonIds = searchParams.getAll('responsible_person_ids[]');
  const stringResponsiblePersonIds = useMemo(() => joinSearchString(arrayOfResponsiblePersonIds, 'responsible_person_ids'), [arrayOfResponsiblePersonIds]);
  const newResponsiblePersonIds = useMemo(() => arrayOfResponsiblePersonIds.map( id => (+id)), [arrayOfResponsiblePersonIds]);

  const arrayOfApplicantIds = searchParams.getAll('ids[]');
  const stringOfApplicantIds = useMemo(() => joinSearchString(arrayOfApplicantIds, 'ids'), [arrayOfApplicantIds]);
  const newOfApplicantIds = useMemo(() => arrayOfApplicantIds.map( id => (+id)), [arrayOfApplicantIds]);

  const { data: responsiblePersons } = useQuery({
    type: searchEmployeeOptions,
    action: searchEmployeeOptions,
    variables: [query, stringResponsiblePersonIds],
    requestKey: query + stringResponsiblePersonIds,
    autoLoad: !!arrayOfResponsiblePersonIds.length,
  });

  const { data: applicants } = useQuery({
    type: searchEmployeeOptions,
    action: searchEmployeeOptions,
    variables: [query, stringOfApplicantIds],
    requestKey: query + stringOfApplicantIds,
    autoLoad: !!stringOfApplicantIds.length,
  });

  const handleChangeDomains = useCallback(domains => setSearchParams({ 'domains[]': domains }), [setSearchParams]);
  const handleChangeStatuses = useCallback(statuses => setSearchParams({ 'statuses[]': statuses }), [setSearchParams]);
  const handleChangePlatforms = useCallback(platforms => setSearchParams({ 'platforms[]': platforms }), [setSearchParams]);
  const handleSearchResponsiblePerson = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [ dispatch ]);
  const handleChangeResponsiblePerson = useCallback(responsible_person_ids => setSearchParams({ 'responsible_person_ids[]': responsible_person_ids }), [setSearchParams]);

  const handleSearchApplicant = useCallback(input => input.length > 1 && dispatch(searchApplicantOptions(input)), [ dispatch ]);
  const handleChangeApplicant = useCallback(ids => setSearchParams({ 'ids[]': ids }), [setSearchParams]);

  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const { data, loading, load } = useQuery({
    type: fetchApplicantsList,
    action: fetchApplicantsList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
    autoReset: true,
  });

  useEffect(()=>setSearchParams({ 'order_by': 'desc', 'sort_by': 'id' }), []);

  const pagination = usePagination(data?.meta);
  const handleDelete = useCallback(id => dispatch(deleteApplicant(id)).then(() => load()), [dispatch, load]);
  const columns = useColumns(handleDelete, role).filter(column => !column.hidden);

  return (
    <>
      <div className='section-filters'>
        <Form
            layout="inline"
            className="components-table-demo-control-bar"
        >
          <Form.Item>
            <SearchSelect
              mode='multiple'
              placeholder='Applicants'
              onSearch={handleSearchApplicant}
              onChange={handleChangeApplicant}
              value={newOfApplicantIds}
              defaultOption={applicants}
              style={{ width: 220 }}
            />
          </Form.Item>
          <Form.Item>
            <ApplicantDomainsFilter
              placeholder='Type'
              mode='multiple'
              value={searchParams.getAll('domains[]')}
              options={domains}
              onChange={handleChangeDomains}
              maxTagCount='responsive'
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Status'
              mode='multiple'
              value={searchParams.getAll('statuses[]')}
              options={statuses}
              onChange={handleChangeStatuses}
              maxTagCount='responsive'
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Platform'
              mode='multiple'
              value={searchParams.getAll('platforms[]')}
              options={platforms}
              onChange={handleChangePlatforms}
              maxTagCount='responsive'
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <SearchSelect
              mode='multiple'
              placeholder='Responsible person'
              onSearch={handleSearchResponsiblePerson}
              onChange={handleChangeResponsiblePerson}
              value={newResponsiblePersonIds}
              defaultOption={responsiblePersons}
              style={{ width: 220 }}
            />
          </Form.Item>
        </Form>
        <ApplicantCreateLink to={routes.applicants_create.path} style={{ marginLeft: 'auto' }}>
          <Button type='primary'>Create</Button>
        </ApplicantCreateLink>
      </div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.data}
        pagination={pagination}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
        scroll={{ x: 1300 }}
      />
    </>
  );
};


export default ApplicantsPage;