import { createAction } from 'redux-smart-actions';

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchWarehousesList = createAction('WAREHOUSES/FETCH_LIST', (searchParams = '') => ({
  request: {
    url: `/warehouses?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
  },
}));

/**
 * @param {Object} data
 * @param {string} data.serial
 * @param {number} data.employee_id
 * @param {string} data.type
 * @param {object[string]} data.specifications
 * @param {string} data.checked_at
 * @param {string} data.location
 * @param {string} data.note
 */
export const createWarehouse= createAction('WAREHOUSES/CREATE', data => ({
  request: {
    url: '/warehouses',
    method: 'POST',
    data
  },
}));

/**
 * @param {number} id
 */
export const fetchWarehouse = createAction('WAREHOUSES/FETCH', id => ({
  request: {
    url: `/warehouses/${id}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 * @param {Object} data
 * @param {string} data.date
 */
export const updateWarehouse = createAction('WAREHOUSES/UPDATE', (id, data) => ({
  request: {
    url: `/warehouses/${id}`,
    method: 'PUT',
    data,
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 */
export const deleteWarehouse = createAction('WAREHOUSES/DELETE', id => ({
  request: {
    url: `/warehouses/${id}`,
    method: 'DELETE',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

export const restoreWarehouse = createAction('WAREHOUSES/RESTORE', id => ({
  request: {
    url: `/warehouses/${id}/restore`,
    method: 'POST',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {string} serial
 */
export const fetchWarehouseItemBySerial = createAction('WAREHOUSES/ITEM/FETCH/SERIAL', (searchParams = '') => ({
  request: {
    url: `/wh?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
    getData: ({ data }) => data
  },
}));

