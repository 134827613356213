import React from 'react';
import { Col, Form, Input, Row } from 'antd';

const PCFormItems = ({name, fieldKey, ...restField}) => (
  <>
    <Row gutter={16}>
      <Col span={8}>
        <Form.Item
          {...restField}
          label="CPU"
          name={[name, 'cpu']}
          fieldKey={[fieldKey, 'cpu']}
          rules={[{ required: true, }]}
        >
          <Input placeholder="CPU"/>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="RAM"
          name={[name, 'ram']}
          fieldKey={[fieldKey, 'ram']}
          rules={[{ required: true, }]}
        >
          <Input placeholder="RAM"/>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="GPU"
          name={[name, 'gpu']}
          fieldKey={[fieldKey, 'gpu']}
          rules={[{ required: true, }]}
        >
          <Input placeholder="GPU"/>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          label='SSD'
          name={[name, 'ssd']}
          fieldKey={[fieldKey, 'ssd']}
        >
          <Input placeholder='SSD'/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label='HDD'
          name={[name, 'hdd']}
          fieldKey={[fieldKey, 'hdd']}
        >
          <Input placeholder='HDD'/>
        </Form.Item>
      </Col>
    </Row>
  </>
);

export default PCFormItems;