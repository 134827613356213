import { Spin, Typography } from 'antd'
import { useParams } from 'react-router-dom';
import routes from '../../../constants/routes';
import useFormHandlers from '../../../hooks/useFormHandlers';
import useFormLoader from '../../../hooks/useFormLoader';
import ApplicantForm from '../../../components/ApplicantForm';
import { fetchApplicant, updateApplicant } from '../../../store/applicants/actions';
import {useSelector} from "react-redux";
import {accountSelector} from "../../../store/auth/selectors";


const ApplicantsEditPage = () => {
  const { id } = useParams();
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
    action: updateApplicant,
    successMessage: 'Applicant updated successfully!',
    successPath: routes.applicants.path,
    actionParams: [id]
  });
  const { data, loading } = useFormLoader(fetchApplicant, id);
  const { role, employee:{id:uid}} = useSelector(accountSelector);

  return (
    <>
      <Typography.Title level={3}>Edit applicant</Typography.Title>
      { loading ? (
        <Spin/>
      ) : (
        <ApplicantForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          initialValues={data}
          role={role}
          uid={uid}
        />
      ) }
    </>
  )
}

export default ApplicantsEditPage;