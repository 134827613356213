import Router from '../../router';
import useAppInitialize from './hooks/useAppInitialize';
import { Spin } from 'antd';
import { Spinner } from '../../styles/styles';



const App = () => {
  const { loading } = useAppInitialize();

  if (loading) {
    return <Spinner><Spin size='large'/></Spinner>;
  }

  return <Router/>;
};

export default App;
