import { Typography } from 'antd';
import ClientForm from '../../../components/ClientForm';
import useFormHandlers from '../../../hooks/useFormHandlers';
import { createClient } from '../../../store/clients/actions';
import routes from '../../../constants/routes';


const ClientsCreatePage = () => {
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
      action: createClient,
      successMessage: 'Client added successfully!',
      successPath: routes.clients.path
  });

  return (
    <>
      <Typography.Title level={3}>Create client</Typography.Title>
      <ClientForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
      />
    </>
  )
}

export default ClientsCreatePage;