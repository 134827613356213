import React, { useCallback, useEffect, useState } from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import routes from '../../constants/routes';
import {useQuery} from '@redux-requests/react';
import useSearchParams from '../../hooks/useSearchParams';
import {Button, List, notification, Space, Table, Tabs, Typography} from 'antd';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import {cancelVacation, fetchVacationsList} from '../../store/vacations/actions';
import AbsentEmployee from '../../components/AbsentEmployee';
import {VACATION_LOG_TYPES} from '../../constants/vacationLogs';
import { fetchCalendarEvents } from '../../store/calendar/action';
import MissedVacationLog from '../../components/MissedVacationLog';
import AbsentEmployeeList from '../../components/AbsentEmployeeList';
import { fetchAbsentEmployeeList } from '../../store/employees/actions';
import {fetchVacationLogsList, fetchMissedVacationLogsList} from '../../store/vacation_logs/actions';
import {calendarEventStyle, formatDate, getLabel, getStartAndEndDateInCalendar} from '../../helpers';
import debounce from 'lodash.debounce';
import Pluralize from 'pluralize';
import '../../styles/style.scss';
import {
  StyledLeftSideContainer,
  StyledRightSideContainer,
  StyledVacationLogsContainer
} from './styles/styles';
import {fetchHolidaysList} from "../../store/holidays/actions";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {typeOptions} from "../../constants/vacations";
import {EditOutlined} from "@ant-design/icons";

const localizer = momentLocalizer(moment);

const ENTITIES = {
  VACATION: 'vacation',
  VACATION_LOG: 'vacation_log',
};

const { TabPane } = Tabs;

TabPane.propTypes = {
  tab: PropTypes.string,
  children: PropTypes.node
};
const DashboardPage = props => {
  const dispatch = useDispatch();
  const date = formatDate(moment());
  const [searchParams, setSearchParams] = useSearchParams();
  const [events, setEvents] = useState([]);

  const setUrlParams = useCallback(({start, end}) => setSearchParams({
    from: formatDate(moment(start)),
    to: formatDate(moment(end)),
    'types[]': [VACATION_LOG_TYPES.VACATION, VACATION_LOG_TYPES.SICK, VACATION_LOG_TYPES.DAY_OFF, VACATION_LOG_TYPES.SICK_PROVED, VACATION_LOG_TYPES.ABSENT],
  }), [setSearchParams]);

  useEffect(() => {
    if (!searchParams.has('from') || !searchParams.has('to')) {
      const {startDate: start, lastDate: end} = getStartAndEndDateInCalendar();
      setUrlParams({start, end});
    }
  }, [searchParams, setUrlParams]);

  const {data: dashboardList, load: dashboardListLoad } = useQuery({
    type: fetchCalendarEvents,
    action: fetchCalendarEvents,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
  });

  const vacationsListParams = `from=${formatDate(moment().add(1, 'days'))}&order_by=asc&sort_by=from`;

  const { data: vacationsList, load: vacationsListLoading } = useQuery({
    type: fetchVacationsList,
    action: fetchVacationsList,
    variables: [vacationsListParams],
    requestKey: vacationsListParams,
    autoLoad: true,
  });

  const absentVacationLogParams = `types[]=${VACATION_LOG_TYPES.ABSENT}&sort_by=date&order_by=desc`;

  const {data: vacationLogsListByAbsentType, loading: vacationLogsListByAbsentTypeLoading} = useQuery({
    type: fetchVacationLogsList,
    action: fetchVacationLogsList,
    variables: [absentVacationLogParams],
    requestKey: absentVacationLogParams,
    autoLoad: true,
  });

  const {data: absentEmployeeList, loading: absentEmployeeLoading, load: absentEmployeeLoad} = useQuery({
    type: fetchAbsentEmployeeList,
    action: fetchAbsentEmployeeList,
    variables: [date],
    autoLoad: true,
  });

  const {data: missedVacationLogsList, loading: missedVacationLogsLoading, load: missedVacationLogsLoad} = useQuery({
    type: fetchMissedVacationLogsList,
    action: fetchMissedVacationLogsList,
    variables: [date],
    autoLoad: true,
  });

  const { data: holidays } = useQuery({
    type: fetchHolidaysList,
    action: fetchHolidaysList,
    autoLoad: true,
  });

  useEffect(() => {
    if (dashboardList?.data) {
      setEvents(dashboardList?.data);
    }
  }, [searchParams, setUrlParams, dashboardList]);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRangeChange = useCallback(debounce( range => setUrlParams(range), 500), [setSearchParams]);
  const handleCancel = useCallback((id, data) => dispatch(cancelVacation(id, data)), [dispatch]);
  const handleCancelMissedDate = useCallback((id, data) => dispatch(cancelVacation(id, data)), [dispatch]);
  const handleSuccess = useCallback(message => notification.success({message: message}), []);

  const handleSuccessAbsentEmployee = useCallback(() => {
    handleSuccess('Log vacation added successfully!');
    absentEmployeeLoad().catch(console.log);
  }, [absentEmployeeLoad, handleSuccess]);

  const handleSuccessMissedVacationLog = useCallback(() => {
    handleSuccess('Log vacation added successfully!');
    missedVacationLogsLoad().catch(console.log);
  }, [handleSuccess, missedVacationLogsLoad]);

  const handleCancelAbsentEmployee = useCallback(() => {
    handleSuccess('Vacation canceled successfully!');
    absentEmployeeLoad().catch(console.log);
    dashboardListLoad().catch(console.log);
  }, [absentEmployeeLoad, handleSuccess, dashboardListLoad]);

  const handleCancelMissedVacationLog = useCallback(() => {
    handleSuccess('Vacation canceled successfully!');
    missedVacationLogsLoad().catch(console.log);
    dashboardListLoad().catch(console.log);
  }, [handleSuccess, missedVacationLogsLoad, dashboardListLoad]);

  const handleDoubleClickEvent = event => {
    let path = '',
      id = null;

    switch (event.entity) {
      case ENTITIES.VACATION:
        id = event.id;
        path = routes.vacations_edit.path;
        break;
      case ENTITIES.VACATION_LOG:
        id = event.id;
        path = routes.vacations_log_edit.path;
        break;
      case 'missed_vacation_log':
        id = event.employee_id;
        path = `${routes.employees_vacation_logs_create.path}?type=${event.type}&date=${event.end}`;
        break;
      case 'absent_today':
        id = event.employee_id;
        path = `${routes.employees_vacation_logs_create.path}?type=${event.type}&date=${event.end}`;
        break;
    }

    return props.history.push(path.replace(':id', id));
  };

  const ColoredDateCellWrapper = ({children}) =>
    React.cloneElement(React.Children.only(children), {
      style: {
        backgroundColor: 'lightred',
      },
    });

  return (
    <>
      <StyledVacationLogsContainer>
        <StyledLeftSideContainer>
          <List
            header={<Typography.Title level={4}>Absent today</Typography.Title>}
            loading={absentEmployeeLoading}
            dataSource={absentEmployeeList?.data}
            renderItem={absentEmployee => (<AbsentEmployee
              absentEmployee={absentEmployee}
              onCancel={handleCancel}
              onSuccessSubmit={handleSuccessAbsentEmployee}
              onSuccessCancel={handleCancelAbsentEmployee}
            />)}
          />
          <List
            header={<Typography.Title level={4} type='danger'>Missed day</Typography.Title>}
            loading={missedVacationLogsLoading}
            dataSource={missedVacationLogsList?.data}
            renderItem={missedVacationLog => (<MissedVacationLog
              missedVacationLog={missedVacationLog}
              onCancel={handleCancelMissedDate}
              onSuccessSubmit={handleSuccessMissedVacationLog}
              onSuccessCancel={handleCancelMissedVacationLog}
            />)}
          />
        </StyledLeftSideContainer>
        <StyledRightSideContainer>
          <List
            itemLayout="horizontal"
            header={<Typography.Title level={4}>Absent</Typography.Title>}
            loading={vacationLogsListByAbsentTypeLoading}
            dataSource={vacationLogsListByAbsentType?.data}
            renderItem={absentVacationLog => (<AbsentEmployeeList absentVacationLog={absentVacationLog}/>)}
          />
        </StyledRightSideContainer>
      </StyledVacationLogsContainer>
      <Tabs defaultActiveKey="calendar">
        <TabPane tab="Vacations calendar" key="calendar">
          <Calendar
            localizer={localizer}
            events={events}
            views={['month']}
            step={60}
            popup
            showMultiDayTimes
            handleDragStart={() => false}
            onRangeChange={handleRangeChange}
            eventPropGetter={calendarEventStyle}
            dayPropGetter={(date) => {
              const day = date.getDay();
              let holiday = holidays?.data.find(holiday => moment(date).isSame(holiday.date, 'day'));
              if(day === 6 || day === 0 || holiday) {
                return {
                  style: {
                    backgroundColor: '#ffe8d1',
                  },
                };
              }
            }}
            onDoubleClickEvent={handleDoubleClickEvent}
            components={{
              timeSlotWrapper: ColoredDateCellWrapper,
            }}
            style={{minHeight: '1300px', marginTop: '15px'}}
          />
        </TabPane>
        <TabPane tab="Upcoming vacations" key="upcoming">
          <Table
            columns={[
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 120,
                render: (_, { employee: { id, first_name, last_name } }) => <Link to={routes.employees_view.path.replace(':id', id)}>{first_name} {last_name}</Link>
              },
              {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                render: type => getLabel(typeOptions, type),
                width: 40,
              },
              {
                title: 'In',
                dataIndex: 'from',
                key: 'from',
                width: 40,
                render: from => {
                  return Pluralize( 'days', moment(from).diff(moment(), 'day'), true);
                },
              },
              {
                title: 'From',
                dataIndex: 'from',
                key: 'from',
                width: 40,
              },
              {
                title: 'To',
                dataIndex: 'to',
                key: 'to',
                width: 40,
              },
              {
                title: '',
                dataIndex: 'id',
                key: 'actions',
                render: id => (
                  <Space size='small'>
                    <Link to={routes.vacations_edit.path.replace(':id', id)}>
                      <Button type='link' size='small' icon={<EditOutlined />}/>
                    </Link>
                  </Space>
                ),
                width: 30,
                align: 'center',
                fixed: 'right'
              }
            ]}
            loading={vacationsListLoading}
            dataSource={vacationsList?.data}
            pagination={false}
            rowKey='id'
            size='small'
            scroll={{ x: 1300 }}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default DashboardPage;
