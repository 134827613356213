import React from 'react';
import useFormSubmit from '../../hooks/useFormSubmit';
import {Button, Col, Form, notification, Row} from 'antd';
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";


const validationRules = {
  content: [
    {required: true},
    {min: 5},
    {max: 65535}
  ]
};

const CommentForm = ({onSubmit, initialValues = null}) => {
  const [form] = Form.useForm();
  const onSuccess = () => {
    notification.success({ message: 'Comment added successfully!' });
    form.resetFields()
  };
  const {submitting, handleSubmit} = useFormSubmit(form, onSubmit, onSuccess);
  const [selectedTab, setSelectedTab] = React.useState("write");
  const [content, setContent] = React.useState(form.getFieldValue('content'));

  return (
    <Form
      form={form}
      initialValues={{
        ...initialValues,
      }}
      validateTrigger={['onBlur']}
      onFinish={handleSubmit}
    >
      <Row gutter={16}>
        <Col flex='1 1 250px'>
          <Form.Item
            name='content'
            rules={validationRules.content}>
            <ReactMde
              value={content}
              onChange={setContent}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              generateMarkdownPreview={(markdown) => {
                return Promise.resolve(<ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>)
              }
              }
              childProps={{
                writeButton: {
                  tabIndex: -1
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col flex='0 1 250px'>
          <Form.Item>
            <Button type='primary' htmlType='submit' loading={submitting} block>
              Send
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default CommentForm;