import moment from 'moment';
import { useCallback } from 'react';
import SearchSelect from '../SearchSelect';
import { DATE_FORMAT } from '../../constants/config';
import useFormSubmit from '../../hooks/useFormSubmit';
import { typeOptions } from '../../constants/vacations';
import {useDispatchRequest} from '@redux-requests/react';
import {searchEmployeeOptions} from '../../store/employees/actions';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space } from 'antd';

const validationRules = {
  employee_id: [
    { required: true }
  ],
  type: [
    { required: true }
  ],
  from: [
    { required: true }
  ],
  to: [
    { required: true },
    ({ getFieldValue }) => ({
      validator: (_, value) => (!value || getFieldValue('from') <= value)
        ? Promise.resolve()
        : Promise.reject('The To date must be greater than the From date.')
    })
  ]
};

const VacationForm = ({ onSubmit, onSuccess, onCancel, initialValues = null }) => {
  const dispatch = useDispatchRequest();
  const [ form ] = Form.useForm();
  const { submitting, handleSubmit } = useFormSubmit(form, onSubmit, onSuccess);
  const handleSearchEmployee = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [ dispatch ]);

  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      initialValues={{
        ...initialValues,
        employee_id: initialValues?.employee.id,
        from: initialValues?.from && moment(initialValues.from),
        to: initialValues?.to && moment(initialValues.to),
      }}
      onFinish={handleSubmit}
      layout='vertical'
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
              label='Employee'
              name='employee_id'
              rules={validationRules.employee_id}
          >
            <SearchSelect
              placeholder='Employee'
              onSearch={handleSearchEmployee}
              defaultOption={[{value: initialValues?.employee.id, label: initialValues?.employee.full_name}]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
              label='Type'
              name='type'
              rules={validationRules.type}
          >
            <Select placeholder='Type' options={typeOptions}/>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
          label='Comment'
          name='comment'
      >
        <Input.TextArea placeholder='Comment' rows={3}/>
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
              label='From'
              name='from'
              rules={validationRules.from}
          >
            <DatePicker style={{ width: '100%' }} placeholder='From' format={DATE_FORMAT}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
              label='To'
              name='to'
              rules={validationRules.to}
          >
            <DatePicker style={{ width: '100%' }} placeholder='To' format={DATE_FORMAT} dependencies='from'/>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Space>
          <Button htmlType='button' loading={submitting} onClick={onCancel}>Cancel</Button>
          <Button type='primary' htmlType='submit' loading={submitting}>Submit</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default VacationForm;