import moment from 'moment';
import React, {useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@redux-requests/react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {typeOptions} from '../../../../constants/vacations';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import useSearchParams from '../../../../hooks/useSearchParams';
import {VACATION_LOG_TYPES} from '../../../../constants/vacationLogs';
import {fetchEmployeeVacationsList} from '../../../../store/employees/actions';
import {calendarEventStyle, formatDate, getStartAndEndDateInCalendar} from '../../../../helpers';
import debounce from 'lodash.debounce';
import '../../../../styles/style.scss';

const localizer = momentLocalizer(moment);

const ScheduledVacations = () => {
  const { id } = useParams();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const setUrlParams = useCallback(({start, end}) => setSearchParams({
    from: formatDate(moment(start)),
    to: formatDate(moment(end)),
    'types[]': [VACATION_LOG_TYPES.VACATION, VACATION_LOG_TYPES.SICK, VACATION_LOG_TYPES.DAY_OFF]
  }), [setSearchParams]);

  useEffect(() => {
    if (!searchParams.has('from') || !searchParams.has('to')) {
      const {startDate: start, lastDate: end} = getStartAndEndDateInCalendar();
      setUrlParams({start, end});
    }
  }, [searchParams, setUrlParams]);

  const VacationsList = useQuery({
    type: fetchEmployeeVacationsList,
    action: fetchEmployeeVacationsList,
    variables: [id, searchParams],
    requestKey: id + searchParams.toString(),
    autoLoad: true,
    autoReset: true,
  });

  const types = typeOptions.map( typeOption => typeOption.value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRangeChange = useCallback(debounce( range => setUrlParams(range), 500), [setSearchParams, types]);

  const events = VacationsList?.data?.map(({ id, type, from, to}) => (
    { id: id, title: type, end: to, start: from, type, entity: 'vacation', allDay: true}
  ))||[];

  const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
      style: {
        backgroundColor: 'lightblue',
      },
    })

  return (
    <Calendar
      step={60}
      localizer={localizer}
      events={events}
      views={['month']}
      onRangeChange={handleRangeChange}
      eventPropGetter={calendarEventStyle}
      style={{minHeight: '500px', marginTop: '15px'}}
      components={{
        timeSlotWrapper: ColoredDateCellWrapper,
      }}
    />
  );
};

export default ScheduledVacations;