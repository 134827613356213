export const types = [
  {
    value: 'pm',
    label: 'Project manager'
  },
  {
    value: 'developer',
    label: 'Developer'
  },
  {
    value: 'qa',
    label: 'QA engineer'
  },
  {
    value: 'designer',
    label: 'Designer'
  }
];