import React, {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import { useQuery } from '@redux-requests/react';
import {domains, enlevels, offices, roles} from '../../constants/employees';
import usePagination from '../../hooks/usePagination';
import EmployeeCard from '../../components/EmployeeCard';
import useSearchParams from '../../hooks/useSearchParams';
import {joinSearchString} from '../work_log/tableHelpers';
import SearchSelect from '../../components/SearchSelect';
import {Pagination, Row, Select, Spin, Form, Button} from 'antd';
import usePaginationChange from '../../hooks/usePaginationChange';
import {fetchEmployeeList, searchChiefsOptions, searchEmployeeOptions} from '../../store/employees/actions';
import './styles.scss';
import {Link} from "react-router-dom";
import routes from "../../constants/routes";
import withRole from '../../hoc/withRole'
import { ADMIN, HR_L1 } from '../../constants/roles'


const CreateEmployeeLink = withRole([ADMIN, HR_L1])(Link)

const EmployeesPage = () => {
  const query = '';
  const dispatch = useDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const arrayOfEmployeeIds = searchParams.getAll('ids[]');
  const stringEmployeeIds = useMemo(() => joinSearchString(arrayOfEmployeeIds, 'ids'), [arrayOfEmployeeIds]);

  const arrayOfOfficerIds = searchParams.getAll('officer_ids[]');
  const stringOfficerIds = useMemo(() => joinSearchString(arrayOfOfficerIds, 'officer_ids'), [arrayOfOfficerIds]);
  const newOfficerIds = useMemo(() => arrayOfOfficerIds.map( id => (+id)), [arrayOfOfficerIds]);

  const { data: employees } = useQuery({
    type: searchEmployeeOptions,
    action: searchEmployeeOptions,
    variables: [query, stringEmployeeIds],
    requestKey: query + stringEmployeeIds,
    autoLoad: true,
  });

  const { data: officers } = useQuery({
    type: searchChiefsOptions,
    action: searchChiefsOptions,
    variables: [query, stringOfficerIds],
    requestKey: query + stringOfficerIds,
    autoLoad: true,
  });

  const handleSearchOfficer = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [dispatch]);
  const handleChangeOfficer = useCallback(employee_ids => setSearchParams({ 'officer_ids[]': employee_ids }), [setSearchParams]);

  const handleChangeEmployee = useCallback(employee_ids => setSearchParams({ 'ids[]': employee_ids }), [setSearchParams]);
  const handleChangeDomains = useCallback(domains => setSearchParams({ 'domains[]': domains }), [setSearchParams]);
  const handleChangeRoles = useCallback(roles => setSearchParams({ 'roles[]': roles }), [setSearchParams]);
  const handleChangeOffices = useCallback(offices => setSearchParams({ 'offices[]': offices }), [setSearchParams]);
  const handleChangeEnLevels = useCallback(en_levels => setSearchParams({ 'en_levels[]': en_levels }), [setSearchParams]);

  const { data, loading } = useQuery({
    type: fetchEmployeeList,
    action: fetchEmployeeList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
  });
  const pagination = usePagination(data?.meta);
  const handlePaginationChange = usePaginationChange();

  return (
    <>
      <div className='section-filters'>
        <Form
            layout="inline"
            className="components-table-demo-control-bar"
        >
          <Form.Item>
            <Select
              mode='multiple'
              placeholder='Employee'
              options={employees}
              notFoundContent={null}
              filterOption={true}
              optionFilterProp='label'
              allowClear
              onChange={handleChangeEmployee}
              style={{ width: 220 }}
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Domain'
              value={searchParams.getAll('domains[]')}
              options={domains}
              onChange={handleChangeDomains}
              mode='multiple'
              maxTagCount='responsive'
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Role'
              value={searchParams.getAll('roles[]')}
              options={roles}
              onChange={handleChangeRoles}
              mode='multiple'
              maxTagCount='responsive'
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <SearchSelect
              mode='multiple'
              placeholder='Officer'
              onSearch={handleSearchOfficer}
              onChange={handleChangeOfficer}
              value={newOfficerIds}
              defaultOption={officers}
              style={{ width: 220 }}
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Office'
              value={searchParams.getAll('offices[]')}
              options={offices}
              onChange={handleChangeOffices}
              mode='multiple'
              maxTagCount='responsive'
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='English level'
              value={searchParams.getAll('en_levels[]')}
              options={enlevels}
              onChange={handleChangeEnLevels}
              mode='multiple'
              maxTagCount='responsive'
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
        </Form>
        <CreateEmployeeLink to={routes.employees_create.path} style={{ float: 'right' }} >
          <Button type='primary'>Create</Button>
        </CreateEmployeeLink>
      </div>
      <Row gutter={16}>
        {loading
          ? <Spin className='custom-spinner'  size="large" />
          : (data?.data.map(item => <EmployeeCard key={item.id} employee={item}/>))}
      </Row>
      { data?.data
        && <Pagination size='small' {...pagination} onChange={handlePaginationChange} style={{ marginTop: 16 }}/>}
    </>
  )
};

export default EmployeesPage;