import moment from 'moment';
import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {useQuery} from '@redux-requests/react';
import {DATE_TIME_FORMAT} from '../../../constants/config';
import useFormLoader from '../../../hooks/useFormLoader';
import CommentForm from '../../../components/CommentForm';
import useSearchParams from '../../../hooks/useSearchParams';
import mapUrlSearchParams from '../../../utils/mapUrlSearchParams';
import {Table, Spin, Space, Descriptions, Typography, Button} from 'antd';
import {fetchApplicant, fetchApplicantCommentsList, createApplicantComment} from '../../../store/applicants/actions';
import routes from "../../../constants/routes";
import {formatDate} from '../../../helpers';
import {DownloadOutlined} from "@ant-design/icons";
import withRole from "../../../hoc/withRole";
import {ADMIN, HR_L1, HR_L2} from "../../../constants/roles";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";


const commentsColumns = [
  {
    title: 'Comment',
    dataIndex: 'content',
    key: 'content',
    fixed: 'left',
    render: report => <ReactMarkdown remarkPlugins={[remarkGfm]} >{report}</ReactMarkdown>,
  },
  {
    title: 'Name',
    dataIndex: 'author',
    key: 'author',
    sorter: true,
    render: author => <Link to={routes.employees_view.path.replace(':id', author?.employee.id)}>{author?.employee.full_name}</Link>
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: true,
    render: date => formatDate(moment(date), DATE_TIME_FORMAT)
  }
];

const ApplicantCommentsPage = styled(Space).attrs(() => ({
  direction: 'vertical',
  size: 'large'
}))`
  display: flex;
`;

const ApplicantsCommentsPage = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const {data: applicant, loading: applicantLoading} = useFormLoader(fetchApplicant, id);

  const {data: comments, loading: commentsLoading, load} = useQuery({
    type: fetchApplicantCommentsList,
    action: fetchApplicantCommentsList,
    variables: [id, searchParams],
    requestKey: id + searchParams.toString(),
    autoLoad: true,
    autoReset: true,
  });

  const handleSubmit = useCallback(
    data => dispatch(createApplicantComment(id, data)).then(load),
    [id, dispatch, searchParams, load]
  );
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  if (applicantLoading) {
    return <Spin size='large'/>
  }
  const ApplicantEditLink = withRole([ADMIN, HR_L1, HR_L2])(Link);

  return (
    <ApplicantCommentsPage>
      <Descriptions
        title={
          <Typography.Title level={3}>Applicant</Typography.Title>
        }
        extra={
          <>
            <ApplicantEditLink to={routes.applicants_edit.path.replace(':id', id)}>
              <Button type='primary'>Edit</Button>
            </ApplicantEditLink>
          </>
        }
        column={8} layout='vertical' size='small' bordered>
        <Descriptions.Item label='Name'>{applicant.full_name}</Descriptions.Item>
        <Descriptions.Item label='Interview date'>{formatDate(moment(applicant.interview_date), DATE_TIME_FORMAT)}</Descriptions.Item>
        <Descriptions.Item label='Domain'>{applicant.domain}</Descriptions.Item>
        <Descriptions.Item label='Status'>{applicant.status}</Descriptions.Item>
        <Descriptions.Item label='Cv file'>{applicant.cv_file && <Button type='link' size='small' icon={<DownloadOutlined />} target='_blank' href={applicant.cv_file} />}</Descriptions.Item>
      </Descriptions>
      <Table
        bordered
        rowKey='id'
        columns={commentsColumns}
        loading={commentsLoading}
        dataSource={comments?.data}
        onChange={handleChangeTable}
        pagination={false}
        size='small'
      />
      <CommentForm onSubmit={handleSubmit}/>
    </ApplicantCommentsPage>
  )
}

export default ApplicantsCommentsPage;