import {Link} from "react-router-dom";
import routes from "../../../constants/routes";
import {Button, Popconfirm, Space} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import React, {useMemo} from "react";

const useAppleColumns = (handleDelete) => useMemo(() => ([
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    width: 120,
    render: (_, { employee: { id, first_name, last_name } }) => <Link to={routes.employees_view.path.replace(':id', id)}>{first_name} {last_name}</Link>
  },
  {
    title: 'Serial',
    dataIndex: 'serial',
    key: 'serial',
    render: (serial) => <Link to={routes.wh.path + '/?serial='+serial}>{serial}</Link>
  },
  {
    title: 'Title',
    dataIndex: 'specifications.title',
    key: 'specifications',
    render: (_, {specifications}) =>{
      return specifications?.title
    }
  },
  {
    title: 'CPU',
    dataIndex: 'specifications.cpu',
    key: 'specifications',
    render: (_, {specifications}) =>{
      return specifications?.cpu
    }
  },
  {
    title: 'GPU',
    dataIndex: 'specifications.gpu',
    key: 'specifications',
    render: (_, {specifications}) =>{
      return specifications?.gpu
    }
  },
  {
    title: 'RAM',
    dataIndex: 'specifications.ram',
    key: 'specifications',
    render: (_, {specifications}) =>{
      return specifications?.ram
    }
  },
  {
    title: 'SSD',
    dataIndex: 'specifications.ssd',
    key: 'specifications',
    render: (_, {specifications}) =>{
      return specifications?.ssd
    }
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    sorter: true,
    render: (_, { employee }) => employee?.office
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
  },
  {
    title: 'Checked at',
    dataIndex: 'checked_at',
    key: 'checked_at',
    sorter: true,
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: id => (
      <Space>
        <Link to={routes.warehouses_edit.path.replace(':id', id)}>
          <Button type="link" size="small" icon={<EditOutlined/>}/>
        </Link>
        <Popconfirm title="Are you sure?" onConfirm={() => handleDelete(id)}>
          <Button type="link" size="small" icon={<DeleteOutlined/>}/>
        </Popconfirm>
      </Space>
    ),
    width: 90,
    align: 'center',
  }
]), [handleDelete]);

export default useAppleColumns;