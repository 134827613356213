import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import MenuDropDown from './MenuDropDown';
import SummaryModal from './SummaryModal';
import SwappedTooltip from './SwappedTooltip';
import TooltipComments from './TooltipComments';
import { message, Dropdown, notification } from 'antd';
import { updateWorkLog } from '../../store/work_logs/actions';
import { createComment } from '../../store/jobs/actions';
import { StyledDropdownContextMenu } from './styles/styles';
import { formatTimeFromHours } from '../../helpers';

const WorkLogDropDown = ({
  setWorkLog,
  workLog,
  jobComments,
  setJobComments,
  job,
  onToggleEdit
}) => {
  const dispatch = useDispatch();
  const [isModalVisible, setModalVisible] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleShowModal = useCallback(() => setModalVisible(!isModalVisible), [isModalVisible]);
  const handleVisibleChange = useCallback(flag => setDropdownVisible(flag), [setDropdownVisible]);
  const handleDropdownVisibleChange = useCallback(() => setDropdownVisible(!isDropdownVisible), [isDropdownVisible]);
  const handleSubmit = useCallback(async (value) => {
    const {
      data,
      error
    } = await dispatch(createComment(job.id, {...value, date: workLog.date}));

    const comment = data?.data;
    if (!error && comment) {
      const newComments = jobComments?.slice() || [];
      newComments.push(comment);
      setJobComments(newComments);
    }

    return error;
  }, [jobComments, dispatch, job.id, setJobComments, workLog.date]);
  const handleSelect = useCallback(value => dispatch(updateWorkLog(workLog.id, value))
    .then(({
      data,
      error
    }) => {
      if (!error && data) {
        setWorkLog({
          ...data?.data,
          time: formatTimeFromHours(data?.data.time)
        });
        setJobComments([...jobComments, data?.data?.job?.comments]);
        message.info('Worklog saved!');
      }
    }), [dispatch, jobComments, setJobComments, setWorkLog, workLog.id]);
  const handleSuccess = useCallback(() => notification.success({ message: 'Comment added successfully!' }), []);

  const commentsOrderByDesc = useMemo(() => _.orderBy(jobComments, 'created', 'desc'), [jobComments]);
  const swappedManager = (workLog.manager.id !== job?.manager.id) ? workLog.manager.full_name : null;
  const swappedEmployee = (workLog.employee.id !== job?.employee.id) ? workLog.employee.full_name : null;

  return (
    <>
      {(swappedManager || swappedEmployee) && <SwappedTooltip
        workLog={workLog}
        swappedManager={swappedManager}
        swappedEmployee={swappedEmployee}
        handleShowModal={handleShowModal}
      />}
      <Dropdown
        overlay={MenuDropDown(
          workLog,
          handleSelect,
          handleSubmit,
          handleSuccess,
          handleShowModal,
          handleDropdownVisibleChange
        )}
        visible={isDropdownVisible}
        onVisibleChange={handleVisibleChange}
        trigger={['contextMenu']}
        onClick={onToggleEdit}
      >
        <StyledDropdownContextMenu>
          <span className="work-log-time">{workLog.time}</span>
        </StyledDropdownContextMenu>
      </Dropdown>
      {!!jobComments?.length && <TooltipComments
        comments={jobComments}
        handleShowModal={handleShowModal}
        commentsOrderByDesc={commentsOrderByDesc}
      />}
      <SummaryModal
        workLog={workLog}
        jobTitle={job?.title}
        comments={jobComments}
        isModalVisible={isModalVisible}
        handleShowModal={handleShowModal}
        handleSubmit={handleSubmit}
        handleSuccess={handleSuccess}
      />
    </>
  );
};

export default React.memo(WorkLogDropDown);