import { useCallback } from 'react';
import useSearchParams from './useSearchParams';
import { APP_TABLE_DEFAULT_SIZE } from '../constants/config';


const usePaginationChange = () => {
  const [, setSearchParams] = useSearchParams();

  return useCallback((page, pageSize) => setSearchParams({
    page: page > 1 ? page : null,
    per_page: (pageSize && pageSize !== APP_TABLE_DEFAULT_SIZE && pageSize) || null,
  }), [setSearchParams])
};

export default usePaginationChange;