import React from 'react';
import { Typography } from 'antd';
import routes from '../../../constants/routes';
import HolidayForm from '../../../components/HolidayForm';
import useFormHandlers from '../../../hooks/useFormHandlers';
import { createHoliday } from '../../../store/holidays/actions';


const HolidaysCreatePage = () => {
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
    action: createHoliday,
    successMessage: 'Holiday added successfully!',
    successPath: routes.holidays.path
  });

  return (
    <>
      <Typography.Title level={3}>Create holiday</Typography.Title>
      <HolidayForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
      />
    </>
  )
}

export default HolidaysCreatePage;
