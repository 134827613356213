import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Input, Typography } from 'antd';
import { sendResetPasswordLink } from '../../store/auth/actions';
import useFormSubmit from '../../hooks/useFormSubmit'


const validationRules = {
  email: [
    { required: true, message: 'Please input your Email!' },
    { type: 'email', message: 'The input is not valid E-mail!' }
  ]
};

// TODO: onSuccess add message check email
const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const [ form ] = Form.useForm();
  const onSubmit = useCallback(values => dispatch(sendResetPasswordLink(values)), [dispatch]);
  const { submitting, handleSubmit } = useFormSubmit(form, onSubmit, console.log);

  return (
    <>
      <Typography.Title>Recover password</Typography.Title>
      <Form
        form={form}
        validateTrigger={['onBlur']}
        onFinish={handleSubmit}
      >
        <Form.Item name='email' rules={validationRules.email}>
          <Input placeholder='Email'/>
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={submitting} block>Email reset password link</Button>
        </Form.Item>
      </Form>
    </>
  )
};

export default ForgotPasswordPage;