import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@redux-requests/react';


const useFormLoader = (action, id) => {
  const dispatch = useDispatch();
  const { data, loading, error, pristine } = useQuery({ type: action, requestKey: id.toString() });

  useEffect(() => dispatch(action(id)), [dispatch, action, id]);

  return { data: data?.data, loading: loading || pristine, error };
}

export default useFormLoader;