import { createAction } from 'redux-smart-actions';

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchCalendarEvents = createAction('CALENDAR_EVENTS/FETCH_LIST',(searchParams = '') => ({
  request: {
    url: `calendar?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
    cache: 5,
  },
}));