import {useCallback,  useState} from 'react';
import { Typography, Space } from 'antd';
import copyToClipboard from 'clipboard-copy';
import {CheckOutlined, CopyOutlined} from '@ant-design/icons';

const PhoneCopyable = (props) => {
  const [ phone ] = useState(props.phone);
  const [copied, setCopied] = useState(false);

  const handleCopiedChange = useCallback(() => setCopied(copied => !copied), [setCopied]);

  const handleCopyable = async () => {
    handleCopiedChange();
    await copyToClipboard(phone);
  };

  return (
    <>
      <Space>
        <Typography.Text code>
          {phone}
        </Typography.Text>
          {copied
            ? <CheckOutlined style={{color: '#52c41a'}} />
            : <CopyOutlined onClick={handleCopyable} style={{color: '#1890ff'}} />
          }
      </Space>
    </>
  );
}

export default PhoneCopyable;