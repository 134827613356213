export const FAQ_TYPE = {
  GENERAL: 'general',
  OFFICE: 'office',
  ROLE: 'role',
};



export const types = [
  {
    value:  FAQ_TYPE.GENERAL,
    label: 'General'
  },
  {
    value:  FAQ_TYPE.OFFICE,
    label: 'Office'
  },
  {
    value:  FAQ_TYPE.ROLE,
    label: 'Role'
  },
];