import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import en_GB from 'antd/lib/locale-provider/en_GB';
import 'antd/dist/antd.css';
import 'moment/locale/en-gb';
import App from './components/App';
import validateMessages from './constants/validateMessages';
import { configureStore } from './store';

moment.locale('en-gb');
const store = configureStore();

ReactDOM.render((
  <ConfigProvider locale={en_GB} form={{ validateMessages }}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
), document.getElementById('root'));
