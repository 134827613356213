import React, {useMemo} from 'react';
import {Form} from 'antd';
import EditableCell from './EditableCell';
import {formatTimeFromHours} from '../../helpers';
import {EditableContext} from '../../pages/work_log/week_simple';


const EditableComponents = columns => {

  /* Hook useMemo deps must without props  */
  const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return useMemo(() => (<Form form={form} component={false}>
        <EditableContext.Provider value={{form}}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    ), [index, form]);
  };

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => {
        const work_logs = record?.work_logs.map(workLog => {
          const time = workLog.time > 0 ? formatTimeFromHours(workLog.time) : formatTimeFromHours(0);
          return {...workLog, time};
        });
        return ({
          record: {...record, work_logs},
          editable: col.editable,
          dataIndex: col.dataIndex,
          date: col.date,
        });
      }
    };
  });

  const components = ({
    body: {
      row: EditableRow,
      cell: EditableCell
    },
  })

  return [components, mergedColumns];
};

export default EditableComponents;