import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@redux-requests/react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from '../../../constants/routes';
import { Descriptions, Spin, Typography } from 'antd';
import { hasAccessByRole } from '../../../helpers';
import { ADMIN, HR_L1, OM } from '../../../constants/roles';
import useSearchParams from '../../../hooks/useSearchParams';
import { fetchWarehouseItemBySerial } from '../../../store/warehouses/actions';
import { accountSelector, isLoggedInSelector } from '../../../store/auth/selectors';
import { Spinner } from '../../../styles/styles';

const ItemInfoPage = () => {
  const history = useHistory();
  const [searchParams] = useSearchParams();

  const serial = searchParams.get('serial');

  const isLoggedIn = useSelector(isLoggedInSelector);

  const { role } = useSelector(accountSelector);

  const { data, loading, pristine } = useQuery({
    type: fetchWarehouseItemBySerial,
    action: fetchWarehouseItemBySerial,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
    autoReset: true,
  });

  const specifications = useMemo(() => {
    if (data?.specifications) {
      return Object.entries(data?.specifications).map(([key, value], i) => {
        return (
          <Descriptions.Item key={i} label={key}>{value}</Descriptions.Item>
        )
      })
    }
  }, [data]);

  useEffect(() => {
    const hasAccess = hasAccessByRole([ADMIN, HR_L1, OM], role);
    if (isLoggedIn && hasAccess && !loading && !pristine) {
      console.log(data)
        const path = data['id']
          ? routes.warehouses_edit.path.replace(':id', data.id)
          : `${routes.warehouses_create.path}?serial=${serial}`;
        history.replace(path)
    }
  }, [role, data, history, isLoggedIn, loading, pristine, serial]);


  if (loading && !pristine) {
    return <Spinner><Spin size='large'/></Spinner>;
  }

  return (
   <>
     <Typography.Title>
       <span style={{textTransform: 'capitalize'}}>
         {data?.type}
       </span> {
       `${ data?.specifications?.title}`}
     </Typography.Title>
     <Descriptions
       column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
       labelStyle={{width: '100px', textTransform: 'capitalize', fontWeight: 600}}
       contentStyle={{width: '300px'}}
       style={{width: '40%'}}
       bordered
     >
       <Descriptions.Item label='Serial'>{data?.serial}</Descriptions.Item>
       {specifications}
       <Descriptions.Item label='Checked at'>{data?.checked_at}</Descriptions.Item>
     </Descriptions>
   </>
  )
};

export default ItemInfoPage;