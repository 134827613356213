import { useEffect } from 'react';
import { Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from '@redux-requests/react';
import routes from '../../../../../constants/routes';
import useFormHandlers from '../../../../../hooks/useFormHandlers';
import { fetchEmployee } from '../../../../../store/employees/actions';
import VacationLogForm from '../../../../../components/VacationLogForm';
import { createVacationLog } from '../../../../../store/vacation_logs/actions';


const VacationLogCreate = () => {
  const { id } = useParams();

  const [handleSubmit, handleSuccess, handleCancel] = useFormHandlers({
    action: createVacationLog,
    successMessage: 'Log vacation added successfully!',
    successPath: routes.employees_vacation_logs.path.replace(':id', id)
  });

  //TODO: fetch employee data from cache
  const { data, loading, pristine, load } = useQuery({
    type: fetchEmployee,
    requestKey: id.toString(),
    autoReset: true,
  });

  const employee = data?.data;

  useEffect(() => {
    pristine && load();
  }, [load, pristine]);

  return (
    <>
      <Typography.Title level={3}>Log vacation</Typography.Title>
      { (loading || pristine) ? (
        <Spin/>
      ) : (
        <VacationLogForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          initialValues={{employee}}
          employeeSelectDisabled={true}
        />
      )}
    </>
  )
}

export default VacationLogCreate;