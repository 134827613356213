import React, {useCallback, useEffect, useMemo} from 'react';
import { useQuery } from '@redux-requests/react';
import {
  formatToFixed, joinSearchString,
} from '../../work_log/tableHelpers';
import {
  formatDate, formatTimeFromHours,
  getDate,
  getStartOfMonth,
} from '../../../helpers';
import {DatePicker, Form, Select, Table} from 'antd';
import useSearchParams from '../../../hooks/useSearchParams';
import mapUrlSearchParams from '../../../utils/mapUrlSearchParams';
import '../../work_log/styles/style.scss';
import {domains, roles} from "../../../constants/employees";
import {Link} from "react-router-dom";
import routes from "../../../constants/routes";
import moment from "moment";
import {fetchWithWorklogsEmployeeList, searchEmployeeOptions} from "../../../store/employees/actions";
import usePagination from "../../../hooks/usePagination";
import business from 'moment-business';
import {fetchHolidaysList} from "../../../store/holidays/actions";


export const EditableContext = React.createContext(null);


const { RangePicker } = DatePicker;

const EmployeesOvertimesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = '';
  /* hook add default dates range (current week) */
  useEffect(() => {
    if (!searchParams.has('start_date') || !searchParams.has('end_date')) {
      setSearchParams({start_date: formatDate(getStartOfMonth()), end_date: formatDate(moment())});
    }
  }, [searchParams, setSearchParams]);

  const startDate = searchParams.get('start_date');
  const endDate = searchParams.get('end_date');

  const arrayOfEmployeeIds = searchParams.getAll('ids[]');
  const stringEmployeeIds = useMemo(() => joinSearchString(arrayOfEmployeeIds, 'ids'), [arrayOfEmployeeIds]);

  const handleChangeDomains = useCallback(domains => setSearchParams({ 'domains[]': domains }), [setSearchParams]);
  const handleChangeRoles = useCallback(roles => setSearchParams({ 'roles[]': roles }), [setSearchParams]);
  const handleChangeEmployee = useCallback(employee_ids => setSearchParams({ 'ids[]': employee_ids }), [setSearchParams]);
  /* filter onChange */
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );
  const handleChangeRangePicker = useCallback(
    ([startDate, endDate]) => {
      setSearchParams({start_date: formatDate(startDate), end_date: formatDate(endDate)})
    },
    [setSearchParams]
  );

  const {data, loading} = useQuery({
    type: fetchWithWorklogsEmployeeList,
    action: fetchWithWorklogsEmployeeList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
  });

  const { data: holidays } = useQuery({
    type: fetchHolidaysList,
    action: fetchHolidaysList,
    autoLoad: true,
  });
  const { data: employees } = useQuery({
    type: searchEmployeeOptions,
    action: searchEmployeeOptions,
    variables: [query, stringEmployeeIds],
    requestKey: query + stringEmployeeIds,
    autoLoad: true,
  });

  const pagination = usePagination(data?.meta);

  const business_hours = (startDate, endDate, holidays) => {
    startDate = getDate(startDate);
    endDate = getDate(endDate);
    let now = startDate.clone(), holidaysNum = 0;
    while (now.isSameOrBefore(endDate)) {
      if(holidays?.data.find(holiday => now.isSame(holiday.date, 'day'))){
        holidaysNum++;
      }
      now.add(1, 'days');
    }
    return (business.weekDays(startDate.startOf(), endDate.add(1, 'days').startOf()) - holidaysNum) * 8;
  }
  const worked_hours = (work_logs) => {
    return work_logs.reduce((total, { time }) => total + time, 0)
  }

  const planned_hours = (startDate, endDate, holidays, vacation_logs) => {
    return business_hours(startDate, endDate, holidays) - (vacation_logs?.data.length*8)
  }

  const overtimes = (work_logs, startDate, endDate, holidays, vacation_logs) => {
    return worked_hours(work_logs) - planned_hours(startDate, endDate, holidays, vacation_logs) ;
  }

  return (
    <>
      <div className='section-filters'>
        <Form
          layout="inline"
          className="components-table-demo-control-bar"
        >
          <Form.Item>
            <RangePicker style={{width: 225}} value={[getDate(startDate), getDate(endDate)]} onChange={handleChangeRangePicker} allowClear={false} />
          </Form.Item>
          <Form.Item>
            <Select
              mode='multiple'
              placeholder='Employee'
              options={employees}
              notFoundContent={null}
              filterOption={true}
              optionFilterProp='label'
              allowClear
              onChange={handleChangeEmployee}
              style={{ width: 220 }}
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Domain'
              mode='multiple'
              maxTagCount='responsive'
              value={searchParams.getAll('domains[]')}
              options={domains}
              onChange={handleChangeDomains}
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Role'
              mode='multiple'
              maxTagCount='responsive'
              value={searchParams.getAll('roles[]')}
              options={roles}
              onChange={handleChangeRoles}
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
        </Form>
      </div>
      <Table
        columns={[
          {
            title: 'First name',
            dataIndex: 'first_name',
            key: 'first_name',
            sorter: true,
            render: (first_name, { id }) => <Link to={routes.employees_view.path.replace(':id', id)}>{first_name}</Link>
          },
          {
            title: 'Last name',
            dataIndex: 'last_name',
            key: 'last_name',
            sorter: true,
            render: (last_name, { id }) => <Link to={routes.employees_view.path.replace(':id', id)}>{last_name}</Link>
          },
          {
            title: 'Worked hours (HH:MM)',
            dataIndex: 'work_logs',
            key: 'work_logs',
            render: (work_logs) => {
              return formatTimeFromHours(worked_hours(work_logs));
            },
          },
          {
            title: 'Worked hours',
            dataIndex: 'work_logs',
            key: 'work_logs',
            render: (work_logs) => {
              return formatToFixed(worked_hours(work_logs));
            },
          },
          {
            title: 'Planned hours',
            dataIndex: 'vacation_logs',
            key: 'vacation_logs',
            render: (vacation_logs) => {
              return formatToFixed( planned_hours(startDate, endDate, holidays, vacation_logs));
            },
          },
          {
            title: 'Overtimes',
            dataIndex: 'vacation_logs',
            key: 'vacation_logs',
            filters: [
              {
                text: 'Overtimes',
                value: 1,
              },
              {
                text: 'Low performance',
                value: 0,
              },
            ],
            render: (vacation_logs, {work_logs}) => {
              const overtimes_hr = overtimes(work_logs, startDate, endDate, holidays, vacation_logs)
              if(overtimes_hr > 0)
                return <b>{formatToFixed( overtimes_hr)}</b>;
              else
                return formatToFixed( overtimes_hr);
            },
            onFilter: (value, record) => {
              if(value)
                return overtimes(record.work_logs, startDate, endDate, holidays, record.vacation_logs) > 0;
              else {
                return overtimes(record.work_logs, startDate, endDate, holidays, record.vacation_logs) < 0;
              }
            },
          },
        ]}
        loading={loading}
        dataSource={data?.data}
        pagination={pagination}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
        scroll={{ x: 1300 }}
      />
    </>
  )
};

export default EmployeesOvertimesPage;