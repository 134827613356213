import React, {useCallback, useEffect} from 'react';
import moment from 'moment';
import {useQuery} from '@redux-requests/react';
import useSearchParams from '../../hooks/useSearchParams';
import {List, Typography} from 'antd';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import {calendarHrEventStyle, formatDate, getStartAndEndDateInCalendar} from '../../helpers';
import debounce from 'lodash.debounce';
import '../../styles/style.scss';
import {fetchApplicantsList} from "../../store/applicants/actions";
import routes from "../../constants/routes";

const localizer = momentLocalizer(moment);

const DashboardPage = props => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setUrlParams = useCallback(({start, end}) => setSearchParams({
    from: formatDate(moment(start)),
    to: formatDate(moment(end)),
    'statuses[]': []
  }), [setSearchParams]);

  useEffect(() => {
    if (!searchParams.has('from') || !searchParams.has('to')) {
      const {startDate: start, lastDate: end} = getStartAndEndDateInCalendar();
      setUrlParams({start, end});
    }
  }, [searchParams, setUrlParams]);

  const {data: applicants, loading: applicantsListLoading} = useQuery({
    type: fetchApplicantsList,
    action: fetchApplicantsList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
  });

  const handleDoubleClickEvent = event => {
    const path = routes.applicants_edit.path;
    return props.history.push(path.replace(':id', event.id));
  };

  const handleRangeChange = useCallback(debounce( range => setUrlParams(range), 500), [setUrlParams]);

  const events = applicants?.data?.map(({id, first_name, last_name, domain, interview_date}) => (
    {
      id: id,
      title: first_name + ' ' + last_name + ' '+ interview_date + ' ' +domain,
      type: 'interview-'+domain,
      end: moment(interview_date),
      start: moment(interview_date),
      allDay: true
    }
  )) || [];

  const interviewsToday = applicants?.data?.filter(function(item) {
      return moment(item.interview_date).isSame(moment(), 'day');
  });
  const ColoredDateCellWrapper = ({children}) =>
    React.cloneElement(React.Children.only(children), {
      style: {
        backgroundColor: 'lightred',
      },
    });

  return (
    <>
        <List
            header={<Typography.Title level={4}>Interviews today</Typography.Title>}
            loading={applicantsListLoading}
            dataSource={interviewsToday}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        title={<span>{item.first_name} {item.last_name}, {item.domain}</span>}
                        description={item.interview_date}
                    />
                </List.Item>
            )}
        >
        </List>
      <Typography.Title level={4}>Interviews</Typography.Title>
        <Calendar
            localizer={localizer}
            events={events}
            views={['month']}
            step={60}
            popup
            showMultiDayTimes
            handleDragStart={() => false}
            onRangeChange={handleRangeChange}
            eventPropGetter={calendarHrEventStyle}
            components={{
              timeSlotWrapper: ColoredDateCellWrapper,
            }}
            onDoubleClickEvent={handleDoubleClickEvent}
            style={{minHeight: '500px', marginTop: '15px'}}
        />
    </>
  );
};

export default DashboardPage;