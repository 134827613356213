export const VACATION_LOG_TYPES = {
  DAY_OFF: 'day_off',
  VACATION: 'vacation',
  SICK: 'sick',
  VACATION_REFILL: 'vacation_refill',
  ABSENT: 'absent',
  SICK_PROVED: 'sick_proved',
  SICK_REFILL: 'sick_refill'
};

export const typeOptions = [
  {
    value: VACATION_LOG_TYPES.DAY_OFF,
    label: 'Day Off'
  },
  {
    value: VACATION_LOG_TYPES.VACATION,
    label: 'Vacation'
  },
  {
    value: VACATION_LOG_TYPES.SICK,
    label: 'Sick'
  },
  {
    value: VACATION_LOG_TYPES.VACATION_REFILL,
    label: 'Vacation Refill'
  },
  {
    value: VACATION_LOG_TYPES.ABSENT,
    label: 'Absent'
  },
  {
    value: VACATION_LOG_TYPES.SICK_PROVED,
    label: 'Sick Proved'
  },
  {
    value: VACATION_LOG_TYPES.SICK_REFILL,
    label: 'Sick Refill'
  }
];