import { createAction } from 'redux-smart-actions';

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchFaqsList = createAction('FAQS/FETCH_LIST', (searchParams = '') => ({
  request: {
    url: `/faqs?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
    cache: 10,
  },
}));

/**
 * @param {Object} data
 * @param {string} data.date
 */
export const createFaq = createAction('FAQS/CREATE', data => ({
  request: {
    url: '/faqs',
    method: 'POST',
    data
  },
}));

/**
 * @param {number} id
 */
export const fetchFaq = createAction('FAQS/FETCH', id => ({
  request: {
    url: `/faqs/${id}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 * @param {Object} data
 * @param {string} data.date
 */
export const updateFaq = createAction('FAQS/UPDATE', (id, data) => ({
  request: {
    url: `/faqs/${id}`,
    method: 'PUT',
    data,
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 */
export const deleteFaq = createAction('FAQS/DELETE', id => ({
  request: {
    url: `/faqs/${id}`,
    method: 'DELETE',
  },
  meta: {
    requestKey: id.toString(),
  },
}));
