import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { notification, Typography } from 'antd';
import routes from '../../../../constants/routes';
import JobForm from '../../../../components/JobForm';
import { createJob } from '../../../../store/jobs/actions';


const JobsCreatePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = useCallback(data => dispatch(createJob(data)), [dispatch]);
  const handleSuccess = useCallback(() => {
    notification.success({ message: 'Job added successfully!' });
    history.push(routes.work_log_week_simple.path);
  }, [history]);
  const handleCancel = useCallback(() => history.goBack(), [history]);

  return (
    <>
      <Typography.Title level={3}>Create job</Typography.Title>
      <JobForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
      />
    </>
  )
};


export default JobsCreatePage;