import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from '../../constants/routes';
import roles from '../../constants/roles';
import {hasAccessByRole} from '../../helpers';
import { accountSelector } from '../../store/auth/selectors';


const PrivateRoute = ({ component: Component, roles: accessRoles, ...rest }) => {
  const { role } = useSelector(accountSelector);

  return (
    <Route {...rest} render={ props => {
      if (!hasAccessByRole(accessRoles, role)) {
        return <Redirect to={routes.dashboard.path} />
      }
      return <Component {...props} />
    }}/>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  roles: PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
    if (!roles.includes(propValue[key])) {
      return new Error(`Invalid prop ${propFullName} supplied to ${componentName}. Validation failed.`);
    }
  }).isRequired,
}


export default PrivateRoute;