import { Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import routes from '../../../constants/routes';
import useFormHandlers from '../../../hooks/useFormHandlers';
import useFormLoader from '../../../hooks/useFormLoader';
import PasswordForm from '../../../components/PasswordForm';
import { fetchPassword, updatePassword } from '../../../store/passwords/actions';


const PasswordsEditPage = () => {
  const { id } = useParams();
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
    action: updatePassword,
    successMessage: 'Password updated successfully!',
    successPath: routes.passwords.path,
    actionParams: [id]
  });
  const { data, loading } = useFormLoader(fetchPassword, id);

  return (
    <>
      <Typography.Title level={3}>Edit password</Typography.Title>
      { loading ? (
        <Spin/>
      ) : (
        <PasswordForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          initialValues={data}
        />
      ) }
    </>
  )
}

export default PasswordsEditPage;