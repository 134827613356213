import styled from 'styled-components';
import NavBar from '../components/NavBar';

const Content = styled.div`
  padding: 16px 24px;
  
  > .section-filters {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 16px;
  }
`;

const PrivateLayout = ({ children }) => (
  <>
    <NavBar/>
    <Content>
      {children}
    </Content>
  </>
);

export default PrivateLayout;