import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../constants/routes';
import {Button, Popconfirm, Space, Tag} from 'antd';
import LinkSpecial from '../../../components/LinkSpecial';
import {getColor, getLabel, hasAccessByRole} from '../../../helpers';
import {ADMIN, HR_L1, HR_L2, PM_L1, TECH_L1} from '../../../constants/roles';
import {platforms, domains, statuses} from '../../../constants/applicants';
import { DeleteOutlined, EditOutlined, MessageOutlined, DownloadOutlined } from '@ant-design/icons';


const useColumns = (onConfirmDelete, role) => useMemo(() => ([
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    width: 30,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, { id, first_name, last_name }) => {
      return <Link to={routes.applicants_comments.path.replace(':id', id)}>
        {first_name} {last_name}
      </Link>
    },
    width: 220,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    render: email => <LinkSpecial mode='email'>{email}</LinkSpecial>,
    width: 220,
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    sorter: true,
    render: phone => <LinkSpecial mode='phone'>{phone}</LinkSpecial>,
    width: 220,
  },
  {
    title: 'Birthday',
    dataIndex: 'birthday',
    key: 'birthday',
    sorter: true,
    width: 150,
    responsive: ['md'],
  },
  {
    title: 'Start Working',
    dataIndex: 'started_at',
    key: 'started_at',
    sorter: true,
    width: 150,
    hidden: !hasAccessByRole([ADMIN, PM_L1, TECH_L1, HR_L1, HR_L2], role),
    responsive: ['md'],
  },
  {
    title: 'Interview date',
    dataIndex: 'interview_date',
    key: 'interview_date',
    sorter: true,
    width: 150,
  },
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
    sorter: true,
    render: domain => getLabel(domains, domain),
    width: 150,
    hidden: !hasAccessByRole([ADMIN, PM_L1, TECH_L1, HR_L1, HR_L2], role),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    render: status => (
        <span>
          <Tag color={getColor(statuses, status)} key={status}>
            {getLabel(statuses, status)}
          </Tag>
        </span>
    ),
    width: 150,
    hidden: !hasAccessByRole([ADMIN, HR_L1, HR_L2, PM_L1, TECH_L1], role),
  },
  {
    title: 'Interviewers',
    dataIndex: 'interviewers',
    key: 'interviewers',
    sorter: true,
    render: interviewers => (
      <span>{interviewers.map(employee =>
          <Tag>
            <Link to={routes.employees_view.path.replace(':id', employee.id)}>{employee.full_name}</Link>
          </Tag>
      )}
      </span>
    ),
    width: 150,
    hidden: !hasAccessByRole([ADMIN, HR_L1, HR_L2, PM_L1, TECH_L1], role),
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    sorter: true,
    render: platform => getLabel(platforms, platform),
    width: 150,
    hidden: !hasAccessByRole([ADMIN, PM_L1, TECH_L1, HR_L1, HR_L2], role),
    responsive: ['lg'],
  },
  {
    title: 'Responsible person',
    dataIndex: 'responsible_person',
    key: 'responsible_person',
    sorter: true,
    render: ({ first_name, last_name }) => `${first_name} ${last_name}`,
    width: 150,
    hidden: !hasAccessByRole([ADMIN, PM_L1, TECH_L1, HR_L1, HR_L2], role),
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: (_, {id, cv_file}) => (
      <Space size='small'>
        {cv_file && <Button type='link' size='small' icon={<DownloadOutlined />} target='_blank' href={cv_file} />}
        {hasAccessByRole([ADMIN, HR_L1, HR_L2], role) && <Link to={routes.applicants_edit.path.replace(':id', id)}>
          <Button type='link' size='small' icon={<EditOutlined />}/>
        </Link>}
        {hasAccessByRole([ADMIN, HR_L1, HR_L2, PM_L1, TECH_L1], role) && <Link to={routes.applicants_comments.path.replace(':id', id)}>
          <Button type='link' size='small' icon={<MessageOutlined />}/>
        </Link>}
        {hasAccessByRole([ADMIN, HR_L1, HR_L2], role) && <Popconfirm title='Are you sure?' onConfirm={() => onConfirmDelete(id)}>
          <Button type='link' size='small' icon={<DeleteOutlined />}/>
        </Popconfirm>}
      </Space>
    ),
    align: 'right',
    hidden: !hasAccessByRole([ADMIN, HR_L1, HR_L2, PM_L1, TECH_L1], role),
    width: 120,
    fixed: 'right'
  }
]), [onConfirmDelete, role]);


export default useColumns;