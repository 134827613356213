import { createAction } from 'redux-smart-actions';

/**
 * @param {string} cipher
 */
export const fetchJob = createAction('JOB/FETCH', cipher => ({
  request: {
    url: `/reports/${cipher}/jobs`,
    method: 'GET',
  },
  meta: {
    requestKey: cipher.toString(),
    getData: ({ data }) => data,
  },
}));

/**
 * @param {string} cipher
 * @param {URLSearchParams} [searchParams]
 */
export const fetchReportsList = createAction('WORK_LOGS/FETCH_LIST', (cipher, searchParams = '') => ({
  request: {
    url: `/reports/${cipher}/work-logs?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: cipher + searchParams.toString(),
  },
}));

/**
 * @param {string} cipher
 * @param {Object} data
 */
export const createReport = createAction('WORK_LOGS/CREATE', (cipher, data) => ({
  request: {
    url: `/reports/${cipher}/work-logs`,
    method: 'POST',
    data
  },
}));

/**
 * @param {string} cipher
 * @param {number} id
 */
export const fetchReport = createAction('WORK_LOGS/FETCH', (cipher, id) => ({
  request: {
    url: `/reports/${cipher}/work-logs/${id}`,
    method: 'GET',
  },
  meta: {
    requestKey: cipher + id.toString(),
    getData: ({ data }) => data,
  },
}));

/**
 * @param {string} cipher
 * @param {number} id
 * @param {Object} data
 */
export const updateReport = createAction('WORK_LOGS/UPDATE', (cipher, id, data) => ({
  request: {
    url: `/reports/${cipher}/work-logs/${id}`,
    method: 'PUT',
    data
  },
}));