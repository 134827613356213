import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import {getLabel} from '../../../../helpers';
import { EditOutlined } from '@ant-design/icons';
import routes from '../../../../constants/routes';
import {domains, enlevels, offices, roles} from '../../../../constants/employees';
import LinkSpecial from '../../../../components/LinkSpecial';


const useColumns = () => useMemo(() => ([
  {
    title: 'First name',
    dataIndex: 'first_name',
    key: 'first_name',
    sorter: true,
    render: (first_name, { id }) => <Link to={routes.employees_view.path.replace(':id', id)}>{first_name}</Link>
  },
  {
    title: 'Last name',
    dataIndex: 'last_name',
    key: 'last_name',
    sorter: true,
    render: (last_name, { id }) => <Link to={routes.employees_view.path.replace(':id', id)}>{last_name}</Link>
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    render: email => <LinkSpecial mode='email'>{email}</LinkSpecial>
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    sorter: true,
    render: phone => <LinkSpecial mode='phone'>{phone}</LinkSpecial>
  },
  {
    title: 'IM nickname',
    dataIndex: 'im_nickname',
    key: 'im_nickname',
    sorter: true,
    render: mention => <LinkSpecial mode='im'>{mention}</LinkSpecial>
  },
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
    sorter: true,
    render: domain => getLabel(domains, domain),
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    sorter: true,
    render: role => getLabel(roles, role),
  },
  {
    title: 'Office',
    dataIndex: 'office',
    key: 'office',
    render: office => getLabel(offices, office),
  },
  {
    title: 'English level',
    dataIndex: 'en_level',
    key: 'en_level',
    render: en_level => getLabel(enlevels, en_level),
  },
  {
    title: 'Birthday',
    dataIndex: 'birthday',
    key: 'birthday',
    sorter: true,
    width: 150,
  },
  {
    title: 'Start Working',
    dataIndex: 'started_at',
    key: 'started_at',
    sorter: true,
    width: 150,
  },
  {
    title: 'Status',
    dataIndex: 'ended_at',
    key: 'status',
    sorter: true,
    //TODO: check ended_at < current_date
    render: ended_at => ended_at ? 'archive' : 'active',
    width: 150,
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: id => (
      <Link to={routes.employees_edit.path.replace(':id', id)}>
        <Button type='link' size='small' icon={<EditOutlined />}/>
      </Link>
    ),
    width: 65,
    align: 'center',
    fixed: 'right'
  }
]), []);


export default useColumns;