import React from 'react';
import moment from 'moment';
import {CommentOutlined} from '@ant-design/icons';
import {Comment, List, Tooltip, Typography} from 'antd';
import {DATE_FORMAT, TIME_FORMAT} from '../../../constants/config';
import {StyledIcon, StyledTooltip} from '../styles/styles';
import {formatDate} from '../../../helpers';


const TooltipComments = ({handleShowModal, commentsOrderByDesc}) => {
  const commentsList = (<List
    className='comment-list'
    itemLayout='horizontal'
    dataSource={commentsOrderByDesc}
    renderItem={comment => (
      <li>
        <Comment
          author={<Typography.Title
            level={5}
            style={{color: '#FFFFFF'}}>
            {comment.author.employee.full_name}
          </Typography.Title>}
          content={<span style={{color: '#8f9797'}}>{comment.content}</span>}
          datetime={formatDate(moment(comment?.created || comment.date), `${DATE_FORMAT} ${TIME_FORMAT}`)}
        />
      </li>
    )}
  />);

  return (
    <StyledTooltip right={3}>
      <Tooltip
        title={commentsList} placement='right' color='#0F1315'>
        <StyledIcon onClick={handleShowModal}><CommentOutlined/></StyledIcon>
      </Tooltip>
    </StyledTooltip>
  );
};

export default TooltipComments;