import React, {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import withRole from '../../hoc/withRole';
import SearchSelect from '../SearchSelect';
import routes from '../../constants/routes';
import {useQuery} from '@redux-requests/react';
import {Button, Select, Space, Form} from 'antd';
import {ADMIN, PM_L1} from '../../constants/roles';
import {statusOptions} from '../../constants/statuses';
import {searchClientOptions} from '../../store/clients/actions';
import {joinSearchString} from '../../pages/work_log/tableHelpers';
import {searchEmployeeOptions} from '../../store/employees/actions';
import {groupByOptions, GROUPS, platformOptions, typeOptions} from '../../constants/jobs';


const CreateJobLink = withRole([ADMIN, PM_L1])(Link);
const AvatarMetaTableFilter = ({searchParams, setSearchParams, rightSectionFilters}) => {
  const query = '';
  const dispatch = useDispatch();

  const arrayOfClientIds = searchParams.getAll('client_ids[]');
  const arrayOfManagerIds = searchParams.getAll('manager_ids[]');
  const arrayOfEmployeeIds = searchParams.getAll('employee_ids[]');

  const stringClientIds = useMemo(() => joinSearchString(arrayOfClientIds, 'client_ids'), [arrayOfClientIds]);
  const stringManagerIds = useMemo(() => joinSearchString(arrayOfManagerIds, 'manager_ids'), [arrayOfManagerIds]);
  const stringEmployeeIds = useMemo(() => joinSearchString(arrayOfEmployeeIds, 'employee_ids'), [arrayOfEmployeeIds]);

  const newClientIds = useMemo(() => arrayOfClientIds.map(id => (+id)), [arrayOfClientIds]);
  const newManagerIds = useMemo(() => arrayOfManagerIds.map(id => (+id)), [arrayOfManagerIds]);
  const newEmployeeIds = useMemo(() => arrayOfEmployeeIds.map(id => (+id)), [arrayOfEmployeeIds]);

  const {data: clients} = useQuery({
    type: searchClientOptions,
    action: searchClientOptions,
    variables: [query, stringClientIds],
    requestKey: query + stringClientIds,
    autoLoad: !!arrayOfClientIds.length,
  });

  const {data: managers} = useQuery({
    type: searchEmployeeOptions,
    action: searchEmployeeOptions,
    variables: [query, stringManagerIds],
    requestKey: query + stringManagerIds,
    autoLoad: !!arrayOfManagerIds.length,
  });

  const {data: employees} = useQuery({
    type: searchEmployeeOptions,
    action: searchEmployeeOptions,
    variables: [query, stringEmployeeIds],
    requestKey: query + stringEmployeeIds,
    autoLoad: !!arrayOfEmployeeIds.length,
  });

  const handleSearchClient = useCallback(input => input.length > 1 && dispatch(searchClientOptions(input)), [dispatch]);
  const handleSearchDeveloper = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [dispatch]);
  const handleSearchManager = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [dispatch]);

  const handleChangeClient = useCallback(client_ids => setSearchParams({'client_ids[]': client_ids}), [setSearchParams]);
  const handleChangeManager = useCallback(manager_ids => setSearchParams({'manager_ids[]': manager_ids}), [setSearchParams]);
  const handleChangeDeveloper = useCallback(employee_ids => setSearchParams({'employee_ids[]': employee_ids}), [setSearchParams]);

  const handleChangeTypes = useCallback(types => setSearchParams({'types[]': types}), [setSearchParams]);
  const handleChangeJobStatuses = useCallback(statuses => setSearchParams({'statuses[]': statuses}), [setSearchParams]);
  const handleChangePlatforms = useCallback(platforms => setSearchParams({'platforms[]': platforms}), [setSearchParams]);
  const handleChangeGroupBy = useCallback(group_by => setSearchParams({group_by}), [setSearchParams]);

  const filteredGroupByOptions = useMemo(() => groupByOptions.filter( option => option.value !== GROUPS.AVATAR), []);

  return (
    <div className='section-filters avatar-meta-filters'>
      <Form
        layout="inline"
        className="components-table-demo-control-bar"
      >
        <Form.Item>
          <SearchSelect
            mode='multiple'
            placeholder='Client'
            onSearch={handleSearchClient}
            onChange={handleChangeClient}
            value={newClientIds}
            defaultOption={clients}
            style={{width: 150}}
          />
        </Form.Item>
        <Form.Item>
          <SearchSelect
            mode='multiple'
            placeholder='Manager'
            onSearch={handleSearchManager}
            onChange={handleChangeManager}
            value={newManagerIds}
            defaultOption={managers}
            style={{width: 150}}
          />
        </Form.Item>
        <Form.Item>
          <SearchSelect
            mode='multiple'
            placeholder='Employee'
            onSearch={handleSearchDeveloper}
            onChange={handleChangeDeveloper}
            value={newEmployeeIds}
            defaultOption={employees}
            style={{width: 150}}
          />
        </Form.Item>
        <Form.Item>
          <Select
            placeholder='Job status'
            value={searchParams.getAll('statuses[]')}
            options={statusOptions}
            onChange={handleChangeJobStatuses}
            mode='multiple'
            maxTagCount='responsive'
            style={{width: 150}}
            allowClear
          />
        </Form.Item>
        <Form.Item>
          <Select
            placeholder='Type'
            value={searchParams.getAll('types[]')}
            options={typeOptions}
            onChange={handleChangeTypes}
            mode='multiple'
            maxTagCount='responsive'
            style={{width: 220}}
            allowClear
          />
        </Form.Item>
        <Form.Item>
          <Select
            placeholder='Platform'
            value={searchParams.getAll('platforms[]')}
            options={platformOptions}
            onChange={handleChangePlatforms}
            mode='multiple'
            maxTagCount='responsive'
            style={{width: 220}}
            allowClear
          />
        </Form.Item>
        <Form.Item>
          <Select
            placeholder='Group by'
            value={searchParams.get('group_by')}
            options={filteredGroupByOptions}
            onChange={handleChangeGroupBy}
            style={{width: 150}}
            allowClear
          />
        </Form.Item>
      </Form>
      <Space>
        {rightSectionFilters}
        <CreateJobLink to={routes.jobs_create.path}>
          <Button type='primary'>Create</Button>
        </CreateJobLink>
      </Space>
    </div>
  )
};

export default React.memo(AvatarMetaTableFilter);