import React from 'react';
import Details from './Details';
import CommentForm from '../CommentForm';
import {Comment, List, Modal} from 'antd';
import {DATE_FORMAT, TIME_FORMAT} from '../../../constants/config';
import {StyledDetailsContainer} from '../styles/styles';
import moment from 'moment';
import {formatDate} from '../../../helpers';
import remarkGfm from 'remark-gfm'
import ReactMarkdown from "react-markdown";

const SummaryModal = ({workLog, jobTitle, comments, isModalVisible, handleShowModal, handleSubmit, handleSuccess}) => {
  const commentsList = (<List
    className='comment-list'
    itemLayout='horizontal'
    dataSource={comments}
    renderItem={comment => (
      <li>
        <Comment
          author={comment.author.employee.full_name}
          content={comment.content}
          datetime={formatDate(moment(comment.date), `${DATE_FORMAT} ${TIME_FORMAT}`)}
        />
      </li>
    )}
  />);

  return (
    <Modal
      title={jobTitle}
      visible={isModalVisible}
      onCancel={handleShowModal}
      footer={false}
    >
      <StyledDetailsContainer>
        <Details workLog={workLog} />
        <ReactMarkdown remarkPlugins={[remarkGfm]} >{workLog.report}</ReactMarkdown>
        {commentsList}
        <CommentForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          rows={2}
        />
      </StyledDetailsContainer>
    </Modal>
  );
};

export default SummaryModal;