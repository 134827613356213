import {PLATFORMS} from './platforms';

export const platformOptions = [
  {
    value: PLATFORMS.PPH,
    label: 'PPH'
  },
  {
    value: PLATFORMS.DIRECT,
    label: 'Direct'
  },
  {
    value: PLATFORMS.UPWORK,
    label: 'Upwork'
  },
  {
    value: PLATFORMS.UPWORK_AGENCY,
    label: 'Upwork agency'
  },
  {
    value: PLATFORMS.NOT_BILLABLE,
    label: 'Not billable'
  },
  {
    value: PLATFORMS.DIRECT_AGENCY,
    label: 'Direct agency'
  },
  {
    value: PLATFORMS.PPH_AGENCY,
    label: 'PPH agency'
  },
];

export const TYPES = {
  DESIGNER: 'designer',
  DEVELOPER: 'developer',
  PM: 'pm',
  QA: 'qa',
};

export const typeOptions = [
  {
    value: 'pm',
    label: 'PM'
  },
  {
    value:  TYPES.DEVELOPER,
    label: 'Dev'
  },
  {
    value:  TYPES.QA,
    label: 'QA'
  },
  {
    value:  TYPES.DESIGNER,
    label: 'Designer'
  }
];

export const GROUPS = {
  PLATFORM: 'platform',
  TYPE: 'type',
  AVATAR: 'avatar',
  CLIENT: 'client',
  MANAGER: 'manager',
  DEVELOPER: 'employee',
  JOB_STATUS: 'job_status',
  OFFICER: 'officer'
};

export const groupByOptions = [
  {
    value: GROUPS.PLATFORM,
    label: 'Platform'
  },
  {
    value: GROUPS.TYPE,
    label: 'Type'
  },
  {
    value: GROUPS.AVATAR,
    label: 'Avatar'
  },
  {
    value: GROUPS.CLIENT,
    label: 'Client'
  },
  {
    value: GROUPS.MANAGER,
    label: 'Manager'
  },
  {
    value: GROUPS.DEVELOPER,
    label: 'Employee'
  },
  {
    value: GROUPS.JOB_STATUS,
    label: 'Job status'
  },
  {
    value: GROUPS.OFFICER,
    label: 'Officer'
  },
];