import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {convertTimeInHours, formatDate} from '../../helpers';
import { Button, Col, DatePicker, Form, Row, TimePicker } from 'antd';
import { DATE_FORMAT, TIME_FORMAT, REPORT_ALLOWED_RANGE_DAYS } from '../../constants/config';
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';


const validationRules = {
  report: [
    { required: true },
    { min: 10 },
    { max: 65535 }
  ],
  date: [
    { required: true }
  ],
  time: [
    { required: true }
  ]
};

const maxDate = moment().endOf('day');
const minDate = maxDate.clone().subtract(REPORT_ALLOWED_RANGE_DAYS, 'days');
const disabledDate = current => current && !current.isBetween(minDate, maxDate);

const ReportForm = ({ onSubmit, onSuccess, initialValues = null }) => {
  const [ form ] = Form.useForm();
  const [ submitting, setSubmitting ] = useState(false);
  const handleSubmit = useCallback( values => {
    setSubmitting(true);
    values.date = formatDate(values.date);
    values.time = convertTimeInHours(values?.time);
    (async () => {
      const {error} = await onSubmit(values);
      setSubmitting(false);
      if (!error) {
        form.resetFields();
        onSuccess();
      } else {
        if (error?.response?.data?.errors) {
          form.setFields(error.response.data.errors.map(error => ({...error, value: form.getFieldValue(error.name)})));
        }
      }
    })();
  }, [form, onSubmit, onSuccess]);

  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);
  const [selectedTab, setSelectedTab] = React.useState("write");
  const [report, setReport] = React.useState(form.getFieldValue('report'));

  return (
    <Form
      form={form}
      initialValues={{
        ...initialValues,
        time: initialValues?.time
          ? moment('00:00', TIME_FORMAT).add(Math.round(initialValues.time * 60), 'minutes')
          : moment('08:00', TIME_FORMAT),
        date: initialValues?.date
          ? moment(initialValues.date)
          : moment()
      }}
      validateTrigger={['onBlur']}
      onFinish={handleSubmit}
      layout='vertical'
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
              label='Comment'
              name='report'
              rules={validationRules.report}
          >
            <ReactMde
              value={report}
              onChange={setReport}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              generateMarkdownPreview={(markdown) => {
                  return Promise.resolve(<ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>)
                }
              }
              childProps={{
                writeButton: {
                  tabIndex: -1
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col style={{ display: 'flex', flexDirection: 'column', width: '250px' }}>
          <div style={{ flex: 1 }}>
            <Form.Item
                label='Day'
                name='date'
                rules={validationRules.date}
            >
              <DatePicker
                disabledDate={disabledDate}
                placeholder='Day'
                style={{ width: '100%' }}
                format={DATE_FORMAT}
              />
            </Form.Item>
            <Form.Item
                label='Worked hours'
                name='time'
                rules={validationRules.time}
            >
              <TimePicker
                placeholder='Worked hours'
                style={{ width: '100%' }}
                format={TIME_FORMAT}
                showNow={false}
                minuteStep={1}
              />
            </Form.Item>
          </div>
          <Form.Item>
            <Button type='primary' htmlType='submit' loading={submitting} block>
              {initialValues?.id ? 'Update & Send' : 'Send'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default ReportForm;
