import React from 'react';
import { Col, Form, Input, Row } from 'antd';


const MonitorFormItems = ({name, fieldKey, ...restField }) => (
  <>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          {...restField}
          label="Model"
          name={[name, 'model']}
          fieldKey={[fieldKey, 'model']}
          rules={[{ required: true, }]}
        >
          <Input placeholder="Model"/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          {...restField}
          label="Resolution"
          name={[name, 'resolution']}
          fieldKey={[fieldKey, 'resolution']}
          rules={[{ required: true, }]}
        >
          <Input placeholder="Resolution"/>
        </Form.Item>
      </Col>
    </Row>
  </>
);

export default MonitorFormItems;