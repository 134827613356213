import { Typography } from 'antd';
import AvatarForm from '../../../components/AvatarForm';
import useFormHandlers from '../../../hooks/useFormHandlers';
import { createAvatar } from '../../../store/avatars/actions';
import routes from '../../../constants/routes';


const AvatarsCreatePage = () => {
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
      action: createAvatar,
      successMessage: 'Avatar added successfully!',
      successPath: routes.avatars.path
  });

  return (
    <>
      <Typography.Title level={3}>Create avatar</Typography.Title>
      <AvatarForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
      />
    </>
  )
}

export default AvatarsCreatePage;