import { createAction } from 'redux-smart-actions';

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchApplicantsList = createAction('APPLICANTS/FETCH_LIST',(searchParams = '') => ({
  request: {
    url: `/applicants?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
  },
}));

/**
 * @param {string} query
 */
export const searchApplicantOptions = createAction('APPLICANT/SEARCH_OPTIONS', (query = '', searchParams= '') => ({
  request: {
    url: `/applicants/search?query=${encodeURIComponent(query)}${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: query.toString() + searchParams.toString(),
    getData: ({ data }) => data.map( applicant => ({...applicant, value: applicant.id, label: `${applicant.first_name} ${applicant.last_name}` }))
  },
}));

/**
 * @param {Object} data
 * @param {string} data.first_name
 * @param {string} data.last_name
 * @param {string} data.email
 * @param {string} data.phone
 * @param {string} data.domain
 * @param {string} data.birthday
 * @param {string} data.cv_text
 * @param {string} data.recommended_by
 * @param {string} data.status
 * @param {string} data.interview_date
 * @param {string} data.started_at
 * @param {string} data.comment
 * @param {string} data.desired
 * @param {string} data.offer
 * @param {string} data.salary
 */
export const createApplicant = createAction('APPLICANTS/CREATE', data => ({
  request: {
    url: '/applicants',
    method: 'POST',
    data
  },
}));

export const fetchApplicant = createAction('APPLICANTS/FETCH', id => ({
  request: {
    url: `/applicants/${id}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

export const updateApplicant = createAction('APPLICANTS/UPDATE', (id, data) => ({
  request: {
    url: `/applicants/${id}`,
    method: 'PUT',
    data
  },
  meta: {
    requestKey: id.toString(),
  },
}));

export const deleteApplicant = createAction('APPLICANTS/DELETE', id => ({
  request: {
    url: `/applicants/${id}`,
    method: 'DELETE',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

export const fetchApplicantCommentsList = createAction('APPLICANTS/COMMENTS/FETCH_LIST', (id, searchParams = '') => ({
  request: {
    url: `/applicants/${id}/comments?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString() + searchParams.toString(),
  },
}));

export const createApplicantComment = createAction('APPLICANTS/COMMENTS/CREATE', (id, data) => ({
  request: {
    url: `/applicants/${id}/comments`,
    method: 'POST',
    data
  },
}));

export const deleteApplicantCVFile = createAction('APPLICANTS/CV_FILE/DELETE', id => ({
  request: {
    url: `/applicants/${id}/cv-file`,
    method: 'DELETE',
  },
  meta: {
    requestKey: id.toString(),
  },
}));