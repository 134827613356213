import React, {useCallback, useEffect} from 'react';
import {Button, Descriptions, Menu, Table, Typography} from 'antd';
import {Link, useParams} from 'react-router-dom';
import {useDispatchRequest, useQuery} from '@redux-requests/react';
import useFormLoader from '../../../hooks/useFormLoader';
import {fetchAvatar} from '../../../store/avatars/actions';
import useSearchParams from '../../../hooks/useSearchParams';
import mapUrlSearchParams from '../../../utils/mapUrlSearchParams';
import {fetchAvatarMeta} from '../../../store/avatars_meta/actions';
import './styles.scss';
import useColumns from "./hooks/useColumns";
import {useSelector} from "react-redux";
import {accountSelector} from "../../../store/auth/selectors";
import {deletePassword, fetchPasswordsList} from "../../../store/passwords/actions";
import usePagination from "../../../hooks/usePagination";
import routes from "../../../constants/routes";
import {ADMIN, PM_L1} from "../../../constants/roles";
import {TagsOutlined} from "@ant-design/icons";
import withRole from "../../../hoc/withRole";


const avatarMetaColumns = [
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    fixed: 'left',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
];

const AvatarsPasswordsPage = () => {
  const {id} = useParams();
  const dispatch = useDispatchRequest();
  const [ searchParams, setSearchParams ] = useSearchParams();
  useEffect(()=>setSearchParams({ 'avatar_ids[]': id }), []);
  const { role } = useSelector(accountSelector);
  const { data: avatar } = useFormLoader(fetchAvatar, id);
  const {data: avatarMeta, loading: avatarMetaLoading} = useFormLoader(fetchAvatarMeta, id);
  const handleChangeTable = useCallback(
      (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
      [setSearchParams]
  );
  /* Main data */
  const { data, loading, load } = useQuery({
    type: fetchPasswordsList,
    action: fetchPasswordsList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
  });
  const pagination = usePagination(data?.meta);
  const handleDelete = useCallback(id => dispatch(deletePassword(id)).then(() => load()), [dispatch, load]);
  const columns = useColumns(handleDelete, role);
  const EditAvatarLink = withRole([ADMIN, PM_L1])(Link);

  return (
    <>
      <Descriptions column={4}
                    title={
                      <Typography.Title level={3} >{avatar?.short_name}</Typography.Title>
                    }
                    extra={
                      <>
                        <EditAvatarLink to={routes.avatars_edit.path.replace(':id', id)}>
                          <Button type='primary'>Edit</Button>
                        </EditAvatarLink>
                        <Link to={routes.avatars_meta_edit.path.replace(':id', id)}>
                          <Button type='link' icon={<TagsOutlined />}/>
                        </Link>
                      </>
                    }
                    bordered
      />
      <Table
        columns={avatarMetaColumns}
        loading={avatarMetaLoading}
        dataSource={avatarMeta}
        pagination={false}
        rowKey='id'
        size='small'
        scroll={{ x: 650 }}
      />
      <Menu selectedKeys={['passwords']} mode="horizontal">
        <Menu.Item key="jobs">
          <Link to={routes.avatars_meta.path.replace(':id', id)}>
            Jobs
          </Link>
        </Menu.Item>
        <Menu.Item key="passwords">
          <Link to={routes.avatars_passwords.path.replace(':id', id)}>
            Passwords
          </Link>
        </Menu.Item>
      </Menu>
      <Table
          columns={columns}
          loading={loading}
          dataSource={data?.data}
          pagination={pagination}
          onChange={handleChangeTable}
          rowKey='id'
          size='small'
          scroll={{ x: 1300 }}
      />
    </>
  );
};

export default AvatarsPasswordsPage;