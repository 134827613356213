import React, { useCallback, useEffect, useMemo } from 'react';
import {Link} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '@redux-requests/react';
import {
  avgWeeklyRate, dayCell,
  formatToFixed, getAvgRateJob, getAvgRateWl, getTotalDay, getTotalWeek,
} from '../tableHelpers';
import {
  formatTimeFromHours,
  getCurrentWeekNumber,
  getLabel, getStartDateByWeekNumber,
  getWeekNumber,
  hasAccessByRole,
} from '../../../helpers';

import {
  CommentOutlined,
  DollarCircleTwoTone,
  EditOutlined,
  InfoCircleOutlined,
  LinkOutlined
} from '@ant-design/icons';
import {IM_BASE_URL} from '../../../constants/config';
import {platformOptions} from '../../../constants/jobs';
import useSearchParams from '../../../hooks/useSearchParams';
import {fetchJobsWeekList} from '../../../store/jobs/actions';
import { accountSelector } from '../../../store/auth/selectors';
import mapUrlSearchParams from '../../../utils/mapUrlSearchParams';
import { fetchHolidaysList } from '../../../store/holidays/actions';
import EditableComponent from '../../../components/EditableComponent';
import UpdateWorkLogsRate from '../../../components/UpdateWorkLogsRate';
import FilterWeek from '../../../components/WorkLogTableFilter/FilterWeek';
import WorkLogTableFilter from '../../../components/WorkLogTableFilter/groupBy';
import {ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2} from '../../../constants/roles';
import {Button, Descriptions, Popover, Space, Table, Tooltip, Typography} from 'antd';
import '../styles/style.scss';
import {domains} from "../../../constants/employees";
import routes from "../../../constants/routes";

export const EditableContext = React.createContext(null);

const WorkLogWeekSimplePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  /* hook add default dates range (current week) */
  useEffect(() => {
    if (!searchParams.has('year') || !searchParams.has('week')) {
      setSearchParams(getCurrentWeekNumber());
    }
  }, [searchParams, setSearchParams]);

  const year = searchParams.get('year');
  const week = searchParams.get('week');


  /* filter onChange */
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const handleChangeWeek = useCallback(
    date => setSearchParams(getWeekNumber(date)),
    [setSearchParams]
  );

  const {data, loading, load} = useQuery({
    type: fetchJobsWeekList,
    action: fetchJobsWeekList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
  });

  const { data: holidays } = useQuery({
    type: fetchHolidaysList,
    action: fetchHolidaysList,
    autoLoad: true,
  });

  const AvatarLink = avatar => {
    const { role } = useSelector(accountSelector);
    return hasAccessByRole([ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2], role) ? (
      <Link to={routes.avatars_meta.path.replace(':id', avatar.id)}>{avatar.short_name}</Link>) : avatar.short_name;
  };

  const EditJobLink = (props) => {
    const { role } = useSelector(accountSelector);
    return hasAccessByRole([ADMIN, PM_L1, PM_L2, PM_L3], role) ? (<Link {...props}>{props.children}</Link>) : null;
  };

  const {role, employee: {id: uid}} = useSelector(accountSelector);
  const hasAccess = hasAccessByRole([ADMIN, PM_L1, PM_L2, PM_L3], role);
  const hasAccessAdmin = hasAccessByRole([ADMIN, PM_L1], role);

  useEffect(() => {
    if (role === PM_L2 || role === PM_L3) {
      setSearchParams({'manager_ids[]': uid})
    }
  }, []);

  useEffect(() => {
    if (role === TECH_L1 || role === TECH_L2) {
      setSearchParams({'officer_ids[]': uid})
    }
  }, []);

  let columns = [
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      sorter: true,
      width: 85,
      render: (platform) => getLabel(platformOptions, platform)
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      width: 75,
      render: (type) => getLabel(domains, type),
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      sorter: true,
      width: 110,
      render: avatar => AvatarLink(avatar),
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      sorter: true,
      render: client => client?.full_name ?? '',
      width: 110,
    },
    {
      title: 'Job name',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      width: 110,
    },
    {
      title: 'Manager',
      dataIndex: 'manager',
      key: 'manager',
      sorter: true,
      render: manager => (
        manager && <Link to={`${routes.employees_view.path.replace(':id', manager.id)}`}>{manager?.full_name}</Link>
      ),
      width: 110,
    },
    {
      title: 'Employee',
      dataIndex: 'employee',
      key: 'employee',
      sorter: true,
      render: employee => (
        employee && <Link to={`${routes.employees_view.path.replace(':id', employee.id)}`}>{employee?.full_name}</Link>
      ),
      width: 110,
    },
    {...dayCell(1, year, week, holidays)},
    {...dayCell(2, year, week, holidays)},
    {...dayCell(3, year, week, holidays)},
    {...dayCell(4, year, week, holidays)},
    {...dayCell(5, year, week, holidays)},
    {...dayCell(6, year, week, holidays)},
    {...dayCell(7, year, week, holidays)},
    {
      title: 'Total',
      dataIndex: 'work_logs',
      key: 'total',
      render: (work_logs) => {
        let total = formatToFixed(work_logs.reduce((total, { time }) => total + time, 0));
        return <Tooltip title={formatTimeFromHours(total)}>
          <span>{total}</span>
        </Tooltip>;
      },
      width: 80,
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: 55,
      align: 'center',
    },
    {
      title: ' Avg',
      dataIndex: 'rate',
      key: 'rate',
      render: (rate, {work_logs}) => {
        return avgWeeklyRate(work_logs);
      },
      width: 50,
      align: 'center',
      className: 'hidden',
      hidden: true
    },
    {
      title: ' Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (rate) => {
        return formatToFixed(rate);
      },
      width: 50,
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'actions',
      render: (id, {
        cipher_link,
        im_channel_id,
        origin_id,
        comment,
        start_date,
        end_date,
        rate
      }) => {
        if (cipher_link) {
          return (
            <Space>
              <EditJobLink to={routes.jobs_edit.path.replace(':id', origin_id ?? id)}>
                <Button type='link' size='small' icon={<EditOutlined />} />
              </EditJobLink>
              <Link to={routes.employee_reports.path.replace(':cipher', cipher_link)}>
                <Button type='link' size='small' icon={<LinkOutlined />} />
              </Link>
              <Button type='link' size='small' icon={<CommentOutlined/>}
                      href={`${IM_BASE_URL}/channels/${im_channel_id}`} />
              <Popover content={<Descriptions style={{ width: 500 }} size="small" layout="vertical" bordered>
                <Descriptions.Item label="Comment" span={3}>{comment}</Descriptions.Item>
                <Descriptions.Item label="Start date">{start_date}</Descriptions.Item>
                <Descriptions.Item label="End date">{end_date}</Descriptions.Item>
              </Descriptions>} title="Job Info" trigger="click"  placement="left">
                <Button type='text' size='small' icon={<InfoCircleOutlined />} />
              </Popover>
              {hasAccess && <Popover
                content={(
                  <UpdateWorkLogsRate
                    jobId={id}
                    defaultRate={rate}
                    jobsLoad={load}
                  />
                )}
                title={(
                  <Typography.Text
                    strong
                    style={{ fontSize: '16px' }}
                  >
                    Mass change rate
                  </Typography.Text>
                )}
                trigger='click'
                placement='left'
                overlayStyle={{
                  width: '40ch',
                }}
                overlayInnerStyle={{
                  borderRadius: '10px'
                }}
              >
                <Button type="text" size="small" icon={<DollarCircleTwoTone/>} />
              </Popover>}
            </Space>
          );
        }
      },
      width: 160,
      align: 'center',
      fixed: 'right'
    }
  ]
  if(!hasAccess) {
    columns = columns.filter(col => col.dataIndex !== 'rate')
  }

  const [components, mergedColumns] = useMemo(() => EditableComponent(columns), [columns]);

  return (
    <>
      <WorkLogTableFilter
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        rightSectionFilters={FilterWeek(getStartDateByWeekNumber(year, week), handleChangeWeek)}
        simple={true}
      />
      <Table
        components={components}
        columns={mergedColumns}
        loading={loading}
        dataSource={data?.data}
        pagination={false}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
        scroll={{x: 1300, y: 1200}}
        bordered
        summary={data => {

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={7}>Total</Table.Summary.Cell>
                <Table.Summary.Cell>
                  {getTotalDay(data, 1, year, week)}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {getTotalDay(data, 2, year, week)}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {getTotalDay(data, 3, year, week)}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {getTotalDay(data, 4, year, week)}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {getTotalDay(data, 5, year, week)}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {getTotalDay(data, 6, year, week)}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {getTotalDay(data, 7, year, week)}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {getTotalWeek(data)}
                </Table.Summary.Cell>
                <Table.Summary.Cell />
                {hasAccess && <Table.Summary.Cell>
                  {hasAccessAdmin && getAvgRateWl(data)}
                </Table.Summary.Cell>}
                {hasAccess && <Table.Summary.Cell>
                  {hasAccessAdmin && getAvgRateJob(data)}
                </Table.Summary.Cell>}
                <Table.Summary.Cell />
              </Table.Summary.Row>
            </>
          );
        }}
        className='worklog_table'
      />
    </>
  )
};

export default WorkLogWeekSimplePage;