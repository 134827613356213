import { createAction } from 'redux-smart-actions';

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchPasswordsList = createAction('PASSWORDS/FETCH_LIST',(searchParams = '') => ({
  request: {
    url: `/passwords?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
  },
}));

/**
 * @param {Object} data
 * @param {number} data.avatar_id
 * @param {string} data.url
 * @param {string} data.login
 * @param {string} data.password
 * @param {string} data.additional_info
 */
export const createPassword = createAction('PASSWORDS/CREATE', data => ({
  request: {
    url: '/passwords',
    method: 'POST',
    data
  },
}));

/**
 * @param {number} id
 */
export const fetchPassword = createAction('PASSWORDS/FETCH', id => ({
  request: {
    url: `/passwords/${id}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));


/**
 * @param {number} id
 * @param {number} data.avatar_id
 * @param {string} data.url
 * @param {string} data.login
 * @param {string} data.password
 * @param {string} data.additional_info
 */
export const updatePassword = createAction('PASSWORDS/UPDATE', (id, data) => ({
  request: {
    url: `/passwords/${id}`,
    method: 'PUT',
    data,
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 */
export const deletePassword = createAction('PASSWORDS/DELETE', id => ({
  request: {
    url: `/passwords/${id}`,
    method: 'DELETE',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

export const searchUrlOptions = createAction('PASSWORDS/SEARCH_URL_OPTIONS', query => ({
  request: {
    url: `/passwords/url/search?query=${encodeURIComponent(query)}`,
    method: 'GET',
  },
  meta: {
    requestKey: query.toString(),
    getData: ({ data }) => data.map( (url, key) => {
      const value = url.toLowerCase()
      return ({ value: value, label: value, key: value + key })
    })
  },
}));

export const decryptPassword = createAction('PASSWORDS/DECRYPT', id => ({
  request: {
    url: `/passwords/${id}/decrypt`,
    method: 'POST',
  },
  meta: {
    requestKey: id.toString(),
  },
}));