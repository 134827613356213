import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import MyAccountForm from '../../components/MyAccountForm';
import { updateAccount } from '../../store/auth/actions';


const MyAccountPage = () => {
  const dispatch = useDispatch();
  const account = useSelector(state => state.auth.account);
  const handleSubmit = useCallback(data => dispatch(updateAccount(data)), [dispatch]);

  return (
    <>
      <Typography.Title level={3}>My account</Typography.Title>
      <MyAccountForm
        onSubmit={handleSubmit}
        account={account}
      />
    </>
  )
}

export default MyAccountPage;