import React, {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import SearchSelect from '../SearchSelect';
import {DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import {DATE_FORMAT, DATE_TIME_FORMAT} from '../../constants/config';
import {searchChiefsOptions, searchEmployeeOptions} from '../../store/employees/actions';
import {deleteApplicantCVFile} from '../../store/applicants/actions';
import { domains, statuses, platforms } from '../../constants/applicants';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Upload,
  message,
  notification,
  Popconfirm
} from 'antd';
import {formatDate, hasAccessByRole} from "../../helpers";
import {ADMIN, HR_L1, HR_L2} from "../../constants/roles";


//TODO: fill validationRules
const validationRules = {
  first_name:  [
    { required: true },
    { min: 3 },
    { max: 100 }
  ],
  last_name:  [
    { required: true },
    { min: 3 },
    { max: 100 }
  ],
  domain: [
    { required: true }
  ],
  platform: [
    { required: true }
  ],
  responsible_person: [
    { required: true }
  ],
  phone: [
    { max: 45 }
  ],
  email: [
    { type: 'email' },
    { max: 255 }
  ],
  interview_date: [
  ],
  status: [
    { required: true },
  ],
  cv_text: [
    { min: 10 }
  ],
};

const useFormSubmit = (form, onSubmit, onSuccess, onError) => {
  const [ submitting, setSubmitting ] = useState(false);
  const handleSubmit = useCallback( values => {
    setSubmitting(true);
    for (const [key, value] of Object.entries(values)) {
      if(key === 'interview_date' && moment.isMoment(value)) {
        values[key] = formatDate(value, DATE_TIME_FORMAT);
      }
      if(key === 'started_at' && moment.isMoment(value)) {
        values[key] = formatDate(value, DATE_FORMAT);
      }
      if(key === 'birthday' && moment.isMoment(value)) {
        values[key] = formatDate(value, DATE_FORMAT);
      }
    }
    (async () => {
      const {error} = await onSubmit(values);
      setSubmitting(false);
      if (!error) {
        onSuccess();
      } else {
        if (onError) {
          onError(error);
        }
        if (error?.response?.data?.errors) {
          form.setFields(error.response.data.errors.map(error => ({...error, value: form.getFieldValue(error.name)})));
        }
      }
    })();
  }, [form, onSubmit, onSuccess]);

  return {
    submitting,
    handleSubmit
  }
}

const ApplicantForm = ({ onSubmit, onSuccess, onCancel, initialValues = null, role, uid }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [ form ] = Form.useForm();

  const mimeTypesFile = [
    'text/csv',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/png',
    'application/pdf',
    'application/rtf',
    'image/gif',
    'image/jpeg',
  ];
  const defaultFile = {
    uid: '-1',
    name: initialValues?.cv_file?.match(/(?!\/)\w+\.\w+/)[0],
    status: 'done',
    url: initialValues?.cv_file,
  };
  const initialFile = initialValues?.cv_file ? [defaultFile] : [];

  const [file, setFile] = useState();
  const [fileList, setFileList] = useState(initialFile);
  const { submitting, handleSubmit } = useFormSubmit(form, onSubmit, onSuccess);
  const handleSearchResponsiblePerson = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [dispatch]);
  const handleSearchInterviewer = useCallback(input => input.length > 1 && dispatch(searchChiefsOptions(input)), [dispatch]);
  const handleDeleteCVFile = useCallback(id => dispatch(deleteApplicantCVFile(id)), [dispatch]);

  const handleBeforeUpload = info => {
    if (!mimeTypesFile.find( value => value === info.type)) {
      message.error(`The file ${info.name} are not csv, txt, pdf, doc, docx, rtf, xls, xlsx, xml, odt, png, gif, jpeg, jpg files!`)
      return Upload.LIST_IGNORE;
    }

    const isLt5M = (info.size / 1024 / 1024) < 5;
    if (!isLt5M) {
      message.error('Image must smaller than 5MB!');
      return Upload.LIST_IGNORE;
    }

    function getBase64(infoFile, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(infoFile);
    }

    getBase64(info, fileUrl => setFile(fileUrl));
    setFileList([info]);

    return false;
  }

  const handleRemove = async () => {
    if(id) {
      const response = await handleDeleteCVFile(id);

      if (!response.error) {
        notification.success({message: 'File successfully deleted!'});
        setFileList([]);
      }
    }
    else {
      notification.success({message: 'File successfully deleted!'});
      setFileList([]);
    }
  }

  const propsUpload = {
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <Popconfirm title='Are you sure?' onConfirm={handleRemove}>
        <DeleteOutlined />
      </Popconfirm>,
    },
  };

  const handleOnSubmit = values => {
    values.cv_file = file;
    handleSubmit(values);
  }

//  initialValues.interviwers = initialValues?.interviewers.map( interviwer => ({key: interviwer.id, value: interviwer.id, label: `${interviwer.first_name} ${interviwer.last_name}` }));
  const interviewersOptions = initialValues?.interviewers.map(({ id, full_name }) => ({value: id, label: full_name }));

  return (
    <>
      <Form
        form={form}
        validateTrigger={['onBlur']}
        initialValues={{
          ...initialValues,
          interview_date: initialValues?.interview_date && moment(initialValues.interview_date),
          birthday: initialValues?.birthday && moment(initialValues.birthday),
          started_at: initialValues?.started_at && moment(initialValues.started_at),
          responsible_person_id: initialValues?.responsible_person.id || uid,
          interviewers: interviewersOptions?.map(({ value }) => value)
        }}
        onFinish={handleOnSubmit}
        layout='vertical'
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
                label='First name'
                name='first_name'
                rules={validationRules.first_name}
            >
              <Input placeholder='First name'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Last name'
                name='last_name'
                rules={validationRules.last_name}
            >
              <Input placeholder='Last name'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Domain'
                name='domain'
                rules={validationRules.domain}
            >
              <Select placeholder='Domain' options={domains} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
                label='Phone'
                name='phone'
                rules={validationRules.phone}
            >
              <Input placeholder='Phone'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Email'
                name='email'
                rules={validationRules.email}
            >
              <Input placeholder='Email'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Interview date'
                name='interview_date'
                rules={validationRules.interview_date}
            >
              <DatePicker style={{ width: '100%' }} placeholder='Interview date' format={DATE_TIME_FORMAT} showTime />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
            label='CV text'
            name='cv_text'
            rules={validationRules.cv_text}
        >
          <Input.TextArea placeholder='CV text' rows={3}/>
        </Form.Item>

        <Form.Item
            label='Comment'
            name='comment'
        >
          <Input.TextArea placeholder='Comment' rows={3}/>
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
                label='Birthday'
                name='birthday'
                rules={validationRules.birthday}
            >
              <DatePicker style={{ width: '100%' }} placeholder='Birthday' format={DATE_FORMAT} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Status'
                name='status'
                rules={validationRules.status}
            >
              <Select placeholder='Status' options={statuses} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Platform'
                name='platform'
                rules={validationRules.platform}
            >
              <Select placeholder='Platform' options={platforms} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          {hasAccessByRole([ADMIN, HR_L1, HR_L2], role) && <Col span={8}>
            <Form.Item
                label='Desired'
                name='desired'
                rules={validationRules.desired}
            >
              <Input placeholder='Desired' prefix='$' />
            </Form.Item>
          </Col>}
          {hasAccessByRole([ADMIN, HR_L1], role) && <Col span={8}>
            <Form.Item
                label='Offer'
                name='offer'
                rules={validationRules.offer}
            >
              <Input placeholder='Offer' prefix='$' />
            </Form.Item>
          </Col>}
          {hasAccessByRole([ADMIN, HR_L1], role) && <Col span={8}>
            <Form.Item
                label='Salary'
                name='salary'
                rules={validationRules.salary}
            >
              <Input placeholder='Salary' prefix='$' />
            </Form.Item>
          </Col>}
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
                label='Start date'
                name='started_at'
                rules={validationRules.started_at}
            >
              <DatePicker style={{ width: '100%' }} placeholder='Start date' format={DATE_FORMAT} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Recommended by'
                name='recommended_by'
                rules={validationRules.recommended_by}
            >
              <Input placeholder='Recommended by' />
            </Form.Item>
          </Col>
          <Col span={8}>
          {(role === HR_L2)?
            <Form.Item name='responsible_person_id' noStyle>
              <Input type="hidden"/>
            </Form.Item>
            :
            <Form.Item
                  label='Responsible person'
                  name='responsible_person_id'
                  rules={validationRules.responsible_person}
              >

              <SearchSelect
                  placeholder='Responsible person'
                  onSearch={handleSearchResponsiblePerson}
                  defaultOption={[{value: initialValues?.responsible_person.id, label: initialValues?.responsible_person.full_name}]}
              />
            </Form.Item>
          }
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
              <Form.Item
                label='Interviewers'
                name='interviewers'
              >
                <SearchSelect
                  defaultOption={interviewersOptions}
                  mode='multiple'
                  placeholder='Please select interviewers'
                  onSearch={handleSearchInterviewer}
                />
              </Form.Item>
          </Col>
        </Row>
        {hasAccessByRole([ADMIN, HR_L1, HR_L2], role) &&
        <Row span={16}>
          <Col span={4}>
            <Form.Item
                label='CV file'
                name='cv_file'
            >
              <Upload
                name='cv_file'
                accept='.csv,.txt,.pdf,.doc,.docx,.rtf,.png,.jpeg,.jpg'
                fileList={fileList}
                beforeUpload={handleBeforeUpload}
                {...propsUpload}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>}
        {hasAccessByRole([ADMIN, HR_L1, HR_L2], role) &&
        <Form.Item>
          <Space>
            <Button htmlType='button' loading={submitting} onClick={onCancel}>Cancel</Button>
            <Button type='primary' htmlType='submit' loading={submitting}>Submit</Button>
          </Space>
        </Form.Item>}
      </Form>
    </>
  )
};

export default ApplicantForm;