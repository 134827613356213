export const EMPLOYEE_DOMAIN = {
  DESIGNER: 'designer',
  DEVELOPER: 'developer',
  HR: 'hr',
  OM: 'om',
  PM: 'pm',
  QA: 'qa',
};



export const domains = [
  {
    value:  EMPLOYEE_DOMAIN.PM,
    label: 'Project management'
  },
  {
    value:  EMPLOYEE_DOMAIN.DEVELOPER,
    label: 'Development'
  },
  {
    value:  EMPLOYEE_DOMAIN.QA,
    label: 'QA'
  },
  {
    value:  EMPLOYEE_DOMAIN.DESIGNER,
    label: 'Design'
  },
  {
    value: EMPLOYEE_DOMAIN.HR,
    label: 'HR'
  },
  {
    value:  EMPLOYEE_DOMAIN.OM,
    label: 'Office management'
  }
];

export const statusOptions = [
  {
    value: '0',
    label: 'Active'
  },
  {
    value: '1',
    label: 'Archive'
  }
];

export const EMPLOYEE_ROLE = {
  BM: 'board_member',
  CEO: 'ceo',
  CTO: 'cto',
  COO: 'coo',
  CBDO: 'cbdo',
  HOQA: 'head_of_qa',
  HOHR: 'head_of_hr',
  TL: 'team_lead',
  RO: 'recruitment_officer',
  HRO: 'human_resource_officer',
  DEV: 'developer',
  PM: 'project_manager',
  OM: 'office_manager',
  QA: 'qa_engineer',
  DESIGNER: 'designer',
  MM: 'maintenance_manager',
  SMM: 'smm',
};

export const roles = [
  {
    value:  EMPLOYEE_ROLE.BM,
    label: 'Board member'
  },
  {
    value:  EMPLOYEE_ROLE.CEO,
    label: 'Chief Executive Officer'
  },
  {
    value:  EMPLOYEE_ROLE.CTO,
    label: 'Chief Technology Officer'
  },
  {
    value:  EMPLOYEE_ROLE.COO,
    label: 'Chief Operating Officer'
  },
  {
    value:  EMPLOYEE_ROLE.CBDO,
    label: 'Chief business development officer'
  },
  {
    value:  EMPLOYEE_ROLE.HOQA,
    label: 'Head of QA'
  },
  {
    value:  EMPLOYEE_ROLE.HOHR,
    label: 'Head of HR'
  },
  {
    value:  EMPLOYEE_ROLE.TL,
    label: 'Team lead'
  },
  {
    value: EMPLOYEE_ROLE.RO,
    label: 'Recruitment Officer'
  },
  {
    value: EMPLOYEE_ROLE.HRO,
    label: 'Human Resource Officer'
  },
  {
    value:  EMPLOYEE_ROLE.DEV,
    label: 'Developer'
  },
  {
    value:  EMPLOYEE_ROLE.PM,
    label: 'Project Manager'
  },
  {
    value:  EMPLOYEE_ROLE.OM,
    label: 'Office Manager'
  },
  {
    value:  EMPLOYEE_ROLE.QA,
    label: 'QA Engineer'
  },
  {
    value:  EMPLOYEE_ROLE.DESIGNER,
    label: 'Designer'
  },
  {
    value:  EMPLOYEE_ROLE.MM,
    label: 'Maintenance Manager'
  },
  {
    value:  EMPLOYEE_ROLE.SMM,
    label: 'SMM'
  },
];

export const EMPLOYEE_OFFICE = {
  ZP01: 'zp01',
  ZP02: 'zp02',
  ZP03: 'zp03',
  DP04: 'dp04',
  REMOTE: 'remote',
};

export const offices = [
  {
    value:  EMPLOYEE_OFFICE.ZP01,
    label: 'ZP01 (rozental)'
  },
  {
    value:  EMPLOYEE_OFFICE.ZP02,
    label: 'ZP02 (praviy)'
  },
  {
    value:  EMPLOYEE_OFFICE.ZP03,
    label: 'ZP03 (orbyta)'
  },
  {
    value:  EMPLOYEE_OFFICE.DP04,
    label: 'DP04 (dnepr)'
  },
  {
    value:  EMPLOYEE_OFFICE.REMOTE,
    label: 'Remote'
  },
];

export const EMPLOYEE_ENLEVEL = {
  A1: 'a1',
  A2: 'a2',
  B1: 'b1',
  B2: 'b2',
  C1: 'c1',
  C2: 'c2',
};

export const enlevels = [
  {
    value:  EMPLOYEE_ENLEVEL.A1,
    label: 'Elementary'
  },
  {
    value:  EMPLOYEE_ENLEVEL.A2,
    label: 'Pre-Intermediate'
  },
  {
    value:  EMPLOYEE_ENLEVEL.B1,
    label: 'Intermediate'
  },
  {
    value:  EMPLOYEE_ENLEVEL.B2,
    label: 'Upper Intermediate'
  },
  {
    value:  EMPLOYEE_ENLEVEL.C1,
    label: 'Advanced'
  },
  {
    value:  EMPLOYEE_ENLEVEL.C2,
    label: 'Proficiency'
  },
];