import { ADMIN, HR_L1, HR_L2, OM, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2 } from './roles';

export const roleOptions = [
  {
    label: 'Admin',
    value: ADMIN,
  },
  {
    label: 'Tech L1',
    value: TECH_L1,
  },
  {
    label: 'Tech L2',
    value: TECH_L2,
  },
  {
    label: 'PM L1',
    value: PM_L1,
  },
  {
    label: 'PM L2',
    value: PM_L2,
  },
  {
    label: 'PM L3',
    value: PM_L3,
  },
  {
    label: 'HR L1',
    value: HR_L1,
  },
  {
    label: 'HR L2',
    value: HR_L2,
  },
  {
    label: 'OM',
    value: OM,
  },
];