import {useCallback, useMemo} from 'react';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import useColumns from './hooks/useColumns';
import routes from '../../constants/routes';
import { types } from '../../constants/avatars';
import { useQuery } from '@redux-requests/react';
import {Button, Form, Select, Space, Table} from 'antd';
import usePagination from '../../hooks/usePagination';
import useSearchParams from '../../hooks/useSearchParams';
import mapUrlSearchParams from '../../utils/mapUrlSearchParams';
import {deleteAvatar, fetchAvatarsList, searchAvatarOptions} from '../../store/avatars/actions';
import {accountSelector} from "../../store/auth/selectors";
import {hasAccessByRole} from "../../helpers";
import {ADMIN, PM_L1} from "../../constants/roles";
import SearchSelect from "../../components/SearchSelect";
import {joinSearchString} from "../work_log/tableHelpers";


const AvatarsPage = () => {
  const query = '';
  const dispatch = useDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { role } = useSelector(accountSelector);

  const arrayOfAvatarIds = searchParams.getAll('ids[]');
  const stringOfAvatarIds = useMemo(() => joinSearchString(arrayOfAvatarIds, 'ids'), [arrayOfAvatarIds]);
  const newOfAvatarIds = useMemo(() => arrayOfAvatarIds.map( id => (+id)), [arrayOfAvatarIds]);

  const handleChangeDomains = useCallback(domains => setSearchParams({ 'domains[]': domains }), [setSearchParams]);
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const handleSearchAvatar = useCallback(input => input.length > 1 && dispatch(searchAvatarOptions(input)), [ dispatch ]);
  const handleChangeAvatar = useCallback(ids => setSearchParams({ 'ids[]': ids }), [setSearchParams]);

  const { data: avatars } = useQuery({
    type: searchAvatarOptions,
    action: searchAvatarOptions,
    variables: [query, stringOfAvatarIds],
    requestKey: query + stringOfAvatarIds,
    autoLoad: !!stringOfAvatarIds.length,
  });

  const { data, loading, load } = useQuery({
    type: fetchAvatarsList,
    action: fetchAvatarsList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
    autoReset: true,
  });

  const pagination = usePagination(data?.meta);
  const handleDelete = useCallback(id => dispatch(deleteAvatar(id)).then(() => load()), [dispatch, load]);
  const columns = useColumns(handleDelete, role);

  return (
    <>
      <div className='section-filters'>
        <Form
          layout="inline"
          className="components-table-demo-control-bar"
        >
          <Space>
            <Form.Item>
              <SearchSelect
                mode='multiple'
                placeholder='Avatars'
                onSearch={handleSearchAvatar}
                onChange={handleChangeAvatar}
                value={newOfAvatarIds}
                defaultOption={avatars}
                style={{ width: 220 }}
              />
            </Form.Item>
            <Form.Item>
            <Select
              placeholder='Type'
              mode='multiple'
              value={searchParams.getAll('domains[]')}
              options={types}
              onChange={handleChangeDomains}
              maxTagCount='responsive'
              style={{ width: 220 }}
              allowClear
            />
            </Form.Item>
          </Space>
        </Form>
        {hasAccessByRole([ADMIN, PM_L1], role)&&<Link to={routes.avatars_create.path} style={{ marginLeft: 'auto' }}>
          <Button type='primary'>Create</Button>
        </Link>}
      </div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.data}
        pagination={pagination}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
        scroll={{ x: 1300 }}
      />
    </>
  );
};

export default AvatarsPage;