import { useCallback, useMemo, useState } from 'react';
import SearchSelect from '../SearchSelect';
import useFormSubmit from '../../hooks/useFormSubmit';
import { useDispatchRequest } from '@redux-requests/react';
import { searchUrlOptions } from '../../store/passwords/actions';
import { searchAvatarOptions } from '../../store/avatars/actions';
import { Button, Col, Form, Input, Row, Space, AutoComplete } from 'antd';

const { Option } = AutoComplete;

const validationRules = {
  avatar_id: [
    { required: true }
  ],
  url: [
    { required: true }
  ],
  login: [
    { required: true }
  ],
  password: [
    { required: true }
  ],
  password_confirmation: [
    { required: true },
    ({ getFieldValue }) => ({
      validator: (_, value) => (!value || getFieldValue('password') === value)
        ? Promise.resolve()
        : Promise.reject('The two passwords that you entered do not match.')
    })
  ]
};

const PasswordForm = ({
  onSubmit,
  onSuccess,
  onCancel,
  initialValues = null
}) => {
  const dispatch = useDispatchRequest();
  const [form] = Form.useForm();
  const [options, setOptions] = useState([{
    value: initialValues?.url.toLowerCase(),
    key: initialValues?.url
  }]);

  const {
    submitting,
    handleSubmit
  } = useFormSubmit(form, onSubmit, onSuccess);
  const handleSearchAvatar = useCallback(input => input.length > 1 && dispatch(searchAvatarOptions(input)), [dispatch]);
  const handleSearchPasswordUrl = useCallback(async (input) => {
    if (input.length > 1) {
      const response = await dispatch(searchUrlOptions(input));
      setOptions(response?.data);
    }
  }, [dispatch]);

  const mapOptions = useMemo(() => (
    options.map( ({
      key,
      value
    }) => (
      <Option key={key} value={value}>
        {value}
      </Option>
    ))
  ), [options]);

  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      initialValues={{
        ...initialValues,
        avatar_id: initialValues?.avatar.id,
        url: initialValues?.url.toLowerCase(),
        password_confirmation: initialValues?.password
      }}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Avatar"
            name="avatar_id"
            rules={validationRules.avatar_id}
          >
            <SearchSelect
              placeholder="Avatar"
              onSearch={handleSearchAvatar}
              defaultOption={[{
                value: initialValues?.avatar.id,
                label: initialValues?.avatar.short_name
              }]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Url"
            name="url"
            rules={validationRules.url}
          >
            <AutoComplete
              placeholder="Url"
              onSearch={handleSearchPasswordUrl}
              filterOption={false}
            >
              {mapOptions}
            </AutoComplete>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Login"
            name="login"
            rules={validationRules.login}
          >
            <Input placeholder="Login"/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Password"
            name="password"
            rules={validationRules.password}
          >
            <Input.Password placeholder="Password" autoComplete="new-password"/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Confirm password"
            name="password_confirmation"
            rules={validationRules.password_confirmation}
          >
            <Input.Password placeholder="Confirm password"/>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Additional info"
        name="additional_info"
      >
        <Input.TextArea placeholder="Additional info" rows={5}/>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button htmlType="button" loading={submitting} onClick={onCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={submitting}>Submit</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default PasswordForm;