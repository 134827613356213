import { Col, Form, Input, Row } from 'antd';
import React from 'react';

const ChairFormItems = ({name, fieldKey, ...restField }) => (
  <>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          {...restField}
          label="Acquired"
          name={[name, 'acquired']}
          fieldKey={[fieldKey, 'acquired']}
          rules={[{ required: true, }]}
        >
          <Input placeholder="Acquired"/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          {...restField}
          label="Issued"
          name={[name, 'issued']}
          fieldKey={[fieldKey, 'issued']}
          rules={[{ required: true, }]}
        >
          <Input placeholder="Issued"/>
        </Form.Item>
      </Col>
    </Row>
  </>
);

export default ChairFormItems;