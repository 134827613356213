import { Typography } from 'antd';
import routes from '../../../constants/routes';
import useFormHandlers from '../../../hooks/useFormHandlers';
import { createEmployee } from '../../../store/employees/actions';
import EmployeeCreateForm from "../../../components/EmployeeCreateForm";


const EmployeesCreatePage = () => {
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
      action: createEmployee,
      successMessage: 'Employee added successfully!',
      successPath: routes.employees_archive.path
  });

  return (
    <>
      <Typography.Title level={3}>Create employee</Typography.Title>
      <EmployeeCreateForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
      />
    </>
  )
}

export default EmployeesCreatePage;