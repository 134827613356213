import React from 'react';
import {Tooltip} from 'antd';
import TooltipTitle from './TooltipTitle';
import {SwapOutlined} from '@ant-design/icons';
import {StyledIcon, StyledTooltip} from '../styles/styles';


const SwappedTooltip = ({handleShowModal, workLog, swappedManager, swappedEmployee}) => (
  <StyledTooltip left={3}>
    <Tooltip title={TooltipTitle(workLog, swappedManager, swappedEmployee)} placement='right'>
      <StyledIcon onClick={handleShowModal}><SwapOutlined/></StyledIcon>
    </Tooltip>
  </StyledTooltip>
);

export default SwappedTooltip;