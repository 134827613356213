import styled from 'styled-components';


export const StyledVacationLogsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledSidesContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
`;

export const StyledLeftSideContainer = styled(StyledSidesContainer)`
`;

export const StyledRightSideContainer = styled(StyledSidesContainer)`
`;
