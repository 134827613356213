import routes from './routes';
import { ADMIN, HR_L1, HR_L2, OM, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2 } from './roles';


const navMenuItems = {
  [ADMIN]: [
    routes.dashboard,
    {
      title: 'WorkLog',
      submenu: [
        {
          ...routes.work_log_week_simple,
          title: 'Week'
        },
        {
          ...routes.work_log_week_consolidated,
          title: 'Week consolidated'
        },
        {
          ...routes.work_log_total,
          title: 'Total'
        },
      ]
    },
    {
      title: 'Employees',
      submenu: [
        {
          ...routes.employees,
          title: 'Team'
        },
        {
          ...routes.employees_archive,
          title: 'Archive'
        },
        {
          ...routes.employees_balances,
          title: 'Balances'
        },
        {
          ...routes.employees_overtimes,
          title: 'Overtimes'
        },
      ]
    },
    routes.avatars,
    {
      title: 'Vacations',
      submenu: [
        {
          ...routes.vacations,
          title: 'Scheduled'
        },
        {
          ...routes.vacations_log,
          title: 'Log'
        },
      ]
    },
    routes.clients,
    routes.applicants,
    routes.passwords,
    {
      title: 'Users',
      submenu: [
        {
          ...routes.users,
          title: 'Active'
        },
        {
          ...routes.users_archive,
          title: 'Archive'
        },
      ]
    },
    routes.holidays,
    routes.faqs,
    routes.warehouses
  ],
  [TECH_L1]: [
    routes.dashboard,
    routes.work_log_week_simple,
    routes.employees,
    {
      title: 'Vacations',
      submenu: [
        {
          ...routes.vacations,
          title: 'Scheduled'
        },
        {
          ...routes.vacations_log,
          title: 'Log'
        },
      ]
    },
    routes.applicants,
    routes.passwords,
    routes.avatars,
  ],
  [TECH_L2]: [
    routes.dashboard,
    routes.work_log_week_simple,
    routes.employees,
    routes.passwords,
    routes.avatars,
  ],
  [PM_L1]: [
    routes.dashboard,
    {
      title: 'WorkLog',
      submenu: [
        {
          ...routes.work_log_week_simple,
          title: 'Week'
        },
        {
          ...routes.work_log_week_consolidated,
          title: 'Week consolidated'
        },
        {
          ...routes.work_log_total,
          title: 'Total'
        },
      ]
    },
    {
      title: 'Employees',
      submenu: [
        {
          ...routes.employees,
          title: 'Team'
        },
        {
          ...routes.employees_balances,
          title: 'Balances'
        },
        {
          ...routes.employees_overtimes,
          title: 'Overtimes'
        },
      ]
    },
    routes.avatars,
    {
      title: 'Vacations',
      submenu: [
        {
          ...routes.vacations,
          title: 'Scheduled'
        },
        {
          ...routes.vacations_log,
          title: 'Log'
        },
      ]
    },
    routes.clients,
    routes.applicants,
    routes.passwords,
    routes.users,
  ],
  [PM_L2]: [
    routes.dashboard,
    routes.work_log_week_simple,
    {
      title: 'Employees',
      submenu: [
        {
          ...routes.employees,
          title: 'Team'
        },
        {
          ...routes.employees_overtimes,
          title: 'Overtimes'
        },
      ]
    },
    {
      title: 'Vacations',
      submenu: [
        {
          ...routes.vacations,
          title: 'Scheduled'
        },
        {
          ...routes.vacations_log,
          title: 'Log'
        },
      ]
    },
    routes.clients,
    routes.passwords,
    routes.avatars,
  ],
  [PM_L3]: [
    routes.dashboard,
    routes.work_log_week_simple,
    {
      title: 'Employees',
      submenu: [
        {
          ...routes.employees,
          title: 'Team'
        },
        {
          ...routes.employees_overtimes,
          title: 'Overtimes'
        },
      ]
    },
    {
      title: 'Vacations',
      submenu: [
        {
          ...routes.vacations,
          title: 'Scheduled'
        },
        {
          ...routes.vacations_log,
          title: 'Log'
        },
      ]
    },
    routes.clients,
    routes.passwords,
    routes.avatars,
  ],
  [HR_L1]: [
    {
      title: 'Dashboard',
      submenu: [
        {
          ...routes.dashboard,
          title: 'Vacations'
        },
        {
          ...routes.dashboard_hr,
          title: 'Interviews'
        },
      ]
    },
    {
      title: 'Employees',
      submenu: [
        {
          ...routes.employees,
          title: 'Team'
        },
        {
          ...routes.employees_archive,
          title: 'Archive'
        },
      ]
    },
    {
      title: 'Vacations',
      submenu: [
        {
          ...routes.vacations,
          title: 'Scheduled'
        },
        {
          ...routes.vacations_log,
          title: 'Log'
        },
      ]
    },
    routes.applicants,
    routes.warehouses
  ],
  [HR_L2]: [
    routes.dashboard,
    routes.applicants,
  ],
  [OM]: [
    routes.employees,
    routes.warehouses
  ]
};


export default navMenuItems;
