import React from 'react';
import {Space} from 'antd';
import moment from 'moment';
import {CalendarTwoTone, ClockCircleTwoTone, MoneyCollectTwoTone, ProjectTwoTone, ToolTwoTone} from '@ant-design/icons';
import {StyledDetails, StyledTwoToneIcon} from '../../styles/styles';
import {formatDate} from '../../../../helpers';


const Details = ({workLog})  => (
  <StyledDetails>
    <Space direction='vertical'>
      <Space><CalendarTwoTone/>{formatDate(moment(workLog.date))}</Space>
      <Space><ClockCircleTwoTone/>{workLog.time}</Space>
      <Space><MoneyCollectTwoTone/>{workLog.rate}</Space>
    </Space>
    <Space direction='vertical'>
      <Space>
        <StyledTwoToneIcon><ProjectTwoTone/></StyledTwoToneIcon>
        {workLog.manager.full_name}
      </Space>
      <Space>
        <StyledTwoToneIcon><ToolTwoTone/></StyledTwoToneIcon>
        {workLog.employee.full_name}
      </Space>
    </Space>
  </StyledDetails>
);

export default Details;