import React, {useCallback, useMemo} from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@redux-requests/react';
import {Button, Form, Select, Table} from 'antd';
import useColumns from './hooks/useColumns';
import routes from '../../../constants/routes';
import {domains, enlevels, offices, roles, statusOptions} from '../../../constants/employees';
import usePagination from '../../../hooks/usePagination'
import useSearchParams from '../../../hooks/useSearchParams';
import mapUrlSearchParams from '../../../utils/mapUrlSearchParams'
import {fetchDeletedEmployeesList, searchEmployeeOptions} from '../../../store/employees/actions';
import SearchSelect from "../../../components/SearchSelect";
import {useDispatch} from "react-redux";
import {joinSearchString} from "../../work_log/tableHelpers";


const EmployeesArchivePage = () => {
  const query = '';
  const dispatch = useDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const arrayOfEmployeeIds = searchParams.getAll('ids[]');
  const stringEmployeeIds = useMemo(() => joinSearchString(arrayOfEmployeeIds, 'ids'), [arrayOfEmployeeIds]);
  const newEmployeeIds = useMemo(() => arrayOfEmployeeIds.map( id => (+id)), [arrayOfEmployeeIds]);

  const { data: employees } = useQuery({
    type: searchEmployeeOptions,
    action: searchEmployeeOptions,
    variables: [query, stringEmployeeIds],
    requestKey: query + stringEmployeeIds,
    autoLoad: !!arrayOfEmployeeIds.length,
  });

  const handleSearchEmployee = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [dispatch]);
  const handleChangeEmployee = useCallback(employee_ids => setSearchParams({ 'ids[]': employee_ids }), [setSearchParams]);
  const handleChangeDomains = useCallback(domains => setSearchParams({ 'domains[]': domains }), [setSearchParams]);
  const handleChangeRoles = useCallback(roles => setSearchParams({ 'roles[]': roles }), [setSearchParams]);
  const handleChangeOffices = useCallback(offices => setSearchParams({ 'offices[]': offices }), [setSearchParams]);
  const handleChangeEnLevels = useCallback(en_levels => setSearchParams({ 'en_levels[]': en_levels }), [setSearchParams]);
  const handleChangeFired = useCallback(fired => setSearchParams({ fired }), [setSearchParams]);
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const { data, loading } = useQuery({
    type: fetchDeletedEmployeesList,
    action: fetchDeletedEmployeesList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
  });
  const pagination = usePagination(data?.meta);
  const columns = useColumns();

  return (
    <>
      <div className='section-filters'>
        <Form
          layout="inline"
          className="components-table-demo-control-bar"
        >
          <Form.Item>
            <SearchSelect
              mode='multiple'
              placeholder='Employee'
              onSearch={handleSearchEmployee}
              onChange={handleChangeEmployee}
              value={newEmployeeIds}
              defaultOption={employees}
              style={{ width: 220 }}
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Domain'
              mode='multiple'
              maxTagCount='responsive'
              value={searchParams.getAll('domains[]')}
              options={domains}
              onChange={handleChangeDomains}
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Role'
              mode='multiple'
              maxTagCount='responsive'
              value={searchParams.getAll('roles[]')}
              options={roles}
              onChange={handleChangeRoles}
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Office'
              value={searchParams.getAll('offices[]')}
              options={offices}
              onChange={handleChangeOffices}
              mode='multiple'
              maxTagCount='responsive'
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='English level'
              value={searchParams.getAll('en_levels[]')}
              options={enlevels}
              onChange={handleChangeEnLevels}
              mode='multiple'
              maxTagCount='responsive'
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Status'
              value={searchParams.get('fired')}
              options={statusOptions}
              onChange={handleChangeFired}
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
        </Form>
        <Link to={routes.employees_create.path} style={{ float: 'right' }} >
          <Button type='primary'>Create</Button>
        </Link>
      </div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.data}
        pagination={pagination}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
        scroll={{ x: 1300 }}
      />
    </>
  )
};

export default EmployeesArchivePage;