export const domains = [
  {
    value: 'pm',
    label: 'Project manager'
  },
  {
    value: 'developer',
    label: 'Developer'
  },
  {
    value: 'qa',
    label: 'QA engineer'
  },
  {
    value: 'designer',
    label: 'Designer'
  },
  {
    value: 'hr',
    label: 'HR manager'
  },
  {
    value: 'om',
    label: 'Office manager'
  }
];

export const statuses = [
  {
    value: 'new',
    label: 'New',
    color: ''
  },
  {
    value: 'pre_interview',
    label: 'Pre interview',
    color: ''
  },
  {
    value: 'not_interviewed',
    label: 'Not interviewed',
    color: ''
  },
  {
    value: 'interviewed',
    label: 'Interviewed',
    color: ''
  },
  {
    value: 'declined',
    label: 'Declined',
    color: 'volcano'
  },
  {
    value: 'declined_forever',
    label: 'Declined forever',
    color: 'red'
  },
  {
    value: 'refused',
    label: 'Refused',
    color: 'geekblue'

  },
  {
    value: 'hired',
    label: 'Hired',
    color: 'green'
  },
];

export const platforms = [
  {
    value: 'djinni',
    label: 'Djinni'
  },
  {
    value: 'dou',
    label: 'DOU'
  },
  {
    value: 'linked_in',
    label: 'LinkedIn'
  },
  {
    value: 'work_ua',
    label: 'Work.ua'
  },
  {
    value: 'robota_ua',
    label: 'Robota.ua'
  },
  {
    value: 'referral',
    label: 'Referral'
  },
];
