export const VACATION_TYPES = {
  DAY_OFF: 'day_off',
  VACATION: 'vacation',
  SICK: 'sick'
};

export const typeOptions = [
  {
    value: VACATION_TYPES.DAY_OFF,
    label: 'Day off'
  },
  {
    value: VACATION_TYPES.VACATION,
    label: 'Vacation'
  },
  {
    value: VACATION_TYPES.SICK,
    label: 'Sick'
  }
];