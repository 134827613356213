import { Link } from 'react-router-dom';
import routes from '../../constants/routes';
import { Divider, List } from 'antd';
import React from 'react';
import styled from 'styled-components';


const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmployeeVacationLogInfo = ({employee, type, vacationDays, btnActions}) => (
  <List.Item actions={btnActions}>
    <StyledContainer>
      <div style={{ width: '14ch'}}>
        <Link
          to={routes.employees_view.path.replace(':id', employee.id)}
        >
          {employee.first_name} {employee.last_name}
        </Link>
      </div>
      <Divider type='vertical'/>
      <div>
        {type}
      </div>
      <Divider type='vertical'/>
      <div>
        {vacationDays}
      </div>
    </StyledContainer>
  </List.Item>
);

export default EmployeeVacationLogInfo;