import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  WAREHOUSE_TYPES,
  warehouseTypeOptions
} from '../../constants/warehouses';
import PCFormItems from './PCForm';
import ChairFormItems from './ChairForm';
import AppleFormItems from './ApplyForm';
import SearchSelect from '../SearchSelect';
import MonitorFormItems from './MonitorForm';
import { DATE_FORMAT } from '../../constants/config';
import { useDispatchRequest } from '@redux-requests/react';
import useFormSubmit from '../../hooks/useFormSubmit';
import { searchEmployeeOptions } from '../../store/employees/actions';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space } from 'antd';
import useSearchParams from '../../hooks/useSearchParams';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../store/auth/selectors';

const validationRules = {
  title: [
    { required: true },
    { min: 2 },
    { max: 255 }
  ],
  serial: [
    { required: true },
    { min: 2 },
    { max: 255 }
  ],
  employee_id: [
    { required: true }
  ],
  type: [
    { required: true }
  ],
  checked_at: [
    { required: true }
  ],
};

const WarehouseForm = ({ onSubmit, onSuccess, onCancel, initialValues = null }) => {
  const dispatch = useDispatchRequest();
  const [ form ] = Form.useForm();
  const [searchParams] = useSearchParams();
  const { submitting, handleSubmit } = useFormSubmit(form, onSubmit, onSuccess);

  const [selectedWarehouseType, setSelectedWarehouseType] = useState('');

  const { employee } = useSelector(accountSelector);

  const handleSearchEmployee = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [ dispatch ]);

  const definitionWarehouseType = useCallback((field) => {
    switch (selectedWarehouseType) {
      case WAREHOUSE_TYPES.APPLE:
        return <AppleFormItems {...field} />;
      case WAREHOUSE_TYPES.PC:
        return <PCFormItems {...field} />;
      case WAREHOUSE_TYPES.MONITOR:
        return <MonitorFormItems {...field} />;
      case WAREHOUSE_TYPES.CHAIR:
        return <ChairFormItems {...field} />;
      default:
        return '';
    }
  }, [selectedWarehouseType]);

  useEffect(() => {
    setSelectedWarehouseType(initialValues?.type);
  }, [initialValues]);

  const handleSelect = value => setSelectedWarehouseType(value);

  const handleOnSubmit = (values) => {
    const newSpecifications = values?.specifications[0];
    newSpecifications.title = values?.title;
    handleSubmit({...values, specifications: newSpecifications});
  };
  const onChecked = () => {
    const fields = form.getFieldsValue();
    fields.checked_at = moment();
    form.setFieldsValue(fields);
  }
  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      initialValues={{
        ...initialValues,
        serial: initialValues?.serial || searchParams.get('serial'),
        title: initialValues?.specifications?.title,
        specifications: [initialValues?.specifications],
        employee_id: initialValues?.employee.id || employee.id,
        checked_at: initialValues?.checked_at && moment(initialValues.checked_at),
      }}
      onFinish={handleOnSubmit}
      layout='vertical'
    >
      <Form.Item
        label='Title'
        name='title'
        rules={validationRules.title}
      >
        <Input placeholder='Product title'/>
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label='Serial'
            name='serial'
            rules={validationRules.serial}
          >
            <Input placeholder='Serial'/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='Employee'
            name='employee_id'
            rules={validationRules.employee_id}
          >
            <SearchSelect
              placeholder='Employee'
              onSearch={handleSearchEmployee}
              defaultOption={[{
                value: initialValues?.employee.id || employee.id,
                label: initialValues?.employee.full_name || employee.full_name}]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='Type'
            name='type'
            rules={validationRules.type}
          >
            <Select
              placeholder='Type'
              options={warehouseTypeOptions}
              onSelect={handleSelect}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='Checked at'
            name='checked_at'
            rules={validationRules.checked_at}
          >
            <DatePicker style={{ width: '100%' }} placeholder='Checked at' format={DATE_FORMAT}/>
          </Form.Item>
        </Col>
      </Row>


      <Form.List name="specifications">
        {(fields) => (
          <>
            {fields.map(field => (
              <div key={field?.key}>
                {definitionWarehouseType(field)}
              </div>
            ))}
          </>
        )}
      </Form.List>

      <Form.Item
        label='Notes'
        name='notes'
      >
        <Input.TextArea placeholder='Note' rows={3}/>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button htmlType='button' loading={submitting} onClick={onCancel}>Cancel</Button>
          <Button type='primary' htmlType='submit' loading={submitting}>Submit</Button>
          <Button type='primary' htmlType='submit' loading={submitting} onClick={onChecked}>Checked</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default WarehouseForm;