import { Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import routes from '../../../constants/routes';
import useFormHandlers from '../../../hooks/useFormHandlers';
import useFormLoader from '../../../hooks/useFormLoader';
import UserForm from '../../../components/UserForm';
import { fetchUser, updateUser } from '../../../store/users/actions';


const UsersEditPage = () => {
  const { id } = useParams();
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
    action: updateUser,
    successMessage: 'User updated successfully!',
    successPath: routes.users.path,
    actionParams: [id]
  });
  const { data, loading } = useFormLoader(fetchUser, id);

  return (
    <>
      <Typography.Title level={3}>Edit user</Typography.Title>
      { loading ? (
        <Spin/>
      ) : (
        <UserForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          initialValues={data}
        />
      ) }
    </>
  )
}

export default UsersEditPage;