import { Typography } from 'antd';
import useFormHandlers from '../../../hooks/useFormHandlers';
import routes from '../../../constants/routes';
import { createUser } from '../../../store/users/actions';
import UserForm from '../../../components/UserForm';


const UsersCreatePage = () => {
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
      action: createUser,
      successMessage: 'User added successfully!',
      successPath: routes.users.path
  });

  return (
    <>
      <Typography.Title level={3}>Create user</Typography.Title>
      <UserForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
      />
    </>
  )
}

export default UsersCreatePage;