import React from 'react';
import {PLATFORMS} from '../../constants/platforms';
import {platformOptions} from '../../constants/jobs';
import useFormSubmit from '../../hooks/useFormSubmit';
import {Button, Col, Form, Input, Row, Select, Space} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

const validationRules = {
  platform: [
    {required: true}
  ],
  name: [
    {max: 255}
  ],
  value: [
    {max: 255}
  ]
};

const AvatarMetaForm = ({onSubmit, onSuccess, onCancel, initialValues = null}) => {
  const [form] = Form.useForm();
  const filteredPlatformOptions = platformOptions.filter(({value}) => (
      value !== PLATFORMS.UPWORK_AGENCY
      && value !== PLATFORMS.NOT_BILLABLE
      && value !== PLATFORMS.DIRECT_AGENCY
      && value !== PLATFORMS.PPH_AGENCY));
  const {submitting, handleSubmit} = useFormSubmit(form, onSubmit, onSuccess);

  const formList = (fields, {add, remove}) => (
    <>
      {fields.map(({key, name, fieldKey, ...restField}) => (
        <Row gutter={16} key={key}>
          <Col span={8}>
            <Form.Item
              {...restField}
              name={[name, 'platform']}
              fieldKey={[fieldKey, 'platform']}
              rules={validationRules.platform}
              style={{width: '100%'}}
              label='Platform'
            >
              <Select
                placeholder='Platform'
                options={filteredPlatformOptions}
                style={{width: '100%'}}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              {...restField}
              name={[name, 'name']}
              fieldKey={[fieldKey, 'name']}
              rules={validationRules.name}
              label='Name'
            >
              <Input placeholder='Name'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              {...restField}
              name={[name, 'value']}
              fieldKey={[fieldKey, 'value']}
              rules={validationRules.value}
              label='Value'
            >
              <Input placeholder='Value'/>
            </Form.Item>
          </Col>
          <Col span={1}>
            <MinusCircleOutlined onClick={() => remove(name)}/>
          </Col>
        </Row>
      ))}
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item>
            <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined/>}>
              Add field
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  return (
    <Form
      form={form}
      initialValues={{
        meta: initialValues
      }}
      onFinish={handleSubmit}
      layout='vertical'
    >
      <Form.List name='meta'>
        {formList}
      </Form.List>
      <Row>
        <Col span={12}>
          <Form.Item>
            <Space>
              <Button htmlType='button' loading={submitting} onClick={onCancel}>Cancel</Button>
              <Button type='primary' htmlType='submit' loading={submitting}>Submit</Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default AvatarMetaForm;