import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import LinkSpecial from '../../../components/LinkSpecial';
import routes from '../../../constants/routes';
import {hasAccessByRole} from "../../../helpers";
import {ADMIN} from "../../../constants/roles";


const useColumns = (onConfirmDelete, role) => useMemo(() => ([
  {
    title: 'Employee',
    dataIndex: 'employee',
    key: 'employee',
    sorter: true,
    render: employee => <Link to={routes.employees_view.path.replace(':id', employee?.id)}>{employee?.full_name}</Link>
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    render: email => <LinkSpecial mode='email'>{email}</LinkSpecial>
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    sorter: true,
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: id => (
      <Space size='small'>
        <Link to={routes.users_edit.path.replace(':id', id)}>
          <Button type='link' size='small' icon={<EditOutlined />}/>
        </Link>
        {hasAccessByRole([ADMIN], role) && <Popconfirm title='Are you sure?' onConfirm={() => onConfirmDelete(id)}>
          <Button type='link' size='small' icon={<DeleteOutlined />}/>
        </Popconfirm>}
      </Space>
    ),
    width: 90,
    align: 'center',
    fixed: 'right'
  }
]), [onConfirmDelete, role]);


export default useColumns;