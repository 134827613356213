import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {Button, Modal, Popconfirm, Space} from 'antd';
import {DeleteOutlined, EditOutlined, EyeOutlined} from '@ant-design/icons';
import routes from '../../../constants/routes';
import Iframe from 'react-iframe'

const useColumns = onConfirmDelete => useMemo(() => ([
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Office',
    dataIndex: 'office',
    key: 'office',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: (id,{title, content}) => (
      <Space>
        <Button
          icon={<EyeOutlined/>}
          onClick={() => {
            Modal.info({
              title: title,
              width: 1000,
              content: (
                <>
                  <Iframe
                    url={content}
                    width="900px"
                    height="900px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                  />
                </>
              ),
            });
          }}
        />
        <Link to={routes.faqs_edit.path.replace(':id', id)}>
          <Button type="link" size="small" icon={<EditOutlined/>}/>
        </Link>
        <Popconfirm title="Are you sure?" onConfirm={() => onConfirmDelete(id)}>
          <Button type="link" size="small" icon={<DeleteOutlined/>}/>
        </Popconfirm>
      </Space>
    ),
    width: 90,
    align: 'center',
  }
]), [onConfirmDelete]);

export default useColumns;
