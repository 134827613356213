import { success } from '@redux-requests/core';
import {fetchAccount, login, logout, setLoggedIn} from './actions';


const initialState = {
  isLoggedIn: false,
  account: {}
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case setLoggedIn.toString():
      return {
        ...state,
        isLoggedIn: true
      };
    case success(login):
      return {
        ...state,
        isLoggedIn: true,
      };
    case success(logout):
      return initialState;
    case success(fetchAccount):
      return {
        ...state,
        isLoggedIn: true,
        account: {
          ...action.response.data.data
        }
      };
    default:
      return state;
  }
}

export default authReducer;