import React, {useState} from 'react';
import {Button, Form, Input} from 'antd';


const CommentForm = ({onSubmit, onSuccess, rows = 4}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [disable, setDisable] = useState(true);

  const handleOnChange = e => {
    const value = e.target.value;
    const isValidate = (value !== undefined && (value.length >= 10 && value.length <= 65535));
    setDisable(!isValidate);
  }

  const handleOnSubmit = async (value) => {
    await setSubmitting(!submitting);
    const error = await onSubmit(value);
    setSubmitting(submitting);

    if (!error) {
      form.resetFields();
      onSuccess();
    }

    if (error?.response?.data?.errors) {
      form.setFields(error.response.data.errors.map(error => ({...error, value: form.getFieldValue(error.name)})));
    }
  }

  return (
    <Form
      form={form}
      layout='vertical'
      validateTrigger={['onBlur']}
      onFinish={handleOnSubmit}
    >
      <Form.Item
        name='content'
        rules={[{min: 10, max: 65535, message: 'Please enter your comment for report!'}]}
        onChange={handleOnChange}
      >
        <Input.TextArea
          placeholder='Add comment for report'
          rows={rows}
        />
      </Form.Item>
      <Button
        type='primary'
        htmlType='submit'
        block
        disabled={disable}
        loading={submitting}
      >
        Submit
      </Button>
    </Form>
  )
}

export default CommentForm;