import React, {useState, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import SearchSelect from '../SearchSelect';
import {hasAccessByRole} from '../../helpers';
import {roleOptions} from '../../constants/users';
import {ADMIN, PM_L3} from '../../constants/roles';
import useFormSubmit from '../../hooks/useFormSubmit';
import {useDispatchRequest} from '@redux-requests/react';
import {accountSelector} from '../../store/auth/selectors';
import {Button, Col, Form, Row, Select, Space, Input} from 'antd';
import {searchAvatarOptions} from '../../store/avatars/actions';
import {searchEmployeeOptions} from '../../store/employees/actions';

//TODO: fill validationRules
const validationRules = {
  employee_id: [
    { required: true }
  ],
  role: [
    { required: true }
  ],
  email: [
    { required: true },
    { type: 'email' },
    { max: 255 }
  ],
};

const UserForm = ({onSubmit, onSuccess, onCancel, initialValues = null}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatchRequest();
  const { role } = useSelector(accountSelector);
  const isAccessRole = useMemo(() => hasAccessByRole([ADMIN], role), [role]);
  const {submitting, handleSubmit} = useFormSubmit(form, onSubmit, onSuccess);
  const [showAvatars, setShowAvatars] = useState(isPML3(initialValues?.role));

  const handleSearchAvatar = useCallback(input => input.length > 1 && dispatch(searchAvatarOptions(input)), [dispatch]);
  const handleSearchEmployee = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [ dispatch ]);

  const handleOnSelect = role => {
    if (isPML3(role) !== showAvatars) {
      setShowAvatars(!showAvatars)
    }
  }

  function isPML3(role) {
    return role === PM_L3;
  }

  const avatarOptions = initialValues?.avatars.map(({ id, short_name }) => ({value: id, label: short_name }));

  return (
    <>
      <Form
        form={form}
        validateTrigger={['onBlur']}
        initialValues={{
          ...initialValues,
          employee_id: initialValues?.employee.id,
          avatars: avatarOptions?.map(({ value }) => value)
        }}
        onFinish={handleSubmit}
        layout='vertical'
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
                label='Employee'
                name='employee_id'
                rules={validationRules.employee_id}
            >
              <SearchSelect
                placeholder='Employee'
                onSearch={handleSearchEmployee}
                defaultOption={[{value: initialValues?.employee.id, label: initialValues?.employee.full_name}]}
                disabled={!isAccessRole}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Role'
                name='role'
                rules={validationRules.role}
            >
              <Select
                placeholder='Role'
                options={roleOptions}
                onSelect={handleOnSelect}
                disabled={!isAccessRole}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Email'
                name='email'
                rules={validationRules.email}
            >
              <Input placeholder='Email' disabled={!isAccessRole}/>
            </Form.Item>
          </Col>
          <Col span={8}>
            {showAvatars && <Form.Item
                label='Avatars'
                name='avatars'
            >
              <SearchSelect
                defaultOption={avatarOptions}
                mode='multiple'
                placeholder='Please select avatars'
                onSearch={handleSearchAvatar}
              />
            </Form.Item>}
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <Button htmlType='button' loading={submitting} onClick={onCancel}>Cancel</Button>
            <Button type='primary' htmlType='submit' loading={submitting}>Submit</Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

export default UserForm;