import styled from 'styled-components';


export const StyledDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledIcon = styled.div`
  color: #000000;
  cursor: pointer;
`;
export const StyledTwoToneIcon = styled.div`
  font-size: 16px;
`;
export const StyledDropdownContextMenu = styled('div').attrs({
  className: 'site-dropdown-context-menu'
})`
  line-height: 65px;
`;

export const StyledTooltip = styled.div`
  position: absolute;
  top: ${props => (props.left ? 0 : 'unset')};
  bottom: ${props => (props.right ? 0 : 'unset')};
  left: ${props => (props.left + 'px' ?? 'unset')};
  right: ${props => (props.right + 'px' ?? 'unset')};
`;