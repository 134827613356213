import { createAction } from 'redux-smart-actions';

/**
 * @param {number} id
 */
export const createWorkLog = createAction('WORK_LOGS/CREATE', (id, data) => ({
  request: {
    url: `/jobs/${id}/work-log`,
    method: 'POST',
    data
  },
}));

/**
 * @param {number} id
 * @param {Object} data
 */
export const updateWorkLog = createAction('WORK_LOGS/UPDATE', (id, data) => ({
  request: {
    url: `/work-logs/${id}`,
    method: 'PUT',
    data
  },
}));

// /**
//  * @param {number} id
//  * @param {Object} data
//  */
// export const fetchWorkLogCommentsList = createAction('WORK_LOGS/COMMENTS/FETCH_LIST', id => ({
//   request: {
//     url: `/work-logs/${id}/comments`,
//     method: 'GET',
//   },
//   meta: {
//     requestKey: id.toString(),
//   },
// }));
//
// export const createComment = createAction('WORK_LOGS/COMMENTS/CREATE', (id, data) => ({
//   request: {
//     url: `/work-logs/${id}/comments`,
//     method: 'POST',
//     data
//   },
// }));