import React, { useCallback, useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import SearchSelect from '../SearchSelect';
import { ADMIN } from '../../constants/roles';
import useFormSubmit from '../../hooks/useFormSubmit';
import { DATE_FORMAT } from '../../constants/config';
import useSearchParams from '../../hooks/useSearchParams';
import { useDispatchRequest } from '@redux-requests/react';
import { accountSelector } from '../../store/auth/selectors';
import {
  fetchEmployeeSickLeaveBalance,
  searchEmployeeOptions
} from '../../store/employees/actions';
import { typeOptions, VACATION_LOG_TYPES } from '../../constants/vacationLogs';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Spin } from 'antd';
import { fetchHolidaysList } from '../../store/holidays/actions';
import { hasAccessByRole } from '../../helpers';

//TODO: fill validationRules
const validationRules = {
  employee_id: [
    { required: true }
  ],
  type: [
    { required: true }
  ],
  date: [
    { required: true }
  ],
};

// TODO: add modifier field.
const VacationLogForm = ({
  onSubmit,
  onSuccess,
  onCancel,
  initialValues = null,
  employeeSelectDisabled = false
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatchRequest();
  const [searchParams] = useSearchParams();
  const [employee, setEmployee] = useState(null);
  const [holidayDates, setHolidayDates] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState(null);
  const [vacationLogTypeOptions, setVacationLogTypeOptions] = useState(null);
  const [typeRefill, setTypeRefill] = useState(false);
  const {
    submitting,
    handleSubmit
  } = useFormSubmit(form, onSubmit, onSuccess);

  const { role } = useSelector(accountSelector);

  useEffect(() => {
    (async () => {
      const response = await dispatch(fetchHolidaysList());
      if (response?.data?.data) {
        const dates = response?.data?.data.map( ({ date }) => date)
        setHolidayDates(dates);
      }
    })();
  }, [dispatch]);

  const disabledDates = current => (
    current && holidayDates.find( date => moment(date).isSame(current.format(DATE_FORMAT)))
  );

  const handleSearchEmployee = useCallback(async (input) => {
    if (input.length > 1) {
      const response = await dispatch(searchEmployeeOptions(input));
      setEmployeeOptions(response.data);
      return response;
    }
  }, [dispatch]);

  const filterTypeOptions = useCallback(exceptTypes => (
    typeOptions.filter(typeOption => !_.includes([...exceptTypes], typeOption.value))
  ), []);

  const getEmployeeSickLeave = useCallback(value => (
    value && dispatch(fetchEmployeeSickLeaveBalance(value))
  ), [dispatch]);

  const handleSelectEmployee = async (value) => {
    let foundEmployee;
    if (value) {
      foundEmployee = employeeOptions.find(employeeOption => employeeOption.id === value);
    }

    if (foundEmployee && !employeeSelectDisabled) {
      const { data: sickLeaveBalance } = await getEmployeeSickLeave(value);
      foundEmployee = {
        ...foundEmployee,
        sick_leave_balance: sickLeaveBalance
      };
    }

    setEmployee(foundEmployee);
  };

  const checkType = value => (
    [
      VACATION_LOG_TYPES.VACATION_REFILL,
      VACATION_LOG_TYPES.SICK_REFILL
    ].includes(value)
  );

  useEffect(() => setTypeRefill(checkType(initialValues?.type)), [initialValues]);

  const handleSelectType = value => {
    setTypeRefill(checkType(value));
  };

  useEffect(() => {
    if (initialValues?.employee) {
      (async () => {
        const { data: sickLeaveBalance } = await getEmployeeSickLeave(initialValues?.employee.id);
        if (sickLeaveBalance !== undefined) {
          setEmployee({
            ...initialValues?.employee,
            sick_leave_balance: sickLeaveBalance
          });
        }
      })();
    }
  }, [dispatch, getEmployeeSickLeave, initialValues?.employee]);

  const definitionTypeBySickLeave = useCallback((types = []) => {

    if (role !== ADMIN) {
      types.push(VACATION_LOG_TYPES.VACATION_REFILL, VACATION_LOG_TYPES.SICK_REFILL);
    }

    if (employee?.sick_leave_balance && employee?.sick_leave_balance > 0) {
      return filterTypeOptions([
        VACATION_LOG_TYPES.SICK_PROVED,
        ...types
      ]);
    }

    if (employee?.sick_leave_balance < 1) {
      if (initialValues?.type !== VACATION_LOG_TYPES.SICK) {
        types.push(VACATION_LOG_TYPES.SICK);
      }

      return filterTypeOptions([
        ...types
      ]);
    }
  }, [employee?.sick_leave_balance, filterTypeOptions, initialValues?.type, role]);

  useEffect( () => {
    let typeBySickLeave;
    if (initialValues?.type === VACATION_LOG_TYPES.ABSENT) {
      typeBySickLeave = definitionTypeBySickLeave([VACATION_LOG_TYPES.VACATION_REFILL]);
    } else {
      typeBySickLeave = definitionTypeBySickLeave();
    }
    setVacationLogTypeOptions(typeBySickLeave);
  }, [definitionTypeBySickLeave, employee, initialValues?.type]);

  if (initialValues) {
    if (!vacationLogTypeOptions || !initialValues) {
      return <Spin />;
    }
  }

  const handleOnSubmit = async (values) => {
    const newValues = {};
    for (let key in values) {
      if (values[key]) {
        newValues[key] = values[key];
      }
    }

    return handleSubmit(newValues);
  }

  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      initialValues={{
        type: !(employee?.sick_leave_balance < 1 && searchParams.get('type') === VACATION_LOG_TYPES.SICK)
          ? searchParams.get('type')
          : '',
        ...initialValues,
        employee_id: initialValues?.employee.id,
        date: (initialValues?.date && moment(initialValues.date)) || (searchParams.has('date') && moment(searchParams.get('date'))),
      }}
      onFinish={handleOnSubmit}
      layout="vertical"
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Employee"
            name="employee_id"
            rules={validationRules.employee_id}
          >
            <SearchSelect
              placeholder="Employee"
              onSearch={handleSearchEmployee}
              onSelect={handleSelectEmployee}
              disabled={employeeSelectDisabled}
              defaultOption={[{
                value: initialValues?.employee.id,
                label: initialValues?.employee.full_name
              }]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Type"
            name="type"
            rules={validationRules.type}
          >
            <Select
              placeholder="Type"
              options={vacationLogTypeOptions}
              disabled={!employee}
              onSelect={handleSelectType}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Comment"
        name="comment"
      >
        <Input.TextArea placeholder="Comment" rows={3}/>
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Date"
            name="date"
            rules={validationRules.date}
          >
            <DatePicker
              disabledDate={disabledDates}
              style={{ width: '100%' }}
              placeholder="Date"
              format={DATE_FORMAT}
            />
          </Form.Item>
        </Col>

        {(typeRefill && hasAccessByRole([ADMIN], role)) && <Col span={12}>
          <Form.Item
            label="Usage"
            name="usage"
          >
            <Input placeholder="Usage"/>
          </Form.Item>
        </Col>}
      </Row>

      <Form.Item>
        <Space>
          <Button htmlType="button" loading={submitting} onClick={onCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={submitting}>Submit</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default VacationLogForm;
