import moment from 'moment';
import classNames from 'classnames';
import {VACATION_TYPES} from './constants/vacations';
import {VACATION_LOG_TYPES} from './constants/vacationLogs';
import {DATE_FORMAT, TIME_FORMAT} from './constants/config';
import {formatToFixed} from './pages/work_log/tableHelpers';

export function getClassNameByType(type) {
  switch (type) {
    case VACATION_TYPES.VACATION:
    case VACATION_LOG_TYPES.VACATION:
      return 'vacation';
    case VACATION_TYPES.SICK:
    case VACATION_LOG_TYPES.SICK:
      return 'sick';
    case VACATION_TYPES.DAY_OFF:
    case VACATION_LOG_TYPES.DAY_OFF:
      return 'day-off';
    case VACATION_LOG_TYPES.ABSENT:
      return 'absent';
  }
}

export function calendarEventStyle(event) {
  let className;

  switch (event.entity) {
    case 'vacation':
      className = getClassNameByType(event.type);
      break;
    case 'vacation_log':
      className = `vacation-log ${getClassNameByType(event.type)}`;
      break;
    case 'create_vacation_log':
      className = `vacation-log missed`;
      break;
  }

  return {className: classNames('table-cell', className)};
}

export function calendarHrEventStyle(event) {
  return {className: classNames('table-cell', event.type)};
}

export const formatSelectInitialOption = (entity) => (entity ? [{value: entity.id, label: entity?.full_name ?? entity?.short_name}] : []);

export function formatDate(date, format = DATE_FORMAT) {
  return date.format(format);
}

export function getStartWeek(startDate) {
  return formatDate(moment(startDate).clone().startOf('week'));
}

export function getEndWeek(endDate) {
  return formatDate(moment(endDate).clone().endOf('week'));
}

export function getCurrentWeek() {
  const todayDate = moment();
  const start_date = getStartWeek(todayDate);
  const end_date = getEndWeek(todayDate);
  return {start_date, end_date}
}
export function getCurrentWeekNumber() {
  const todayDate = moment();
  const year = todayDate.year();
  const week = todayDate.week();
  return {year, week}
}

export function getWeekNumber(date) {
  const year = date.year();
  const week = date.week();
  return {year, week}
}
export function getWeek(date) {
  const start_date = getStartWeek(date);
  const end_date = getEndWeek(date);
  return {start_date, end_date}
}

export function getStartOfMonth() {
  return moment().startOf('month');
}

export function getDaysBetweenDates(startDate, endDate) {
  let now = moment(startDate);
  const dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(moment(formatDate(now)));
    now.add(1, 'days');
  }

  return dates;
}

export function getStartDateByWeekNumber(year, week) {
  return formatDate(moment().day("Monday").isoWeekYear(parseInt(year)).isoWeek(parseInt(week)));
}

export function addWeek(date, week = 1) {
  return moment(date).clone().add(week, 'week');
}

export function subtractWeek(date, week = 1) {
  return moment(date).clone().subtract(week, 'week');
}

export function getDate(date) {
  return moment(date);
}

export function getStartAndEndDateInCalendar() {
  const startDate = moment().startOf('month').startOf('week');
  const lastDate = moment().endOf('month').endOf('week');
  return {startDate, lastDate}
}

export function formatTimeFromHours(time) {
  const dur = moment.duration(time, 'hours');
  const hours = Math.floor(dur.asHours());
  const [, minutes] = formatDate(moment('00:00', TIME_FORMAT).add(Math.round(time * 60), 'minutes'), TIME_FORMAT).split(':');
  return hours + ':' + minutes;
}

export function convertTimeInHours(time) {
  const [hours, minutes] = formatDate(time, TIME_FORMAT).split(':');
  return formatToFixed((+hours + (+minutes / 60)));
}

export function convertTimeInHoursMoment(time) {
  const [hours, minutes] = time.split(':');
  return moment('00:00', TIME_FORMAT).add((+hours + (+minutes / 60)), 'hours');
}

export function isSameDate(date, compareDate, granularity = 'day') {
  return date.isSame(compareDate, granularity);
}

export function getLabel(entity, searchValue) {
  return (entity.find( ({value}) => value === searchValue))?.label;
}
export function getColor(entity, searchValue) {
  return (entity.find( ({value}) => value === searchValue))?.color;
}
export function hasAccessByRole(accessRoles, role) {
  return accessRoles.includes(role);
}