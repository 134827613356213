export const ADMIN = 'admin';
export const TECH_L1 = 'tech-l1';
export const TECH_L2 = 'tech-l2';
export const PM_L1 = 'pm-l1';
export const PM_L2 = 'pm-l2';
export const PM_L3 = 'pm-l3';
export const HR_L1 = 'hr-l1';
export const HR_L2 = 'hr-l2';
export const OM = 'office-manager';

const roles = [ADMIN, HR_L1, HR_L2, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2, OM];

export default roles;