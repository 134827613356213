import React from 'react';
import TimePickerCell from './TimePickerCell';
import WorkLogDropDown from '../../../WorkLogDropDown';
import DailyDropdown from '../../../DailyDropdown';

const CellView = ({
  date,
  setWorkLog,
  workLog,
  job,
  editing,
  childNode,
  dataIndex,
  onAction,
  onToggleEdit,
  jobComments,
  setJobComments
}) => {
  if (editing) {
    return (<TimePickerCell
        dataIndex={dataIndex}
        onAction={onAction}
        workLog={workLog}
    />)
  }

  if (workLog) {
    return (<WorkLogDropDown
      jobComments={jobComments}
      setJobComments={setJobComments}
      setWorkLog={setWorkLog}
      workLog={workLog}
      job={job}
      onToggleEdit={onToggleEdit}
    />)
  }

  return (
    <DailyDropdown
      jobComments={jobComments}
      setJobComments={setJobComments}
      job={job}
      date={date}
      childNode={childNode}
      onToggleEdit={onToggleEdit}
    />
  )
}

export default CellView;