import {useSelector} from 'react-redux';
import {hasAccessByRole} from '../helpers';
import {accountSelector} from '../store/auth/selectors';

const withRole = accessRoles => (Component) => (props) => {
  const { role } = useSelector(accountSelector);

  if (hasAccessByRole(accessRoles, role)) {
    return <Component {...props} />
  }

  return null;
};

export default withRole;