import { Link } from 'react-router-dom';
import CardMeta from './styled/CardMeta';
import {getLabel} from '../../helpers';
import LinkSpecial from '../LinkSpecial';
import withRole from '../../hoc/withRole';
import routes from '../../constants/routes';
import {EditOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {ADMIN, HR_L1, PM_L1, TECH_L1} from '../../constants/roles';
import {domains, enlevels, offices, roles} from '../../constants/employees';
import {Avatar, Button, Card, Descriptions, Popover} from 'antd';
import React from "react";


const EditEmployeeLink = withRole([ADMIN, HR_L1, TECH_L1, PM_L1])(Link);
const EmployeeCard = ({ employee }) => (
  <>
    <Card
      title={
        <Link to={routes.employees_view.path.replace(':id', employee.id)}>
          <Button type='link'>{employee.first_name + ' ' +employee.last_name}</Button>
        </Link>
      }

      extra={
        <>
          <EditEmployeeLink to={routes.employees_edit.path.replace(':id', employee.id)}>
            <Button type='link'><EditOutlined /></Button>
          </EditEmployeeLink>
          <Popover
            content={
              <Descriptions style={{ width: 500 }} size="small" column={2} layout="vertical" bordered>
                <Descriptions.Item label='Domain'>{getLabel(domains, employee.domain)}</Descriptions.Item>
                <Descriptions.Item label='Role'>{getLabel(roles, employee.role)}</Descriptions.Item>
                <Descriptions.Item label='Office' span={2}>{getLabel(offices, employee.office)}</Descriptions.Item>
                <Descriptions.Item label='English level'>{getLabel(enlevels, employee.en_level)}</Descriptions.Item>
                <Descriptions.Item label='English score'>{employee.en_score}</Descriptions.Item>
                <Descriptions.Item label='Officer'>
                  {employee.officer && <Link to={routes.employees_view.path.replace(':id', employee.officer.id)}>
                    {employee.officer.full_name}
                  </Link>}
                </Descriptions.Item>
                <Descriptions.Item label='Chief officer'>
                  {employee.chief_officer && <Link to={routes.employees_view.path.replace(':id', employee.chief_officer.id)}>
                    {employee.chief_officer.full_name}
                  </Link>}
                </Descriptions.Item>
              </Descriptions>
          }
            title="Employee Info"
            trigger="click"
            placement="left"
          >
            <Button type='text' size='small' icon={<InfoCircleOutlined />} />
          </Popover>
        </>
      }
      style={{ marginTop: 16, marginLeft: 16, width: 400 }}
    >
      <CardMeta
        avatar={
          <Link to={routes.employees_view.path.replace(':id', employee.id)}>
            <Avatar size={80} src={employee.avatar}/>
          </Link>
        }
        description={(
          <Descriptions
            column={1}
          >
            <Descriptions.Item label='Phone'>
              <LinkSpecial mode='phone'>{employee.phone}</LinkSpecial>
            </Descriptions.Item>
            <Descriptions.Item label='Email'>
              <LinkSpecial mode='email'>{employee.email}</LinkSpecial>
            </Descriptions.Item>
            <Descriptions.Item label='Mattermost'>
              <LinkSpecial mode='im'>{employee.im_nickname}</LinkSpecial>
            </Descriptions.Item>
            <Descriptions.Item label='Personal page'>
              <LinkSpecial mode='personal' style={{padding: 0}}>{employee.cipher_link}</LinkSpecial>
            </Descriptions.Item>
            <Descriptions.Item label='Birthday'>{employee.birthday}</Descriptions.Item>
          </Descriptions>
        )}
      />
    </Card>
  </>
);

export default EmployeeCard;