import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import {DeleteOutlined, EditOutlined, LinkOutlined} from '@ant-design/icons';
import routes from '../../../constants/routes';


const useColumns = (onConfirmDelete) => useMemo(() => ([
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    // fixed: 'left',
    sorter: true,
    render: (_, { id, first_name, last_name }) => (
      <Link to={`${routes.work_log_week_simple.path}?client_ids[]=${id}`}>{first_name} {last_name}</Link>
    ),
    width: 40,
  },
  {
    title: 'Redmine Link',
    dataIndex: 'redmine_link',
    key: 'redmine_link',
    render: redmine_link => <Button type='link' size='small' icon={<LinkOutlined />} target='_blank' href={redmine_link} />,
    width: 40,
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: id => (
      <Space>
        <Link to={routes.clients_edit.path.replace(':id', id)}>
          <Button type='link' size='small' icon={<EditOutlined />}/>
        </Link>
        <Popconfirm title='Are you sure?' onConfirm={() => onConfirmDelete(id)}>
          <Button type='link' size='small' icon={<DeleteOutlined />}/>
        </Popconfirm>
      </Space>
    ),
    width: 15,
    align: 'center',
    fixed: 'right'
  }
]), [onConfirmDelete]);


export default useColumns;