import { useParams } from 'react-router-dom';
import routes from '../../../constants/routes';
import { Spin, Typography } from 'antd';
import useFormLoader from '../../../hooks/useFormLoader';
import FaqForm from '../../../components/FaqForm';
import useFormHandlers from '../../../hooks/useFormHandlers';
import { fetchFaq, updateFaq } from '../../../store/faqs/actions';

const FaqsEditPage = () => {
  const { id } = useParams();
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
    action: updateFaq,
    successMessage: 'Faq updated successfully!',
    successPath: routes.faqs.path,
    actionParams: [id]
  });
  const { data, loading } = useFormLoader(fetchFaq, id);

  return (
    <>
      <Typography.Title level={3}>Edit faq</Typography.Title>
      { loading ? (
        <Spin/>
      ) : (
        <FaqForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          initialValues={data}
        />
      ) }
    </>
  )
}

export default FaqsEditPage;
