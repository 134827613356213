import { createAction } from 'redux-smart-actions';

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchClientsList = createAction('CLIENTS/FETCH_LIST',(searchParams = '') => ({
  request: {
    url: `/clients?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
  },
}));

/**
 * @param {Object} data
 * @param {string} data.first_name
 * @param {string} data.last_name
 * @param {string} data.email
 * @param {string} data.comment
 */
export const createClient = createAction('CLIENTS/CREATE', data => ({
  request: {
    url: '/clients',
    method: 'POST',
    data
  },
}));

/**
 * @param {number} id
 */
export const fetchClient = createAction('CLIENTS/FETCH', id => ({
  request: {
    url: `/clients/${id}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 * @param {Object} data
 * @param {string} data.first_name
 * @param {string} data.last_name
 * @param {string} data.email
 * @param {string} data.comment
 */
export const updateClient = createAction('CLIENTS/UPDATE', (id, data) => ({
  request: {
    url: `/clients/${id}`,
    method: 'PUT',
    data,
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 */
export const deleteClient = createAction('CLIENTS/DELETE', id => ({
  request: {
    url: `/clients/${id}`,
    method: 'DELETE',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {string} query
 * @param {array} searchParams
 */
export const searchClientOptions = createAction('CLIENTS/SEARCH_OPTIONS', (query = '', searchParams= '') => ({
  request: {
    url: `/clients/search?query=${encodeURIComponent(query)}${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: query.toString() + searchParams.toString(),
    getData: ({ data }) => data.map(({ id, first_name, last_name }) => ({ value: id, label: `${first_name} ${last_name}` }))
  },
}));