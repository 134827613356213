import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { isLoggedInSelector } from './store/auth/selectors';
import routes from './constants/routes';
import { ADMIN, HR_L1, HR_L2, OM, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2 } from './constants/roles';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout';
import PrivateRoute from './components/PrivateRoute';
import LoginPage from './pages/login';
import ForgotPasswordPage from './pages/forgot_password';
import ResetPasswordPage from './pages/reset_password';
import MyAccountPage from './pages/my_account';
import DashboardPage from './pages/dashboard';
import JobsCreatePage from './pages/work_log/jobs/create';
import JobsEditPage from './pages/work_log/jobs/edit';
import EmployeesPage from './pages/employees';
import EmployeesArchivePage from './pages/employees/archive';
import EmployeesCreatePage from './pages/employees/create';
import EmployeesEditPage from './pages/employees/edit';
import EmployeesViewPage from './pages/employees/view';
import EmployeePersonalPage from './pages/personal_info';
import AvatarsPage from './pages/avatars';
import AvatarsCreatePage from './pages/avatars/create';
import AvatarsEditPage from './pages/avatars/edit';
import AvatarsMetaEditPage from './pages/avatars/meta/edit';
import VacationsPage from './pages/vacations';
import VacationsCreatePage from './pages/vacations/create';
import VacationsEditPage from './pages/vacations/edit';
import VacationsLogPage from './pages/vacations/log';
import VacationsLogCreatePage from './pages/vacations/log/create';
import VacationsLogEditPage from './pages/vacations/log/edit';
import ClientsPage from './pages/clients';
import ClientsCreatePage from './pages/clients/create';
import ClientsEditPage from './pages/clients/edit';
import ApplicantsPage from './pages/applicants';
import ApplicantsCommentsPage from './pages/applicants/comments';
import ApplicantsCreatePage from './pages/applicants/create';
import ApplicantsEditPage from './pages/applicants/edit';
import PasswordsPage from './pages/passwords';
import PasswordsCreatePage from './pages/passwords/create';
import PasswordsEditPage from './pages/passwords/edit';
import UsersPage from './pages/users';
import UsersArchivePage from './pages/users/archive';
import UsersCreatePage from './pages/users/create';
import UsersEditPage from './pages/users/edit';
import ReportsPage from './pages/reports';
import EmployeeLayout from './layouts/EmployeeLayout';
import WorkLogTotalPage from './pages/work_log/total';
import AvatarsMetaPage from './pages/avatars/meta';
import AvatarsPasswordsPage from './pages/avatars/passwords';
import HrDashboardPage from './pages/dashboard/hr.js';
import HolidaysPage from './pages/holidays';
import HolidaysEditPage from './pages/holidays/edit';
import HolidaysCreatePage from './pages/holidays/create';

import EmployeesBalancesPage from './pages/employees/balances';
import WorkLogWeekSimplePage from './pages/work_log/week_simple';
import WorkLogWeekConsolidatedPage from './pages/work_log/week_consolidated';
import EmployeesOvertimesPage from './pages/employees/overtimes';
import FaqsPage from './pages/faqs';
import FaqsCreatePage from './pages/faqs/create';
import FaqsEditPage from './pages/faqs/edit';
import WarehousesPage from './pages/warehouses';
import WarehousesEditPage from './pages/warehouses/edit';
import WarehousesCreatePage from './pages/warehouses/create';
import WarehouseItem from './layouts/WarehouseItemLayout';
import ItemInfoPage from './pages/warehouses/item_info';

const Router = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);

  return (
    <BrowserRouter>
      <Switch>
        {/* Employee pages */}
        <Route exact path={[
          routes.employee_reports.path,
          routes.employee_reports_edit.path,
          routes.employee_personal_info.path
        ]}>
          <EmployeeLayout>
            <Route exact path={[routes.employee_reports.path, routes.employee_reports_edit.path]} component={ReportsPage}/>
            <Route exact path={routes.employee_personal_info.path} component={EmployeePersonalPage}/>
          </EmployeeLayout>
        </Route>

        <Route exact path={[
          routes.wh.path,
        ]}>
          {/*{!isLoggedIn && (*/}
            <WarehouseItem>
              <Route exact path={routes.wh.path} component={ItemInfoPage} />
            </WarehouseItem>
          {/*)}*/}
        </Route>

        {/* Public pages */}
        <Route exact path={[
          routes.auth_login.path,
          routes.auth_forgot_password.path,
          routes.auth_reset_password.path
        ]}>
          { isLoggedIn ? <Redirect to={routes.dashboard.path}/> : (
            <PublicLayout>
              <Route exact path={routes.auth_login.path} component={LoginPage}/>
              <Route exact path={routes.auth_forgot_password.path} component={ForgotPasswordPage}/>
              <Route exact path={routes.auth_reset_password.path} component={ResetPasswordPage}/>
            </PublicLayout>
          ) }
        </Route>

        {/* Private pages */}
        <Route>
          { !isLoggedIn ? <Redirect to={routes.auth_login.path}/> : (
            <PrivateLayout>
              {/* Dashboard */}
              <PrivateRoute exact path={routes.dashboard.path} component={DashboardPage}
                            roles={[ADMIN, TECH_L1, TECH_L2, PM_L1, PM_L2, PM_L3, HR_L1]}/>
              <PrivateRoute exact path={routes.dashboard.path} component={HrDashboardPage}
                            roles={[HR_L2]}/>
              <PrivateRoute exact path={routes.dashboard_hr.path} component={HrDashboardPage}
                            roles={[HR_L1]}/>
              {/* Account */}
              <PrivateRoute exact path={routes.auth_my_account.path} component={MyAccountPage}
                            roles={[ADMIN, TECH_L1, TECH_L2, PM_L1, PM_L2, PM_L3, HR_L1, HR_L2]}/>
              {/* WorkLog Week Simple */}
              <PrivateRoute exact path={routes.work_log_week_simple.path} component={WorkLogWeekSimplePage}
                            roles={[ADMIN, TECH_L1, TECH_L2, PM_L1, PM_L2, PM_L3]}/>
              {/* WorkLog Week Simple */}
              <PrivateRoute exact path={routes.work_log_week_consolidated.path} component={WorkLogWeekConsolidatedPage}
                            roles={[ADMIN, PM_L1]}/>
              {/* Jobs */}
              <PrivateRoute exact path={routes.jobs_create.path} component={JobsCreatePage}
                            roles={[ADMIN, PM_L1, PM_L2, PM_L3]}/>
              <PrivateRoute exact path={routes.jobs_edit.path} component={JobsEditPage}
                            roles={[ADMIN, PM_L1, PM_L2, PM_L3]}/>

              {/* WorkLog Total */}
              <PrivateRoute exact path={routes.work_log_total.path} component={WorkLogTotalPage}
                            roles={[ADMIN, PM_L1]}/>
              {/* Employees */}
              <PrivateRoute exact path={routes.employees.path} component={EmployeesPage}
                            roles={[ADMIN, TECH_L1, TECH_L2, PM_L1, PM_L2, PM_L3, HR_L1, OM]}/>
              <Switch>
                <PrivateRoute exact path={routes.employees_create.path} component={EmployeesCreatePage}
                              roles={[ADMIN, HR_L1]}/>
                <PrivateRoute exact path={routes.employees_edit.path} component={EmployeesEditPage}
                              roles={[ADMIN, HR_L1, TECH_L1, PM_L1]}/>
                <PrivateRoute exact path={routes.employees_archive.path} component={EmployeesArchivePage}
                              roles={[ADMIN, HR_L1]}/>
                <PrivateRoute exact path={routes.employees_balances.path} component={EmployeesBalancesPage}
                              roles={[ADMIN, HR_L1, PM_L1, PM_L2]}/>
                <PrivateRoute exact path={routes.employees_overtimes.path} component={EmployeesOvertimesPage}
                              roles={[ADMIN, PM_L1, PM_L1, PM_L2, PM_L3]}/>
                <PrivateRoute path={routes.employees_view.path} component={EmployeesViewPage}
                              roles={[ADMIN, TECH_L1, TECH_L2, PM_L1, PM_L2, PM_L3, HR_L1]}/>
              </Switch>
              {/* Avatars */}
              <PrivateRoute exact path={routes.avatars.path} component={AvatarsPage}
                            roles={[ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2]}/>
              <PrivateRoute exact path={routes.avatars_create.path} component={AvatarsCreatePage}
                            roles={[ADMIN, PM_L1]}/>
              <PrivateRoute exact path={routes.avatars_edit.path} component={AvatarsEditPage}
                            roles={[ADMIN, PM_L1]}/>
              {/* Avatars Meta */}
              <PrivateRoute exact path={routes.avatars_meta.path} component={AvatarsMetaPage}
                            roles={[ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2]}/>
              <PrivateRoute exact path={routes.avatars_meta_edit.path} component={AvatarsMetaEditPage}
                            roles={[ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2]}/>
              {/* Avatars Passwords */}
              <PrivateRoute exact path={routes.avatars_passwords.path} component={AvatarsPasswordsPage}
                            roles={[ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2]}/>
              {/* Vacations */}
              <PrivateRoute exact path={routes.vacations.path} component={VacationsPage}
                            roles={[ADMIN, TECH_L1, PM_L1, PM_L2, PM_L3, HR_L1]}/>
              <PrivateRoute exact path={routes.vacations_create.path} component={VacationsCreatePage}
                            roles={[ADMIN, TECH_L1, PM_L1, PM_L2, PM_L3, HR_L1]}/>
              <PrivateRoute exact path={routes.vacations_edit.path} component={VacationsEditPage}
                            roles={[ADMIN, TECH_L1, PM_L1, PM_L2, PM_L3, HR_L1]}/>
              {/* Vacations log */}
              <PrivateRoute exact path={routes.vacations_log.path} component={VacationsLogPage}
                            roles={[ADMIN, TECH_L1, PM_L1, PM_L2, PM_L3, HR_L1]}/>
              <PrivateRoute exact path={routes.vacations_log_create.path} component={VacationsLogCreatePage}
                            roles={[ADMIN, TECH_L1, PM_L1, PM_L2, PM_L3, HR_L1]}/>
              <PrivateRoute exact path={routes.vacations_log_edit.path} component={VacationsLogEditPage}
                            roles={[ADMIN, TECH_L1, PM_L1, PM_L2, PM_L3, HR_L1]}/>
              {/* Clients */}
              <PrivateRoute exact path={routes.clients.path} component={ClientsPage}
                            roles={[ADMIN, PM_L1, PM_L2, PM_L3]}/>
              <PrivateRoute exact path={routes.clients_create.path} component={ClientsCreatePage}
                            roles={[ADMIN, PM_L1, PM_L2, PM_L3]}/>
              <PrivateRoute exact path={routes.clients_edit.path} component={ClientsEditPage}
                            roles={[ADMIN, PM_L1, PM_L2, PM_L3]}/>
              {/* Applicants */}
              <PrivateRoute exact path={routes.applicants.path} component={ApplicantsPage}
                            roles={[ADMIN, TECH_L1, PM_L1, HR_L1, HR_L2]}/>
              <PrivateRoute exact path={routes.applicants_create.path} component={ApplicantsCreatePage}
                            roles={[ADMIN, HR_L1, HR_L2]}/>
              <PrivateRoute exact path={routes.applicants_edit.path} component={ApplicantsEditPage}
                            roles={[ADMIN, HR_L1, HR_L2, PM_L1, TECH_L1]}/>
              <PrivateRoute exact path={routes.applicants_comments.path} component={ApplicantsCommentsPage}
                            roles={[ADMIN, HR_L1, HR_L2, PM_L1, TECH_L1]}/>
              {/* Passwords */}
              <PrivateRoute exact path={routes.passwords.path} component={PasswordsPage}
                            roles={[ADMIN, TECH_L1, TECH_L2, PM_L1, PM_L2, PM_L3]}/>
              <PrivateRoute exact path={routes.passwords_create.path} component={PasswordsCreatePage}
                            roles={[ADMIN, TECH_L1, TECH_L2, PM_L1, PM_L2, PM_L3]}/>
              <PrivateRoute exact path={routes.passwords_edit.path} component={PasswordsEditPage}
                            roles={[ADMIN, TECH_L1, TECH_L2, PM_L1, PM_L2, PM_L3]}/>
              {/* Users */}
              <PrivateRoute exact path={routes.users.path} component={UsersPage}
                            roles={[ADMIN, PM_L1]}/>
              <PrivateRoute exact path={routes.users_archive.path} component={UsersArchivePage}
                            roles={[ADMIN]}/>
              <PrivateRoute exact path={routes.users_create.path} component={UsersCreatePage}
                            roles={[ADMIN]}/>
              <PrivateRoute exact path={routes.users_edit.path} component={UsersEditPage}
                            roles={[ADMIN, PM_L1]}/>

              {/* Holidays */}
              <PrivateRoute exact path={routes.holidays.path} component={HolidaysPage}
                            roles={[ADMIN]}/>
              <PrivateRoute exact path={routes.holidays_create.path} component={HolidaysCreatePage}
                            roles={[ADMIN]}/>
              <PrivateRoute exact path={routes.holidays_edit.path} component={HolidaysEditPage}
                            roles={[ADMIN]}/>

              {/* Faqs */}
              <PrivateRoute exact path={routes.faqs.path} component={FaqsPage}
                            roles={[ADMIN]}/>
              <PrivateRoute exact path={routes.faqs_create.path} component={FaqsCreatePage}
                            roles={[ADMIN]}/>
              <PrivateRoute exact path={routes.faqs_edit.path} component={FaqsEditPage}
                            roles={[ADMIN]}/>

              {/* Warehouses */}
              <PrivateRoute exact path={routes.warehouses.path} component={WarehousesPage}
                            roles={[ADMIN, HR_L1, OM]}/>
              <PrivateRoute exact path={routes.warehouses_create.path} component={WarehousesCreatePage}
                            roles={[ADMIN, HR_L1, OM]}/>
              <PrivateRoute exact path={routes.warehouses_edit.path} component={WarehousesEditPage}
                            roles={[ADMIN, HR_L1, OM]}/>
            </PrivateLayout>
          ) }
        </Route>
      </Switch>
    </BrowserRouter>
  )
};

export default Router;
