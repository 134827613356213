import React, {useState} from 'react';
import {Form, TimePicker} from 'antd';
import {TIME_FORMAT} from '../../../../../constants/config';
import {convertTimeInHoursMoment} from "../../../../../helpers";


const TimePickerCell = ({dataIndex, onAction, workLog}) => {
    const [open, setOpen] = useState(false);

    return (<Form.Item
        style={{margin: 0}}
        name={dataIndex}
        initialValue={convertTimeInHoursMoment(workLog?.time||"00:00")}
    >
        <TimePicker
            autoFocus
            open={open}
            placeholder='Worked hours'
            style={{ width: '100%' }}
            format={TIME_FORMAT}
            showNow={false}
            onOpenChange={ isOpen => {
                if (!isOpen) {
                    setOpen(!open)
                    onAction();
                }
            }}
            onFocus={() => setOpen(!open)}
            minuteStep={1}
        />
    </Form.Item>);
};

export default TimePickerCell;