import React, {useCallback, useMemo} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '@redux-requests/react';
import {Button, Form, Select, Table} from 'antd';
import useColumns from './hooks/useColumns';
import routes from '../../../constants/routes';
import { roleOptions } from '../../../constants/users';
import usePagination from '../../../hooks/usePagination';
import useSearchParams from '../../../hooks/useSearchParams';
import mapUrlSearchParams from '../../../utils/mapUrlSearchParams';
import { fetchDeletedUsersList, restoreUser } from '../../../store/users/actions';
import SearchSelect from "../../../components/SearchSelect";
import {searchEmployeeOptions} from "../../../store/employees/actions";
import {joinSearchString} from "../../work_log/tableHelpers";


const UsersArchivePage = () => {
  const query = '';
  const dispatch = useDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const arrayOfEmployeeIds = searchParams.getAll('employee_ids[]');
  const stringEmployeeIds = useMemo(() => joinSearchString(arrayOfEmployeeIds, 'employee_ids'), [arrayOfEmployeeIds]);
  const newEmployeeIds = useMemo(() => arrayOfEmployeeIds.map( id => (+id)), [arrayOfEmployeeIds]);

  const { data: employees } = useQuery({
    type: searchEmployeeOptions,
    action: searchEmployeeOptions,
    variables: [query, stringEmployeeIds],
    requestKey: query + stringEmployeeIds,
    autoLoad: !!arrayOfEmployeeIds.length,
  });

  const handleSearchEmployee = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [ dispatch ]);
  const handleChangeEmployee = useCallback(employee_ids => setSearchParams({ 'employee_ids[]': employee_ids }), [setSearchParams]);
  const handleChangeRoles = useCallback(roles => setSearchParams({ 'roles[]': roles }), [setSearchParams]);
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const { data, loading, load } = useQuery({
    type: fetchDeletedUsersList,
    action: fetchDeletedUsersList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
    autoReset: true,
  });
  const pagination = usePagination(data?.meta);
  const handleRestore = useCallback(id => dispatch(restoreUser(id)).then(() => load()), [dispatch, load]);
  const columns = useColumns(handleRestore);

  return (
    <>
      <div className='section-filters'>
        <Form
          layout="inline"
          className="components-table-demo-control-bar"
        >
          <Form.Item>
            <SearchSelect
              mode='multiple'
              placeholder='Employee'
              onSearch={handleSearchEmployee}
              onChange={handleChangeEmployee}
              value={newEmployeeIds}
              defaultOption={employees}
              style={{ width: 220 }}
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Role'
              mode='multiple'
              maxTagCount='responsive'
              value={searchParams.getAll('roles[]')}
              options={roleOptions}
              onChange={handleChangeRoles}
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
        </Form>
        <Link to={routes.users_create.path}>
          <Button type='primary'>Create</Button>
        </Link>
      </div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.data}
        pagination={pagination}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
        scroll={{ x: 650 }}
      />
    </>
  );
};

export default UsersArchivePage;