import React from 'react';
import {Space, Tooltip} from 'antd';
import {ProjectTwoTone, ToolTwoTone} from '@ant-design/icons';
import {StyledTwoToneIcon} from '../../styles/styles';


const TooltipTitle = (workLog, swappedManager, swappedEmployee) => (
  <Space direction='vertical'>
    {swappedManager && <Space>
      <Tooltip title='Manager'>
        <StyledTwoToneIcon><ProjectTwoTone/></StyledTwoToneIcon>
      </Tooltip>
      {workLog.manager.full_name}
    </Space>}
    {swappedEmployee && <Space>
      <Tooltip title='Employee'>
        <StyledTwoToneIcon><ToolTwoTone/></StyledTwoToneIcon>
      </Tooltip>
      {workLog.employee.full_name}
    </Space>}
  </Space>
);

export default TooltipTitle;