import React from 'react';
import useFormSubmit from '../../hooks/useFormSubmit';
import { DATE_FORMAT } from '../../constants/config';
import { Button, Col, DatePicker, Form, Input, Row, Space } from 'antd';
import moment from 'moment';

const validationRules = {
  date: [
    { required: true }
  ],
};

const HolidayForm = ({
  onSubmit,
  onSuccess,
  onCancel,
  initialValues = null
}) => {
  const [form] = Form.useForm();
  const {
    submitting,
    handleSubmit
  } = useFormSubmit(form, onSubmit, onSuccess);

  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      initialValues={{
        ...initialValues,
        date: initialValues?.date
          ? moment(initialValues.date)
          : moment()
      }}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Day"
            name="date"
            rules={validationRules.date}
          >
            <DatePicker
              placeholder="Holiday Day"
              style={{ width: '100%' }}
              format={DATE_FORMAT}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Space>
          <Button htmlType="button" loading={submitting} onClick={onCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={submitting}>Submit</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default HolidayForm;
