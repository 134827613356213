import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DATE_FORMAT } from '../../constants/config';
import useFormSubmit from '../../hooks/useFormSubmit';
import { updateWorkLogsRate } from '../../store/jobs/actions';
import { Button, DatePicker, Form, InputNumber, notification, Popconfirm, Switch } from 'antd'

const validationRules = {
  rate: [
    { required: true }
  ],
  start_date: [
    { required: true }
  ],
  end_date: [
    { required: true }
  ],
};

const UpdateWorkLogsRate = ({jobId, defaultRate, jobsLoad}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [autoCalculateMode, setAutoCalculateMode] = useState(false);

  const onSubmit = useCallback(data => dispatch(updateWorkLogsRate(jobId, data)), [dispatch, jobId])
  const onSuccess = useCallback(() => {
      notification.success({ message: 'Rate changed successfully!' });
      return jobsLoad();
    }, [jobsLoad]);

  const { submitting, handleSubmit } = useFormSubmit(form, onSubmit, onSuccess);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (<Form
      {...layout}
      form={form}
      layout='horizontal'
      validateTrigger={['onBlur']}
      initialValues={{
        amount: 0,
        rate: defaultRate,
        start_date: null,
        end_date: null,
      }}
      onFinish={handleSubmit}
      size='default'
    >

      <Form.Item
        labelAlign='left'
      >
        <Switch
          checkedChildren="Auto" unCheckedChildren="Manual"
          checked={autoCalculateMode}
          onChange={() => setAutoCalculateMode(!autoCalculateMode)}
        />
      </Form.Item>

      {autoCalculateMode ? (<Form.Item
          labelAlign='left'
          label='Amount'
          name='amount'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber placeholder='Total amount' prefix='$' />
        </Form.Item>) : (<Form.Item
          labelAlign='left'
          label='Rate'
          name='rate'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber placeholder='Rate' />
        </Form.Item>)}
      <Form.Item
        labelAlign='left'
        label='Start date'
        name='start_date'
        rules={validationRules.start_date}
      >
        <DatePicker style={{ width: '100%' }} placeholder='Start date' format={DATE_FORMAT}/>
      </Form.Item>
      <Form.Item
        labelAlign='left'
        label='End date'
        name='end_date'
        rules={validationRules.end_date}>
        <DatePicker style={{ width: '100%' }} placeholder='End date' format={DATE_FORMAT}/>
      </Form.Item>

      <Popconfirm
        title="Are you sure?"
        onConfirm={form.submit}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type='primary'
          block
          loading={submitting}
        >
          Submit
        </Button>
      </Popconfirm>
    </Form>);
};

export default UpdateWorkLogsRate;