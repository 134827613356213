import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';


const useFormHandlers = ({action, successMessage, successPath, actionParams = []}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = useCallback(async (data) => dispatch(action(...actionParams, data)), [dispatch, action, actionParams]);
  const handleSuccess = useCallback(() => {
    notification.success({ message: successMessage });

    if (successPath) {
      history.push(successPath);
    }
  }, [history, successMessage, successPath, actionParams]);
  const handleCancel = useCallback(() => history.goBack(), [history]);

  return [handleSubmit, handleSuccess, handleCancel];
};

export default useFormHandlers;