import { Typography } from 'antd';
import useFormHandlers from '../../../../hooks/useFormHandlers';
import routes from '../../../../constants/routes';
import VacationLogForm from '../../../../components/VacationLogForm';
import { createVacationLog } from '../../../../store/vacation_logs/actions';


const VacationsLogCreatePage = () => {
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
      action: createVacationLog,
      successMessage: 'Log vacation added successfully!',
      successPath: routes.vacations_log.path
  });

  return (
    <>
      <Typography.Title level={3}>Log vacation</Typography.Title>
      <VacationLogForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
      />
    </>
  )
}

export default VacationsLogCreatePage;