import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import authToken from '../../../utils/authToken';
import {fetchAccount, setLoggedIn} from '../../../store/auth/actions';


const useAppInitialize = () => {
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(true);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  useEffect(() => {
    const token = authToken.get()
    if (token) {
      dispatch(setLoggedIn())
    } else {
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        setLoading(true)
        await dispatch(fetchAccount())
        setLoading(false)
      })()
    }
  }, [dispatch, isLoggedIn])

  return { loading };
};

export default useAppInitialize;