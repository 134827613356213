import { Image } from 'antd';
import Link from './styled/Link';
import logo from './images/rezet_logo_black.svg';


const Logo = ({ onClick, buttonProps, ...imageProps }) => (
  <Link onClick={onClick}>
    <Image width={200} src={logo} preview={false} alt='reZet' {...imageProps}/>
  </Link>
);

export default Logo;