import {useCallback} from 'react';
import useColumns from './hooks/useColumns';
import {useQuery} from '@redux-requests/react';
import {useParams} from 'react-router-dom';
import usePagination from '../../../../hooks/usePagination';
import useSearchParams from '../../../../hooks/useSearchParams';
import mapUrlSearchParams from '../../../../utils/mapUrlSearchParams';
import {fetchWarehousesList} from '../../../../store/employees/actions';

import {Table} from 'antd';



const Warehouses = () => {
  const {id} = useParams();
  const [setSearchParams] = useSearchParams();

  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const {
    data,
    loading
  } = useQuery({
    type: fetchWarehousesList,
    action: fetchWarehousesList,
    variables: [id],
    requestKey: id,
    autoLoad: true,
    autoReset: true,
  });




  const pagination = usePagination(data?.meta);
  const columns = useColumns();

  return (
    <>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.data}
        pagination={pagination}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
        scroll={{ x: 1300 }}
      />
    </>
  )
}

export default Warehouses;