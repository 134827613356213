import React, {useEffect, useState} from 'react';
import useFormSubmit from '../../hooks/useFormSubmit';
import {offices, roles} from '../../constants/employees';
import {types} from "../../constants/faqs";
import {Button, Col, Form, Input, Row, Select, Space} from 'antd';




const FaqForm = ({
  onSubmit,
  onSuccess,
  onCancel,
  initialValues = null
}) => {
  const [form] = Form.useForm();
  const {
    submitting,
    handleSubmit
  } = useFormSubmit(form, onSubmit, onSuccess);

  const [checkRole, setCheckRole] = useState(false);
  const [checkOffice, setCheckOffice] = useState(false);

  useEffect(() => {
    form.validateFields(['role', 'office']);
  }, [checkRole, checkOffice]);

  const validationRules = {
    title: [
      { required: true }
    ],
    type: [
      { required: true }
    ],
  };

  const onTypeChange = (value) => {
    switch (value) {
      case 'general': {
        setCheckRole(false);
        setCheckOffice(false);
      } break;
      case 'office': {
        setCheckRole(false);
        setCheckOffice(true);
      } break;
      case 'role': {
        setCheckRole(true);
        setCheckOffice(false);
      } break;
    }
  };

  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      initialValues={{
        ...initialValues,
      }}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Title"
            name="title"
            rules={validationRules.title}
          >
            <Input placeholder='Faq title'/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label='Role'
            name='role'
            rules={[
              {
                required: checkRole,
              },
            ]}
          >
            <Select placeholder='Role' options={roles} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label='Office'
            name='office'
            rules={[
              {
                required: checkOffice,
              },
            ]}
          >
            <Select placeholder='Office' options={offices} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label='Type'
            name='type'
          >
            <Select placeholder='Faq type' options={types} onChange={onTypeChange} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label='Content'
        name='content'
      >
        <Input placeholder='Content'/>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button htmlType="button" loading={submitting} onClick={onCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={submitting}>Submit</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default FaqForm;
