import React, {useCallback, useEffect, useMemo} from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import routes from '../../constants/routes';
import { useQuery } from '@redux-requests/react';
import uploadCSVFile from '../../utils/uploadCSVFile';
import usePagination from '../../hooks/usePagination';
import SearchSelect from '../../components/SearchSelect';
import useSearchParams from '../../hooks/useSearchParams';
import { joinSearchString } from '../work_log/tableHelpers';
import mapUrlSearchParams from '../../utils/mapUrlSearchParams';
import { searchEmployeeOptions } from '../../store/employees/actions';
import { employeeOfficeOptions } from '../../constants/employeeOffice';
import { deleteWarehouse, fetchWarehousesList } from '../../store/warehouses/actions';
import { UploadOutlined } from '@ant-design/icons';
import {Button, Form, message, notification, Select, Table, Tabs, Upload} from 'antd';
import useOtherColumns from './hooks/useOtherColumns';
import useAppleColumns from "./hooks/useAppleColumns";
import usePcColumns from "./hooks/usePcColumns";
import useMonitorColumns from "./hooks/useMonitorColumns";
const { TabPane } = Tabs;

const WarehousesPage = () => {
  const query = '';
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isUploading, setIsUploading] = React.useState(false);

  const arrayOfEmployeeIds = searchParams.getAll('employee_ids[]');

  const stringEmployeeIds = useMemo(() => joinSearchString(arrayOfEmployeeIds, 'employee_ids'), [arrayOfEmployeeIds]);
  const newEmployeeIds = useMemo(() => arrayOfEmployeeIds.map( id => (+id)), [arrayOfEmployeeIds]);

  const handleSearchEmployee = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [ dispatch ]);
  const handleChangeEmployee = useCallback(employee_ids => setSearchParams({ 'employee_ids[]': employee_ids }), [setSearchParams]);

  const handleChangeLocations = useCallback(locations => setSearchParams({ 'locations[]': locations }), [setSearchParams]);

  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );


  const { data: employees } = useQuery({
    type: searchEmployeeOptions,
    action: searchEmployeeOptions,
    variables: [query, stringEmployeeIds],
    requestKey: query + stringEmployeeIds,
    autoLoad: !!arrayOfEmployeeIds.length,
  });

  const {
    data,
    loading,
    load
  } = useQuery({
    type: fetchWarehousesList,
    action: fetchWarehousesList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
    autoReset: true,
  });

  const handleDelete = useCallback(id => (dispatch(deleteWarehouse(id)).then(() => load())
  ), [dispatch, load]);



  const handleError = error => {
    console.error({'interceptor onError': {error}});
    notification.error({
      message: error.response?.statusText || error.message,
      description: error.response?.data?.message
    });
  }

  const handleUploadFile = async (options) => {
    setIsUploading(true);
    const { file, onError, onSuccess } = options;
    if (!['text/csv', 'csv', 'xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].find( value => value === file.type)) {
      message.error(`The file ${file.name} are not text/csv, csv, xlsx!`)
      setIsUploading(false);
      onError();
      return Upload.LIST_IGNORE;
    }

    const isLt10M = (file.size / 1024 / 1024) < 2;
    if (!isLt10M) {
      message.error('CSV file must smaller than 10MB!');
      setIsUploading(false);
      onError();
      return Upload.LIST_IGNORE;
    }

    const formData = new FormData();
    formData.append('warehouse_file', file);

    try {
      const response = await uploadCSVFile(formData);

      if (response?.error) {
        handleError(response?.error);
        onError(response?.error);
      }

      onSuccess();
      notification.success({ message: 'File successfully uploaded!' });
      load();
    } catch (error) {
      handleError(error);
      onError(error);
    }

    setIsUploading(false);
  }

  const pagination = usePagination(data?.meta);

  function changeTab(key) {
    setSearchParams({'types[]': key, 'page': 1})
  }
  useEffect(() => {
    setSearchParams({'types[]': 'apple', 'page': 1})
  }, []);

  const columns = useOtherColumns(handleDelete);
  const apple = useAppleColumns(handleDelete);
  const pc = usePcColumns(handleDelete);
  const monitor = useMonitorColumns(handleDelete);

  return (
    <>
      <div className='section-filters'>
        <Form
          layout="inline"
          className="components-table-demo-control-bar"
        >
          <Form.Item>
            <SearchSelect
              mode='multiple'
              placeholder='Employee'
              onSearch={handleSearchEmployee}
              onChange={handleChangeEmployee}
              value={newEmployeeIds}
              defaultOption={employees}
              style={{ width: 220 }}
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder='Location'
              value={searchParams.getAll('locations[]')}
              options={employeeOfficeOptions}
              onChange={handleChangeLocations}
              mode='multiple'
              maxTagCount='responsive'
              style={{ width: 220 }}
              allowClear
            />
          </Form.Item>
        </Form>
        <Upload
          customRequest={handleUploadFile}
        >
          <Button
            loading={isUploading}
            icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        <Link to={routes.warehouses_create.path} style={{ marginLeft: 'auto' }}>
          <Button type='primary'>Create</Button>
        </Link>
      </div>
      <Tabs defaultActiveKey="apple" onChange={changeTab}>
        <TabPane tab="Apple" key="apple">
          <Table
            columns={apple}
            loading={loading}
            dataSource={data?.data}
            pagination={pagination}
            onChange={handleChangeTable}
            rowKey="id"
            size="small"
            scroll={{ x: 1300 }}
          />
        </TabPane>
        <TabPane tab="PC" key="pc">
          <Table
            columns={pc}
            loading={loading}
            dataSource={data?.data}
            pagination={pagination}
            onChange={handleChangeTable}
            rowKey="id"
            size="small"
            scroll={{ x: 1300 }}
          />
        </TabPane>
        <TabPane tab="Monitor" key="monitor">
          <Table
            columns={monitor}
            loading={loading}
            dataSource={data?.data}
            pagination={pagination}
            onChange={handleChangeTable}
            rowKey="id"
            size="small"
            scroll={{ x: 1300 }}
          />
        </TabPane>
        <TabPane tab="Chair" key="chair">
          <Table
            columns={columns}
            loading={loading}
            dataSource={data?.data}
            pagination={pagination}
            onChange={handleChangeTable}
            rowKey="id"
            size="small"
            scroll={{ x: 1300 }}
          />
        </TabPane>
        <TabPane tab="Other" key="other">
          <Table
            columns={columns}
            loading={loading}
            dataSource={data?.data}
            pagination={pagination}
            onChange={handleChangeTable}
            rowKey="id"
            size="small"
            scroll={{ x: 1300 }}
          />
        </TabPane>
      </Tabs>



    </>
  );
}

export default WarehousesPage;
