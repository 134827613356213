import { Button } from 'antd';
import styled from 'styled-components';


const Link = styled(Button).attrs(props => ({
  type: 'link',
  block: true,
}))`
  width: 100%;
  height: 100%;
  padding: 0;
`;

export default Link;