export const EMPLOYEE_OFFICES = {
  ZP01: 'zp01',
  ZP02: 'zp02',
  ZP03: 'zp03',
  DP04: 'dp04',
  REMOTE: 'remote',
};

export const employeeOfficeOptions = [
  {
    value: EMPLOYEE_OFFICES.ZP01,
    label: 'Zp01'
  },
  {
    value: EMPLOYEE_OFFICES.ZP02,
    label: 'Zp02'
  },
  {
    value: EMPLOYEE_OFFICES.ZP03,
    label: 'Zp03'
  },
  {
    value: EMPLOYEE_OFFICES.DP04,
    label: 'Dp04'
  },
  {
    value: EMPLOYEE_OFFICES.REMOTE,
    label: 'Remote'
  }
];