import { Typography } from 'antd';
import routes from '../../../constants/routes';
import useFormHandlers from '../../../hooks/useFormHandlers';
import WarehouseForm from '../../../components/WarehouseForm';
import { createWarehouse } from '../../../store/warehouses/actions';


const WarehousesCreatePage = () => {
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
    action: createWarehouse,
    successMessage: 'Product added successfully!',
    successPath: routes.warehouses.path
  });

  return (
    <>
      <Typography.Title level={3}>Create Product</Typography.Title>
      <WarehouseForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
      />
    </>
  )
}

export default WarehousesCreatePage;