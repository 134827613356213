import moment from 'moment';
import styled from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@redux-requests/react';
import {
  Table,
  Button,
  Typography,
  Space,
  Divider,
  Row,
  Col,
  Card,
  Statistic,
  PageHeader,
  Menu,
  Modal, Dropdown
} from 'antd';
import { LinkOutlined, CommentOutlined } from '@ant-design/icons';
import routes from '../../constants/routes';
import { IM_BASE_URL } from '../../constants/config';
import usePagination from '../../hooks/usePagination';
import useSearchParams from '../../hooks/useSearchParams';
import mapUrlSearchParams from '../../utils/mapUrlSearchParams';
import {
  createAndUpdateEmployeeFaq,
  fetchEmployeeFaqs,
  fetchEmployeePersonalData,
  fetchEmployeeSickLeaveBalanceByCipher,
  fetchEmployeeVacationBalanceByCipher
} from '../../store/employees/actions';
import {fetchVacationLogsListByCipher, fetchWarehousesListByCipher} from '../../store/vacation_logs/actions';
import { formatDate, getLabel } from '../../helpers'
import { typeOptions } from '../../constants/vacationLogs'
import {roles} from '../../constants/employees';
import Iframe from 'react-iframe';
import { useDispatch } from 'react-redux';


const jobsColumns = [
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'Profile',
    dataIndex: ['avatar', 'short_name'],
    key: 'avatar',
  },
  {
    title: 'Client',
    dataIndex: ['client', 'full_name'],
    key: 'client',
  },
  {
    title: 'Job name',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Manager',
    dataIndex: ['manager', 'full_name'],
    key: 'manager',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: (_, { cipher_link, im_channel_id }) => (
      <Space>
        <Link to={routes.employee_reports.path.replace(':cipher', cipher_link)}>
          <Button type='link' size='small' icon={<LinkOutlined />}/>
        </Link>
        <Button
          type='link'
          size='small'
          href={`${IM_BASE_URL}/messages/channels/${im_channel_id}`}
          icon={<CommentOutlined/>}
        />
      </Space>
    ),
    width: 90,
    align: 'center',
  }
];

const vacationsColumns = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: type => getLabel(typeOptions, type)
  },
  {
    title: 'From',
    dataIndex: 'from',
    key: 'from',
  },
  {
    title: 'To',
    dataIndex: 'to',
    key: 'to',
  }
];

const usedDaysColumns = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: true,
    render: type => getLabel(typeOptions, type)
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: true,
    render: date => formatDate(moment(date))
  },
  {
    title: 'Modifier',
    dataIndex: 'usage',
    key: 'usage',
    sorter: true,
    render: usage => usage > 0 ? `+${usage}` : usage
  }
];

const warehousesColumns = [
  {
    title: 'Serial',
    dataIndex: 'serial',
    key: 'serial',
    render: (serial) => <Link to={routes.wh.path + '/?serial='+serial}>{serial}</Link>
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Checked at',
    dataIndex: 'checked_at',
    key: 'checked_at',
    sorter: true,
  }
];

const TypographyWrapper = styled(Space)`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

const PersonalInfoPage = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { cipher } = useParams();

  const [newFaqs, setNewFaqs] = useState([]);

  const handleVacationLogsChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const { data, loading } = useQuery({
    type: fetchEmployeePersonalData,
    action: fetchEmployeePersonalData,
    variables: [cipher],
    requestKey: cipher,
    autoLoad: true,
  });

  const { data: faqs, faqsLoading } = useQuery({
    type: fetchEmployeeFaqs,
    action: fetchEmployeeFaqs,
    variables: [cipher],
    requestKey: cipher,
    autoLoad: true,
  });

  const {data: vacationBalance, loading: vacationBalanceLoading} = useQuery({
    type: fetchEmployeeVacationBalanceByCipher,
    action: fetchEmployeeVacationBalanceByCipher,
    variables: [cipher],
    requestKey: cipher,
    autoLoad: true,
  });

  const {data: sickLeaveBalance, loading: sickLeaveBalanceLoading} = useQuery({
    type: fetchEmployeeSickLeaveBalanceByCipher,
    action: fetchEmployeeSickLeaveBalanceByCipher,
    variables: [cipher],
    requestKey: cipher,
    autoLoad: true,
  });

  const { data: vacationLogs, loading: vacationLogsLoading } = useQuery({
    type: fetchVacationLogsListByCipher,
    action: fetchVacationLogsListByCipher,
    variables: [cipher, searchParams],
    requestKey: cipher + searchParams.toString(),
    autoLoad: true,
  });

  const { data: warehouses, loading: warehousesLoading } = useQuery({
    type: fetchWarehousesListByCipher,
    action: fetchWarehousesListByCipher,
    variables: [cipher, searchParams],
    requestKey: cipher + searchParams.toString(),
    autoLoad: true,
  });

  const vacationLogsPagination = usePagination(vacationLogs?.meta);

  const handleSubmit = useCallback((id) => dispatch(createAndUpdateEmployeeFaq(id, cipher)), [cipher, dispatch])

  const modalInfo = useCallback(({id, title, content}) => {
    return (
      Modal.info({
        title: title,
        width: 1000,
        content: (
          <>
            <Iframe
              url={content}
              width="900px"
              height="900px"
              display="initial"
              position="relative"
            />
          </>
        ),
        onOk() {
          const filteredFaqs = newFaqs.filter(faq => faq.id !== id)
          setNewFaqs(filteredFaqs);
          !(newFaqs.length === filteredFaqs.length) && handleSubmit(id);
        },
      })
    )
  }, [handleSubmit, newFaqs]);

  useEffect(() => {
    if (faqs) {
      const filteredEmployeeFaqs = faqs.filter(faq => !faq.viewed);
      setNewFaqs(filteredEmployeeFaqs);
    }
  }, [faqs]);

  useEffect(() => {
    const foundFaq = newFaqs[0];
    foundFaq && modalInfo(foundFaq);
  }, [modalInfo, newFaqs]);

  return (
    <>
      <PageHeader
        ghost={false}
        title={data?.full_name}
        subTitle={getLabel(roles, data?.role)}
        avatar={{ src: data?.avatar, size: 80 }}
        extra={faqsLoading ? <></>: <Dropdown overlay={<Menu>
          {(faqs?.map(item => <Menu.Item
              key={item.id}
              onClick={() => modalInfo(item)}
            >{item.title}</Menu.Item>))}
        </Menu>}><Button>FAQs</Button></Dropdown>}

      >
      </PageHeader>
      <Table
        bordered
        rowKey='id'
        columns={jobsColumns}
        loading={loading}
        dataSource={data?.jobs}
        pagination={false}
        size='small'
      />
      <TypographyWrapper>
        <Typography.Title level={3}>Vacations</Typography.Title>
      </TypographyWrapper>
      <Table
        bordered
        rowKey='id'
        columns={vacationsColumns}
        loading={loading}
        dataSource={data?.vacations}
        pagination={false}
        size='small'
      />
      <TypographyWrapper>
        <Typography.Title level={3}>Available days</Typography.Title>
      </TypographyWrapper>
      <div>
        <Row gutter={16}>
          <Col span={12}>
            {!vacationBalanceLoading && <Card>
              <Statistic
                  title="Available sick leaves"
                  value={sickLeaveBalance}
              />
            </Card>}
          </Col>
          <Col span={12}>
            {!sickLeaveBalanceLoading && <Card>
              <Statistic
                  title="Available paid time off (days)"
                  value={vacationBalance}
              />
            </Card>}
          </Col>
        </Row>
      </div>
      <Divider />
      <Table
        bordered
        rowKey='id'
        columns={usedDaysColumns}
        loading={vacationLogsLoading}
        dataSource={vacationLogs?.data}
        pagination={vacationLogsPagination}
        onChange={handleVacationLogsChangeTable}
        size='small'
      />
      <Divider />
      <TypographyWrapper>
        <Typography.Title level={3}>Inventory</Typography.Title>
      </TypographyWrapper>
      <Table
        bordered
        rowKey='id'
        columns={warehousesColumns}
        loading={warehousesLoading}
        dataSource={warehouses?.data}
        pagination={false}
        size='small'
      />
    </>
  )
}

export default PersonalInfoPage;