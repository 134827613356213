import React, {useCallback, useState} from 'react';
import { BASE_URL } from '../../constants/config';
import withRole from '../../hoc/withRole';
import { ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1 } from '../../constants/roles';
import { Button } from 'antd';
import EmployeeVacationLogInfo from '../EmployeeVacationLogInfo';

const Btn = withRole([ADMIN, TECH_L1, PM_L1, PM_L2, PM_L3])(Button);

const MissedVacationLog = ({missedVacationLog, onCancel, onSuccessCancel}) => {
  const [submitting, setSubmitting] = useState(false);

  const {missed_day, vacation, employee} = missedVacationLog;

  const handleCancel = useCallback(async () => {
    const response = await onCancel(vacation.id, {date: missed_day});

    setSubmitting(false);

    if (!response?.error) {
      onSuccessCancel();
    }
  }, [missed_day, onCancel, onSuccessCancel, vacation.id]);

  const btnActions = [
    <Btn
      loading={submitting}
      type="primary"
      target="_blank"
      href={`${BASE_URL}/employees/${employee.id}/logs/create?type=${vacation.type}&date=${missed_day}`}
    >
      Log
    </Btn>,
    <Btn
      loading={submitting}
      onClick={handleCancel}
    >
      Cancel vacation
    </Btn>
  ]

  return (
    <EmployeeVacationLogInfo
      type={vacation.type}
      employee={employee}
      vacationDays={missed_day}
      btnActions={btnActions}
    />
  )
}

export default MissedVacationLog;
