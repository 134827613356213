import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import usePagination from '../../hooks/usePagination';
import useColumns from './hooks/useColumns';
import { useDispatch } from 'react-redux';
import useSearchParams from '../../hooks/useSearchParams';
import mapUrlSearchParams from '../../utils/mapUrlSearchParams';
import { useQuery } from '@redux-requests/react';
import { Button, Table } from 'antd';
import routes from '../../constants/routes';
import { deleteHoliday, fetchHolidaysList } from '../../store/holidays/actions';

const HolidaysPage = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const {
    data,
    loading,
    load
  } = useQuery({
    type: fetchHolidaysList,
    action: fetchHolidaysList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
    autoReset: true,
  });

  const pagination = usePagination(data?.meta);
  const handleDelete = useCallback(id => (
    dispatch(deleteHoliday(id))
      .then(() => load())
  ), [dispatch, load]);

  const columns = useColumns(handleDelete);

  return (
    <>
      <div className="section-filters">
        <Link to={routes.holidays_create.path} style={{ marginLeft: 'auto' }}>
          <Button type="primary">Create</Button>
        </Link>
      </div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.data}
        pagination={pagination}
        onChange={handleChangeTable}
        rowKey="id"
        size="small"
        scroll={{ x: 1300 }}
      />
    </>
  );
};

export default HolidaysPage;
