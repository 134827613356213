import { createAction } from 'redux-smart-actions';

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchAvatarsList = createAction('AVATARS/FETCH_LIST',(searchParams = '') => ({
  request: {
    url: `/avatars?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString()
  },
}));

/**
 * @param {Object} data
 * @param {string} data.short_name
 * @param {string} data.type
 * @param {string} data.comment
 * @param {string} data.phone
 */
export const createAvatar = createAction('AVATARS/CREATE', data => ({
  request: {
    url: '/avatars',
    method: 'POST',
    data
  },
}));

/**
 * @param {number} id
 */
export const fetchAvatar = createAction('AVATARS/FETCH', id => ({
  request: {
    url: `/avatars/${id}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 * @param {Object} data
 * @param {string} data.short_name
 * @param {string} data.type
 * @param {string} data.comment
 * @param {string} data.phone
 */
export const updateAvatar = createAction('AVATARS/UPDATE', (id, data) => ({
  request: {
    url: `/avatars/${id}`,
    method: 'PUT',
    data,
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 */
export const deleteAvatar = createAction('AVATARS/DELETE', id => ({
  request: {
    url: `/avatars/${id}`,
    method: 'DELETE',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {string} query
 */
export const searchAvatarOptions = createAction('AVATARS/SEARCH_OPTIONS', (query = '', searchParams= '') => ({
  request: {
    url: `/avatars/search?query=${encodeURIComponent(query)}${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: query.toString() + searchParams.toString(),
    getData: ({ data }) => data.map(({ id, short_name }) => ({ value: id, label: short_name }))
  },
}));