import { Typography } from 'antd';
import useFormHandlers from '../../../hooks/useFormHandlers';
import routes from '../../../constants/routes';
import PasswordForm from '../../../components/PasswordForm';
import { createPassword } from '../../../store/passwords/actions';


const PasswordsCreatePage = () => {
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
      action: createPassword,
      successMessage: 'Password added successfully!',
      successPath: routes.passwords.path
  });

  return (
    <>
      <Typography.Title level={3}>Create password</Typography.Title>
      <PasswordForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
      />
    </>
  )
}

export default PasswordsCreatePage;