import { types } from '../../constants/avatars';
import useFormSubmit from '../../hooks/useFormSubmit';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';


const validationRules = {
  short_name: [
    { required: true },
    { min: 3 },
    { max: 100 },
  ],
  type: [
    { required: true }
  ],
  phone: [
    { required: true },
    { min: 5 },
    { max: 45 },
  ]
};

const AvatarForm = ({ onSubmit, onSuccess, onCancel, initialValues = null }) => {
  const [ form ] = Form.useForm();
  const { submitting, handleSubmit } = useFormSubmit(form, onSubmit, onSuccess);

  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout='vertical'
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label='Short name'
              name='short_name'
              rules={validationRules.short_name}
          >
            <Input placeholder='Short name'/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label='Phone'
              name='phone'
              rules={validationRules.phone}
          >
            <Input placeholder='Phone'/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label='Type'
              name='type'
              rules={validationRules.type}
          >
            <Select placeholder='Type' options={types}/>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
          label='Comment'
          name='comment'
      >
        <Input.TextArea placeholder='Comment' rows={3}/>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button htmlType='button' loading={submitting} onClick={onCancel}>Cancel</Button>
          <Button type='primary' htmlType='submit' loading={submitting}>Submit</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default AvatarForm;