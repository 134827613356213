import React, {useCallback, useState} from 'react';
import moment from 'moment';
import ClientForm from '../ClientForm';
import SearchSelect from '../SearchSelect';
import { PlusOutlined } from '@ant-design/icons';
import { DATE_FORMAT } from '../../constants/config';
import useFormSubmit from '../../hooks/useFormSubmit';
import {formatSelectInitialOption} from '../../helpers';
import { useDispatchRequest } from '@redux-requests/react';
import {STATUSES, statusOptions} from '../../constants/statuses';
import { searchAvatarOptions } from '../../store/avatars/actions';
import { platformOptions, typeOptions } from '../../constants/jobs';
import {searchDevOptions, searchPmOptions} from '../../store/employees/actions';
import {createClient, searchClientOptions} from '../../store/clients/actions';
import {Button, Col, DatePicker, Form, Input, Modal, notification, Row, Select, Space} from 'antd';


const validationRules = {
  title: [
    { required: true },
    { min: 3 },
    { max: 255 }
  ],
  employee_id: [
    { required: true }
  ],
  manager_id: [
    { required: true }
  ],
  avatar_id: [
    { required: true }
  ],
  type: [
    { required: true }
  ],
  platform: [
    { required: true }
  ],
  client_id: [
    { required: true }
  ],
  rate: [
    { required: true }
  ],
  im_channel_id: [
    { required: true },
    { max: 255 }
  ],
  status: [
    { required: true }
  ],
};

//TODO: Add new client modal.
const JobForm = ({ onSubmit, onSuccess, onCancel, initialValues = null }) => {
  const [ form ] = Form.useForm();
  const dispatch = useDispatchRequest();
  const { submitting, handleSubmit } = useFormSubmit(form, onSubmit, onSuccess);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const initialOptionAvatar = formatSelectInitialOption(initialValues?.avatar);
  const initialOptionClient = formatSelectInitialOption(initialValues?.client);
  const initialOptionManager = formatSelectInitialOption(initialValues?.manager);
  const initialOptionDeveloper = formatSelectInitialOption(initialValues?.employee);

  const [client, setClient] = useState(initialOptionClient);

  const showModal = () => setIsModalVisible(!isModalVisible);

  const handleOnSuccess = useCallback(() => {
    notification.success({ message: 'Client added successfully!' });
    setIsModalVisible(false);
  }, []);

  //TODO: add filter by employee type.
  const handleSearchDeveloper = useCallback(input => input.length > 1 && dispatch(searchDevOptions(input)), [ dispatch ]);
  const handleSearchManager = useCallback(input => input.length > 1 && dispatch(searchPmOptions(input)), [ dispatch ]);
  const handleSearchAvatar = useCallback(input => input.length > 1 && dispatch(searchAvatarOptions(input)), [ dispatch ]);
  const handleSearchClient = useCallback(input => input.length > 1 && dispatch(searchClientOptions(input)), [ dispatch ]);

  const handleSelectStatus = useCallback( () => {
    const status = form.getFieldValue('status');
    const endDate = form.getFieldValue('end_date');
    if ([STATUSES.ACTIVE, STATUSES.PAUSED].find(value => value === status) && endDate) {
      form.setFieldsValue({end_date: null})
    }

    if (status === STATUSES.CLOSED && !endDate) {
      form.setFieldsValue({end_date: moment()})
    }
  }, [form]);

  const handleOnSubmit = useCallback(async (values) => {
      const response = await dispatch(createClient(values));
      if (!response.error) {
        setClient(formatSelectInitialOption(response.data?.data));
        form.resetFields(['client_id']);
      }
      return response;
    },
    [dispatch, form]
  );

  return (
    <>
      <Form
        form={form}
        validateTrigger={['onBlur']}
        initialValues={{
          ...initialValues,
          employee_id: initialValues?.employee.id,
          manager_id: initialValues?.manager.id,
          avatar_id: initialValues?.avatar.id,
          client_id: client[0]?.value,
          start_date: initialValues?.start_date && moment(initialValues.start_date),
          end_date: initialValues?.end_date && moment(initialValues.end_date),
        }}
        onFinish={handleSubmit}
        layout='vertical'
      >
        <Form.Item
            label='Title'
            name='title'
            rules={validationRules.title}>
          <Input placeholder='Job title'/>
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
                label='Employee'
                name='employee_id'
                rules={validationRules.employee_id}
            >
              <SearchSelect
                placeholder='Employee'
                onSearch={handleSearchDeveloper}
                defaultOption={initialOptionDeveloper}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Manager'
                name='manager_id'
                rules={validationRules.manager_id}>
              <SearchSelect
                placeholder='Manager'
                onSearch={handleSearchManager}
                defaultOption={initialOptionManager}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Avatar'
                name='avatar_id'
                rules={validationRules.avatar_id}
            >
              <SearchSelect
                placeholder='Avatar'
                onSearch={handleSearchAvatar}
                defaultOption={initialOptionAvatar}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Type'
                name='type'
                rules={validationRules.type}
            >
              <Select
                placeholder='Type'
                options={typeOptions}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Platform'
                name='platform'
                rules={validationRules.platform}
            >
              <Select
                placeholder='Platform'
                options={platformOptions}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Client'
                name='client_id'
                rules={validationRules.client_id}>
              <SearchSelect
                placeholder='Client'
                onSearch={handleSearchClient}
                defaultOption={client}
                notFoundContent={
                  <Button
                    block
                    icon={<PlusOutlined/>}
                    onClick={showModal}
                  >
                    Add new client
                  </Button>
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
            label='Comment'
            name='comment'
        >
          <Input.TextArea placeholder='Comment' rows={3}/>
        </Form.Item>

        <Form.Item
            label='Report template'
            name='report_template'
        >
          <Input.TextArea placeholder='Report template' rows={5}/>
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
                label='Rate'
                name='rate'
                rules={validationRules.rate}
            >
              <Input placeholder='Rate'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='IM channel id'
                name='im_channel_id'
                rules={validationRules.im_channel_id}
            >
              <Input placeholder='IM channel id'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                label='Start date'
                name='start_date'
                rules={validationRules.start_date}
            >
              <DatePicker style={{ width: '100%' }} placeholder='Start date' format={DATE_FORMAT}/>
            </Form.Item>
          </Col>
          {initialValues && (<>
            <Col span={8}>
              <Form.Item
                  label='Status'
                  name='status'
                  rules={validationRules.status}
              >
                <Select
                  placeholder='Status'
                  options={statusOptions}
                  onSelect={handleSelectStatus}
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                  label='End date'
                  name='end_date'
                  rules={validationRules.end_date}>
                <DatePicker style={{ width: '100%' }} placeholder='End date' format={DATE_FORMAT}/>
              </Form.Item>
            </Col>
          </>)}
        </Row>

        <Form.Item>
          <Space>
            <Button htmlType='button' loading={submitting} onClick={onCancel}>Cancel</Button>
            <Button type='primary' htmlType='submit' loading={submitting}>Submit</Button>
          </Space>
        </Form.Item>
      </Form>

      <Modal
        title='Create client'
        visible={isModalVisible}
        footer={false}
        width={700}
        onCancel={showModal}
      >
        <ClientForm
          onSubmit={handleOnSubmit}
          onSuccess={handleOnSuccess}
          onCancel={showModal}
        />
      </Modal>
    </>
  )
};

export default JobForm;