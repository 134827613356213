import { Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import EmployeeUpdateForm from '../../../components/EmployeeUpdateForm';
import routes from '../../../constants/routes';
import useFormHandlers from '../../../hooks/useFormHandlers'
import useFormLoader from '../../../hooks/useFormLoader';
import { fetchEmployee, updateEmployee } from '../../../store/employees/actions';
import {useSelector} from "react-redux";
import {accountSelector} from "../../../store/auth/selectors";


const EmployeeEditPage = () => {
  const { id } = useParams();
  const {role} = useSelector(accountSelector);
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
    action: updateEmployee,
    successMessage: 'Employee updated successfully!',
    successPath: routes.employees_archive.path,
    actionParams: [id]
  });
  const { data, loading } = useFormLoader(fetchEmployee, id);

  return (
    <>
      <Typography.Title level={3}>Edit employee {data?.last_name} {data?.first_name}</Typography.Title>
      { loading ? (
        <Spin/>
      ) : (
        <EmployeeUpdateForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          initialValues={data}
          role={role}
        />
      ) }
    </>
  )
}

export default EmployeeEditPage;