import React, {useCallback, useState} from 'react';
import moment from 'moment';
import {formatDate} from '../../helpers';
import EmployeeVacationLogInfo from '../EmployeeVacationLogInfo';
import { BASE_URL } from '../../constants/config';
import withRole from '../../hoc/withRole';
import { ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1 } from '../../constants/roles';
import { Button } from 'antd';

const Btn = withRole([ADMIN, TECH_L1, PM_L1, PM_L2, PM_L3])(Button);

const AbsentEmployee = ({absentEmployee, onCancel, onSuccessCancel}) => {
  const [submitting, setSubmitting] = useState(false);

  const date = moment();
  const todayDate = formatDate(date);

  const {id, from, to, type, employee} = absentEmployee;

  const handleCancel = useCallback(async () => {
    const response = await onCancel(id, { date: todayDate });

    setSubmitting(false);

    if (!response?.error) {
      onSuccessCancel();
    }
  }, [todayDate, onCancel, onSuccessCancel, id]);

  const btnActions = [
    <Btn
      loading={submitting}
      type="primary"
      target="_blank"
      href={`${BASE_URL}/employees/${employee.id}/logs/create?type=${type}&date=${todayDate}`}
    >
      Log
    </Btn>,
    <Btn
      loading={submitting}
      onClick={handleCancel}
    >
      Cancel vacation
    </Btn>
  ]

  return (
    <EmployeeVacationLogInfo
      type={type}
      employee={employee}
      vacationDays={`${from} - ${to}`}
      btnActions={btnActions}
    />
  );
}

export default AbsentEmployee;