import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import routes from '../../../constants/routes';

const useColumns = onConfirmDelete => useMemo(() => ([
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: true,
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: id => (
      <Space>
        <Link to={routes.holidays_edit.path.replace(':id', id)}>
          <Button type="link" size="small" icon={<EditOutlined/>}/>
        </Link>
        <Popconfirm title="Are you sure?" onConfirm={() => onConfirmDelete(id)}>
          <Button type="link" size="small" icon={<DeleteOutlined/>}/>
        </Popconfirm>
      </Space>
    ),
    width: 90,
    align: 'center',
  }
]), [onConfirmDelete]);

export default useColumns;
