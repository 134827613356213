import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import routes from '../../../../../constants/routes';



const useColumns = () => useMemo(() => ([
  {
    title: 'Serial',
    dataIndex: 'serial',
    key: 'serial',
    render: (serial) => <Link to={routes.wh.path + '/?serial='+serial}>{serial}</Link>
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Checked at',
    dataIndex: 'checked_at',
    key: 'checked_at',
    sorter: true,
  }
]), []);


export default useColumns;