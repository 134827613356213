import { Button, Form, Input, notification } from 'antd'
import useFormSubmit from '../../hooks/useFormSubmit';


const validationRules = {
  password: [
    { required: true }
  ],
  password_confirmation: [
    { required: true },
    ({ getFieldValue }) => ({
      validator: (_, value) => (!value || getFieldValue('password') === value)
        ? Promise.resolve()
        : Promise.reject('The two passwords that you entered do not match.')
    })
  ]
};

// TODO: add required current password in API.
const MyAccountForm = ({ onSubmit, account }) => {
  const [ form ] = Form.useForm();
  const onSuccess = () => {
    notification.success({ message: 'Password updated successfully' });
    form.resetFields()
  };
  const { submitting, handleSubmit } = useFormSubmit(form, onSubmit, onSuccess);

  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      onFinish={handleSubmit}
      layout='vertical'
    >
      <Form.Item label='Email'>
        <Input placeholder='Email' value={account.email} readOnly/>
      </Form.Item>

      <Form.Item
          label='Password'
          name='password'
          rules={validationRules.password}
      >
        <Input.Password placeholder='Password'/>
      </Form.Item>

      <Form.Item
          label='Confirm password'
          name='password_confirmation'
          rules={validationRules.password_confirmation}
      >
        <Input.Password placeholder='Confirm password'/>
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit' loading={submitting} block>Save</Button>
      </Form.Item>
    </Form>
  )
}

export default MyAccountForm;