import React  from 'react';
import withRole from '../../hoc/withRole';
import { Button, List } from 'antd';
import { BASE_URL } from '../../constants/config';
import EmployeeVacationLogInfo from '../EmployeeVacationLogInfo';
import { ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1 } from '../../constants/roles';

const Btn = withRole([ADMIN, TECH_L1, PM_L1, PM_L2, PM_L3])(Button);

const AbsentEmployeeList = ({absentVacationLog}) => {
  const {id, date, type, employee} = absentVacationLog;

  const btnActions = [
    <Btn
      type='primary'
      target='_blank'
      href={`${BASE_URL}/vacations/log/${id}/edit`}
    >
      Edit
    </Btn>
  ];

  return (
    <EmployeeVacationLogInfo
      btnActions={btnActions}
      type={type}
      employee={employee}
      vacationDays={date}
    />
  );
}

export default AbsentEmployeeList;