import { createAction } from 'redux-smart-actions';

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchJobsList = createAction('JOBS/FETCH_LIST',(searchParams = '') => ({
  request: {
    url: `/jobs?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
    cache: 10,
  },
}));

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchJobsTotalList = createAction('JOBS/FETCH_LIST',(searchParams = '') => ({
  request: {
    url: `/jobs?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
    cache: 10,
    getData: data => {
      data?.data.forEach(jb => {
        jb?.work_logs.forEach(wl => {
          jb[wl.date] = wl;
        })
      });
      return data;
    }
  },
}));

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchJobsWeekList = createAction('JOBS/FETCH_LIST',(searchParams = '') => ({
  request: {
    url: `/jobs/week?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
    cache: 10,
    getData: data => {
      data?.data.forEach(jb => {
        jb?.work_logs.forEach(wl => {
          jb[wl.date] = wl;
        })
      });
      return data;
    }
  },
}));

/**
 * @param {Object} data
 * @param {string} data.title
 * @param {number} data.developer_id
 * @param {number} data.manager_id
 * @param {number} data.avatar_id
 * @param {number} data.client_id
 * @param {string} data.type
 * @param {string} data.platform
 * @param {string} data.comment
 * @param {string} data.report_template
 * @param {string} data.start_date
 * @param {number} data.rate
 * @param {string} data.im_channel_id
 */
export const createJob = createAction('JOBS/CREATE', data => ({
  request: {
    url: '/jobs',
    method: 'POST',
    data
  },
}));

/**
 * @param {number} id
 */
export const fetchJob = createAction('JOBS/FETCH', id => ({
  request: {
    url: `/jobs/${id}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 * @param {Object} data
 * @param {string} data.title
 * @param {number} data.developer_id
 * @param {number} data.manager_id
 * @param {number} data.avatar_id
 * @param {number} data.client_id
 * @param {string} data.type
 * @param {string} data.platform
 * @param {string} data.comment
 * @param {string} data.report_template
 * @param {string} data.start_date
 * @param {number} data.rate
 * @param {string} data.im_channel_id
 */
export const updateJob = createAction('JOBS/UPDATE', (id, data) => ({
  request: {
    url: `/jobs/${id}`,
    method: 'PUT',
    data,
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 */
export const deleteJob = createAction('JOBS/DELETE', id => ({
  request: {
    url: `/jobs/${id}`,
    method: 'DELETE',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

export const updateWorkLogsRate = createAction('JOBS/WORK_LOGS/RATE/UPDATE', (id, data) => ({
  request: {
    url: `/jobs/${id}/work-logs/rate`,
    method: 'PUT',
    data
  },
}));

/**
 * @param {number} id
 * @param {Object} data
 */
export const fetchWorkLogCommentsList = createAction('WORK_LOGS/COMMENTS/FETCH_LIST', id => ({
  request: {
    url: `/jobs/${id}/comments`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

export const createComment = createAction('WORK_LOGS/COMMENTS/CREATE', (id, data) => ({
  request: {
    url: `/jobs/${id}/comments`,
    method: 'POST',
    data
  },
}));
