export const STATUSES = {
  ACTIVE: 'active',
  CLOSED: 'closed',
  PAUSED: 'paused'
}

export const statusOptions = [
  {
    value: STATUSES.ACTIVE,
    label: 'Active'
  },
  {
    value: STATUSES.CLOSED,
    label: 'Closed'
  },
  {
    value: STATUSES.PAUSED,
    label: 'Paused'
  },
];