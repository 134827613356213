import { createAction } from 'redux-smart-actions';

/**
 * @param {number} id
 */
export const fetchAvatarMeta = createAction('AVATARS_META/FETCH', id => ({
  request: {
    url: `/avatars/${id}/meta`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString()
  },
}));

/**
 * @param {Object[]} data
 * @param {number} data[].id
 * @param {string} data[].platform
 * @param {string} data[].name
 * @param {string} data[].value
 */
export const saveAvatarMeta = createAction('AVATARS_META/SAVE', (id ,data) => ({
  request: {
    url: `/avatars/${id}/meta`,
    method: 'PUT',
    data
  },
}));