import authToken from './authToken';
import { apiClient } from '../store/drivers';


const uploadCSVFile = async (data) => {
  const token = authToken.get();
  const { response } = await apiClient.post(`/warehouses/files/data`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: 'multipart/form-data'
    },
  });

  return response?.data;
}

export default uploadCSVFile;