import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {getLabel, hasAccessByRole} from '../../../helpers';
import withRole from '../../../hoc/withRole';
import routes from '../../../constants/routes';
import { Button, Popconfirm, Space } from 'antd';
import {ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2} from '../../../constants/roles';
import {domains} from '../../../constants/employees';
import {DeleteOutlined, EditOutlined, LockOutlined, TagsOutlined} from '@ant-design/icons';
import PhoneCopyable from "../../../components/PhoneCopyable";


const ShortNameLink = withRole([ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2])(Link);
const useColumns = (onConfirmDelete, role) => useMemo(() => ([
  {
    title: 'Short name',
    dataIndex: 'short_name',
    key: 'short_name',
    sorter: true,
    render: (_, {id, short_name}) => <ShortNameLink to={routes.avatars_meta.path.replace(':id', id)}>{short_name}</ShortNameLink>,
    width: 220,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: true,
    render: type => getLabel(domains, type),
    width: 80,
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    sorter: true,
    render: (_, phone )  => <PhoneCopyable {...phone} />,
    width: 80,
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: id => (
      <Space>
        {hasAccessByRole([ADMIN, PM_L1], role)&&<Link to={routes.avatars_edit.path.replace(':id', id)}>
          <Button type='link' size='small' icon={<EditOutlined />}/>
        </Link>}
        {hasAccessByRole([ADMIN, PM_L1], role)&&<Popconfirm title='Are you sure?' onConfirm={() => onConfirmDelete(id)}>
          <Button type='link' size='small' icon={<DeleteOutlined />}/>
        </Popconfirm>}
        <Link to={routes.avatars_meta_edit.path.replace(':id', id)}>
          <Button type='link' size='small' icon={<TagsOutlined />}/>
        </Link>
        <Link to={routes.avatars_passwords.path.replace(':id', id)}>
          <Button type='link' size='small' icon={<LockOutlined />}/>
        </Link>
      </Space>
    ),
    width: 80,
    align: 'center',
    fixed: 'right'
  }
]), [onConfirmDelete, role]);


export default useColumns;