import { notification } from 'antd';
import { success } from '@redux-requests/core';
import { logout } from './auth/actions';
import authToken from '../utils/authToken';


export const onRequest = (request, action, store) => {
  if (!action.meta?.noAuth) {
    const token = authToken.get();
    if (token) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token}`
      }
    }
  }

  return request;
};

export const onSuccess = (response, action, store) => {
  return response;
};

export const onError = (error, action, store) => {
  // TODO: replace console.error with logger
  console.error({'interceptor onError': {error, action, store}});
  notification.error({
    message: error.response?.statusText || error.message,
    description: error.response?.data?.message
  });

  if (error.response?.status === 401) {
    authToken.clear();
    store.dispatch({ type: success(logout) });
  }

  // This error format is more convenient for use with ant.d forms.
  if (typeof error.response?.data?.errors === 'object') {
    error.response.data.errors = Object.keys(error.response.data.errors).map(name => ({
      name,
      errors: error.response.data.errors[name]
    }))
  }

  throw error;
};