import React from 'react';
import { Typography } from 'antd';
import routes from '../../../constants/routes';
import FaqForm from '../../../components/FaqForm';
import useFormHandlers from '../../../hooks/useFormHandlers';
import { createFaq } from '../../../store/faqs/actions';


const FaqsCreatePage = () => {
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
    action: createFaq,
    successMessage: 'Faq added successfully!',
    successPath: routes.faqs.path
  });

  return (
    <>
      <Typography.Title level={3}>Create faq</Typography.Title>
      <FaqForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
      />
    </>
  )
}

export default FaqsCreatePage;
