import { Typography } from 'antd';
import useFormHandlers from '../../../hooks/useFormHandlers';
import routes from '../../../constants/routes';
import { createApplicant } from '../../../store/applicants/actions';
import ApplicantForm from '../../../components/ApplicantForm';
import {useSelector} from "react-redux";
import {accountSelector} from "../../../store/auth/selectors";


const ApplicantsCreatePage = () => {
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
      action: createApplicant,
      successMessage: 'Applicant added successfully!',
      successPath: routes.applicants.path
  });
  const { role, employee:{id:uid}} = useSelector(accountSelector);
  return (
    <>
      <Typography.Title level={3}>Create applicant</Typography.Title>
      <ApplicantForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
        role={role}
        uid={uid}
      />
    </>
  )
}

export default ApplicantsCreatePage;