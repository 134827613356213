import React from 'react';

import './styles/style.scss';
import {formatDate, formatTimeFromHours, getClassNameByType, isSameDate} from "../../helpers";
import moment from "moment";
import {STATUSES} from "../../constants/statuses";
import {PLATFORMS} from "../../constants/platforms";


export const WORK_LOG_CELL_CLASSNAME = {
  WARNING_CELL: 'warning-cell',
  LACK_HOURS_CELL: 'lack-hours-cell',
  VACATION_LOG: 'vacation-log',
  EDITABLE_ROW: 'editable-row',
  WEEKEND_CELL: 'weekend-cell',
  VACATION: 'vacation',
};



export const formatToFixed = (num, fractionDigits = 2) => parseFloat(num).toFixed(fractionDigits);
export const findWorkLogByDate = (workLogs, date) => workLogs?.find(workLog => isSameDate(date, workLog.date));
export const joinSearchString = (values, arrayName) => {
  let searchString = '';
  values.map( value => searchString +=`&${arrayName}[]=${value}`);
  return searchString;
};

export const  getTotalDay = (data, day, year, week) => {
  let date =dayColDate(day, year, week);
  let total = 0;
  data.forEach(job => {
    if(job.rate && job.platform !== PLATFORMS.NOT_BILLABLE) {
      total += (job[date]?.time) ? (job[date]?.time) : 0;
    }
  })
  return formatToFixed(total);
}

export const totalTimeByWorklogs = (work_logs) => {
  let total = 0;
  work_logs.forEach(wl => {
    total += wl.time;
  })
  return total;
}
export const totalAmByWorklogs = (work_logs) => {
  let total = 0;
  work_logs.forEach(wl => {
    total += wl.time * wl.rate;
  })
  return total;
}

export const  getTotalWeek = (data) => {
  let total = 0;
  data.forEach(job => {
    if(job.rate && job.platform !== PLATFORMS.NOT_BILLABLE) {
      total += totalTimeByWorklogs(job.work_logs);
    }
  })
  return formatToFixed(total);
}
export const  getAvgRateJob = (data) => {
  let total = 0;
  let count = 0;
  data.forEach(job => {
    if(job.rate && job.status === STATUSES.ACTIVE && job.platform !== PLATFORMS.NOT_BILLABLE) {
      count++;
      total += job.rate;
    }
  });
  return formatToFixed((total / count)||0);
}
export const  getAvgRateWl = (data) => {
  let totalHr = 0;
  let totalAm = 0;
  data.forEach(job => {
    if(job.rate && job.platform !== PLATFORMS.NOT_BILLABLE) {
      totalHr += totalTimeByWorklogs(job.work_logs);
      totalAm += totalAmByWorklogs(job.work_logs);
    }
  });
  return formatToFixed((totalAm / totalHr)||0);
}

export const avgWeeklyRate = workLogs => {
  let totalHr = totalTimeByWorklogs(workLogs);
  let totalAm = totalAmByWorklogs(workLogs);
  return formatToFixed((totalAm / totalHr)||0);
};

export const getTotalDayConsolidated = (data, day, year, week) => {
  let date =dayColDate(day, year, week);
  let total = 0;
  data.forEach(group => {
    group?.data.forEach(job => {
      if(job.rate && job.platform !== PLATFORMS.NOT_BILLABLE) {
        total += (job[date]?.time) ? (job[date]?.time) : 0;
      }
    });
  })
  return formatToFixed(total);
}

export const getTotalWeekConsolidated = (data) => {
  let total = 0;
  data.forEach(group => {
    group?.data.forEach(job => {
      if(job.rate && job.platform !== PLATFORMS.NOT_BILLABLE) {
        total += totalTimeByWorklogs(job.work_logs);
      }
    });
  });
  return formatToFixed(total);
}

export const getAvgRateJobConsolidated = (data) => {
  let total = 0;
  let count = 0;
  data.forEach(group => {
    group?.data.forEach(job => {
      if(job.rate && job.status === STATUSES.ACTIVE && job.platform !== PLATFORMS.NOT_BILLABLE) {
        count++;
        total += job.rate;
      }
    });
  });
  return formatToFixed((total / count)||0);
}
export const getAvgRateWlConsolidated = (data) => {
  let totalHr = 0;
  let totalAm = 0;
  data.forEach(group => {
    group?.data.forEach(job => {
      if(job.rate && job.platform !== PLATFORMS.NOT_BILLABLE) {
        totalHr += totalTimeByWorklogs(job.work_logs);
        totalAm += totalAmByWorklogs(job.work_logs);
      }
    });
  });
  return formatToFixed(totalAm / totalHr);
}


export const dayColMoment = (n, year, week) => moment().isoWeekYear(parseInt(year)).isoWeekday(n).isoWeek(parseInt(week));
export const dayColName = (n, year, week) => dayColMoment(n, year, week).format('DD.MM')
export const dayColDate = (n, year, week) => formatDate(dayColMoment(n, year, week));
export const logBgColor = (n, record, year, week, holidays) => {
  let warningClass;
  let vacationClass;
  let editableClass;
  let log = record[formatDate(moment().day(n).year(parseInt(year)).isoWeek(parseInt(week)))];
  let date = dayColMoment(n, year, week);
  const employeeVacationLog = record.employee.vacation_logs?.find(vacationLog => date.isSame(vacationLog.date, 'day'));

  const foundHoliday = holidays?.data?.find( holiday => date.isSame(holiday.date, 'day'));
  if(!log) {
    warningClass = WORK_LOG_CELL_CLASSNAME.WARNING_CELL;
  }
  if(log?.time <= 3) {
    warningClass = WORK_LOG_CELL_CLASSNAME.LACK_HOURS_CELL;
  }
  if(log?.vacation_log) {

    vacationClass = WORK_LOG_CELL_CLASSNAME.VACATION_LOG + ' ' + getClassNameByType(log.vacation_log.type);
  }
  if(employeeVacationLog) {
    vacationClass = WORK_LOG_CELL_CLASSNAME.VACATION_LOG + ' ' + getClassNameByType(employeeVacationLog.type)
  }
  if(
    moment(date).isBefore(moment(record.start_date).add(1, 'days'), 'day')
    || moment(date).isAfter(moment(record.end_date).subtract(1, 'days'), 'day')
  ) {
    warningClass = '';
  }
  if(foundHoliday) {
    warningClass = '';
  }
  if([0, 6].includes(date.day())) {
    warningClass = '';
  }
  if(moment().subtract(1, 'days').isBefore(date, 'day')) {
    warningClass = '';
  }
  editableClass = WORK_LOG_CELL_CLASSNAME.EDITABLE_ROW
  return warningClass + ' ' + vacationClass + ' ' + editableClass;
}

export const shouldFill = (children, record , date) => {
  let fill = true;
  if(moment(date).isBefore(moment(record.start_date), 'day')) {
    fill = false
  }
  if(record.end_date && moment(date).isAfter(moment(record.end_date), 'day')) {
    fill = false
  }
  if(moment().isBefore(date, 'day')) {
    fill = false
  }
  return fill;
}

export const dayCell = (n, year, week, holidays) => {
  return {
    title: () => dayColName(n, year, week),
    dataIndex: [dayColDate(n, year, week), 'time'],
    width: 110,
    editable: true,
    date: dayColDate(n, year, week),
    align: 'center',
    render(children, record) {
      let date = dayColMoment(n, year, week);
      let fill = shouldFill(children, record , date)

      if(!children && fill) {
        children = formatTimeFromHours(0);
      }
      return {
        props: {
          className: logBgColor(n, record, year, week, holidays)
        },
        children: children
      };
    }
  }
}