import styled from 'styled-components';
import { Card } from 'antd';


const CardMeta = styled(Card.Meta)`
  .ant-card-meta-avatar {
    float: right;
    padding-left: 16px;
    padding-right: 0;
  }
`;

export default CardMeta;