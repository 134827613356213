import { createAction } from 'redux-smart-actions';

/**
 * @param {URLSearchParams} [searchParams]
 */
export const fetchVacationsList = createAction('VACATIONS/FETCH_LIST',(searchParams = '') => ({
  request: {
    url: `/scheduled-vacations?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
    cache: 5,
  },
}));

/**
 * @param {Object} data
 * @param {number} data.employee_id
 * @param {string} data.type
 * @param {string} data.from
 * @param {string} data.to
 * @param {string} data.comment
 */
export const createVacation = createAction('VACATIONS/CREATE', data => ({
  request: {
    url: '/scheduled-vacations',
    method: 'POST',
    data
  },
}));

/**
 * @param {number} id
 */
export const fetchVacation = createAction('VACATIONS/FETCH', id => ({
  request: {
    url: `/scheduled-vacations/${id}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));


/**
 * @param {number} id
 * @param {Object} data
 * @param {number} data.employee_id
 * @param {string} data.type
 * @param {string} data.from
 * @param {string} data.to
 * @param {string} data.comment
 */
export const updateVacation = createAction('VACATIONS/UPDATE', (id, data) => ({
  request: {
    url: `/scheduled-vacations/${id}`,
    method: 'PUT',
    data,
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 */
export const deleteVacation = createAction('VACATIONS/DELETE', id => ({
  request: {
    url: `/scheduled-vacations/${id}`,
    method: 'DELETE',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 * @param {string} data
 */
export const cancelVacation = createAction('VACATIONS/CANCEL', (id, data) => ({
  request: {
    url: `/scheduled-vacations/${id}/cancel`,
    method: 'POST',
    data
  },
  meta: {
    requestKey: id.toString(),
  },
}));