import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { handleRequests } from '@redux-requests/core';
import { onRequest, onSuccess, onError } from './interceptors';
import { apiDriver } from './drivers';
import authReducer from './auth/reducer';
import { logout } from './auth/actions';


export const configureStore = () => {
  const { requestsReducer, requestsMiddleware } = handleRequests({
    driver: apiDriver,
    onRequest,
    onSuccess,
    onError,
    cache: true,
  });

  const allReducers = combineReducers({
    requests: requestsReducer,
    auth: authReducer,
  });

  const rootReducer = (state, action) => {
    if (action.type === logout.toString()) {
      state = undefined;
    }

    return allReducers(state, action);
  };

  const composeEnhancers = (window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  return createStore(rootReducer, composeEnhancers(applyMiddleware(...requestsMiddleware)));
};