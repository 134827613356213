import { Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import routes from '../../../constants/routes';
import AvatarForm from '../../../components/AvatarForm';
import useFormHandlers from '../../../hooks/useFormHandlers';
import { fetchAvatar, updateAvatar } from '../../../store/avatars/actions';
import useFormLoader from "../../../hooks/useFormLoader";


const AvatarsEditPage = () => {
  const { id } = useParams();
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
    action: updateAvatar,
    successMessage: 'Avatar updated successfully!',
    successPath: routes.avatars.path,
    actionParams: [id]
  });
  const { data, loading } = useFormLoader(fetchAvatar, id);

  return (
    <>
      <Typography.Title level={3}>Edit avatar</Typography.Title>
      { loading ? (
        <Spin/>
      ) : (
        <AvatarForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          initialValues={data}
        />
      ) }
    </>
  )
}

export default AvatarsEditPage;