import { Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import routes from '../../../../constants/routes';
import JobForm from '../../../../components/JobForm';
import useFormLoader from '../../../../hooks/useFormLoader';
import useFormHandlers from '../../../../hooks/useFormHandlers';
import { fetchJob, updateJob } from '../../../../store/jobs/actions';

const JobsEditPage = () => {
  const { id } = useParams();
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
    action: updateJob,
    successMessage: 'Job updated successfully!',
    successPath: routes.work_log_week_simple.path,
    actionParams: [id]
  });
  const { data, loading } = useFormLoader(fetchJob, id);

  return (
    <>
      <Typography.Title level={3}>Edit job</Typography.Title>
      { loading ? (
        <Spin/>
      ) : (
        <JobForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          initialValues={data}
        />
      ) }
    </>
  )
}

export default JobsEditPage;