import useFormSubmit from '../../hooks/useFormSubmit';
import { Button, Col, Form, Input, Row, Space } from 'antd';


const validationRules = {
  first_name:  [
    { required: true },
    { min: 1 },
    { max: 100 }
  ],
  last_name:  [
    { required: true },
    { min: 1 },
    { max: 100 }
  ],
  redmine_link: [
    { required: true },
    { min: 3 },
    { max: 255 }
  ],
};

const ClientForm = ({ onSubmit, onSuccess, onCancel, initialValues = null }) => {
  const [ form ] = Form.useForm();
  const { submitting, handleSubmit } = useFormSubmit(form, onSubmit, onSuccess);

  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout='vertical'
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label='First name'
              name='first_name'
              rules={validationRules.first_name}
          >
            <Input placeholder='First name'/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label='Last name'
              name='last_name'
              rules={validationRules.last_name}
          >
            <Input placeholder='Last name'/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label='Redmine link'
              name='redmine_link'
              rules={validationRules.redmine_link}
          >
            <Input placeholder='Redmine link'/>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Space>
          <Button htmlType='button' loading={submitting} onClick={onCancel}>Cancel</Button>
          <Button type='primary' htmlType='submit' loading={submitting}>Submit</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default ClientForm;