import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useSearchParams = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search.substring(1)), [location.search]);

  const setSearchParams = useCallback((params) => {
    for (const [name, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        searchParams.delete(name);
        value.sort().map(v => searchParams.append(name, v.toString()));
      }
      else if (![undefined, null].includes(value)) {
        searchParams.set(name, value.toString());
      }
      else {
        searchParams.delete(name);
      }
    }
    searchParams.sort();
    const search = searchParams.toString().replaceAll('%5B', '[').replaceAll('%5D', ']');
    history.replace(location.pathname + location.hash + '?' + search);
  }, [history, location.hash, location.pathname, searchParams]);

  return [searchParams, setSearchParams];
};

export default useSearchParams;