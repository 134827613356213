import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Input, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useMutation } from '@redux-requests/react';
import routes from '../../constants/routes';
import { login } from '../../store/auth/actions';


const validationRules = {
  email: [
    { required: true, message: 'Please input your Email!' },
    { type: 'email', message: 'The input is not valid E-mail!' }
  ],
  password: [
    { required: true, message: 'Please input your Password!' }
  ]
};

const LoginPage = () => {
  const dispatch = useDispatch();
  const [ form ] = Form.useForm();
  const { loading, error } = useMutation({ type: login.toString() });
  const onFinish = useCallback(values => dispatch(login(values)), [dispatch]);

  useEffect(() => {
    if (error?.response?.data?.errors) {
      form.setFields(error.response.data.errors.map(error => ({ ...error, value: form.getFieldValue(error.name) })));
    }
  }, [error, form]);

  return (
    <>
      <Typography.Title>Login</Typography.Title>
      <Form
        form={form}
        name='login-form'
        validateTrigger={['onBlur']}
        onFinish={onFinish}
      >
        <Form.Item
          name='email'
          rules={validationRules.email}
        >
          <Input placeholder='E-mail'/>
        </Form.Item>

        <Form.Item
          name='password'
          rules={validationRules.password}
        >
          <Input.Password placeholder='Password'/>
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loading} block>Log in</Button>
        </Form.Item>

        <Form.Item>
          <Link to={routes.auth_forgot_password.path}>Forgot password</Link>
        </Form.Item>
      </Form>
    </>
  )
};

export default LoginPage;