import React, {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import withRole from '../../hoc/withRole';
import SearchSelect from '../SearchSelect';
import routes from '../../constants/routes';
import {useQuery} from '@redux-requests/react';
import {Button, Select, Space, Form} from 'antd';
import {statusOptions} from '../../constants/statuses';
import {searchClientOptions} from '../../store/clients/actions';
import {searchAvatarOptions} from '../../store/avatars/actions';
import {ADMIN, PM_L1, PM_L2, PM_L3} from '../../constants/roles';
import {joinSearchString} from '../../pages/work_log/tableHelpers';
import {
  searchChiefsOptions,
  searchDevOptions,
  searchEmployeeOptions,
  searchPmOptions
} from '../../store/employees/actions';
import {groupByOptions, platformOptions, typeOptions} from '../../constants/jobs';


const CreateJobLink = withRole([ADMIN, PM_L1, PM_L2, PM_L3])(Link);
const WorkLogTableFilter = ({searchParams, setSearchParams, rightSectionFilters, simple}) => {
  const query = '';
  const dispatch = useDispatch();

  const arrayOfAvatarIds = searchParams.getAll('avatar_ids[]');
  const arrayOfClientIds = searchParams.getAll('client_ids[]');
  const arrayOfManagerIds = searchParams.getAll('manager_ids[]');
  const arrayOfEmployeeIds = searchParams.getAll('employee_ids[]');
  const arrayOfOfficerIds = searchParams.getAll('officer_ids[]');

  const stringAvatarIds = useMemo(() => joinSearchString(arrayOfAvatarIds, 'avatar_ids'), [arrayOfAvatarIds]);
  const stringClientIds = useMemo(() => joinSearchString(arrayOfClientIds, 'client_ids'), [arrayOfClientIds]);
  const stringManagerIds = useMemo(() => joinSearchString(arrayOfManagerIds, 'manager_ids'), [arrayOfManagerIds]);
  const stringEmployeeIds = useMemo(() => joinSearchString(arrayOfEmployeeIds, 'employee_ids'), [arrayOfEmployeeIds]);
  const stringOfficerIds = useMemo(() => joinSearchString(arrayOfOfficerIds, 'officer_ids'), [arrayOfOfficerIds]);

  const newAvatarIds = useMemo(() => arrayOfAvatarIds.map(id => (+id)), [arrayOfAvatarIds]);
  const newClientIds = useMemo(() => arrayOfClientIds.map(id => (+id)), [arrayOfClientIds]);
  const newManagerIds = useMemo(() => arrayOfManagerIds.map(id => (+id)), [arrayOfManagerIds]);
  const newOfficerIds = useMemo(() => arrayOfOfficerIds.map( id => (+id)), [arrayOfOfficerIds]);

  const {data: avatars} = useQuery({
    type: searchAvatarOptions,
    action: searchAvatarOptions,
    variables: [query, stringAvatarIds],
    requestKey: query + stringAvatarIds,
    autoLoad: !!arrayOfAvatarIds.length
  });

  const {data: clients} = useQuery({
    type: searchClientOptions,
    action: searchClientOptions,
    variables: [query, stringClientIds],
    requestKey: query + stringClientIds,
    autoLoad: !!arrayOfClientIds.length,
  });

  const {data: managers} = useQuery({
    type: searchPmOptions,
    action: searchPmOptions,
    variables: [query, stringManagerIds],
    requestKey: query + stringManagerIds,
    autoLoad: true,
  });

  const {data: employees} = useQuery({
    type: searchDevOptions,
    action: searchDevOptions,
    variables: [query, stringEmployeeIds],
    requestKey: query + stringEmployeeIds,
    autoLoad: true,
  });

  const { data: officers } = useQuery({
    type: searchChiefsOptions,
    action: searchChiefsOptions,
    variables: [query, stringOfficerIds],
    requestKey: query + stringOfficerIds,
    autoLoad: true,
  });

  const handleSearchOfficer = useCallback(input => input.length > 1 && dispatch(searchEmployeeOptions(input)), [dispatch]);
  const handleChangeOfficer = useCallback(employee_ids => setSearchParams({ 'officer_ids[]': employee_ids }), [setSearchParams]);

  const handleSearchAvatar = useCallback(input => input.length > 1 && dispatch(searchAvatarOptions(input)), [dispatch]);
  const handleSearchClient = useCallback(input => input.length > 1 && dispatch(searchClientOptions(input)), [dispatch]);
  const handleSearchManager = useCallback(input => input.length > 1 && dispatch(searchPmOptions(input)), [dispatch]);

  const handleChangeAvatar = useCallback(avatar_ids => setSearchParams({'avatar_ids[]': avatar_ids}), [setSearchParams]);
  const handleChangeClient = useCallback(client_ids => setSearchParams({'client_ids[]': client_ids}), [setSearchParams]);
  const handleChangeManager = useCallback(manager_ids => setSearchParams({'manager_ids[]': manager_ids}), [setSearchParams]);
  const handleChangeDeveloper = useCallback(employee_ids => setSearchParams({'employee_ids[]': employee_ids}), [setSearchParams]);

  const handleChangeTypes = useCallback(types => setSearchParams({'types[]': types}), [setSearchParams]);
  const handleChangeJobStatuses = useCallback(statuses => setSearchParams({'statuses[]': statuses}), [setSearchParams]);
  const handleChangePlatforms = useCallback(platforms => setSearchParams({'platforms[]': platforms}), [setSearchParams]);
  const handleChangeGroupBy = useCallback(group_by => setSearchParams({group_by}), [setSearchParams]);

  return (
    <div className='section-filters'>
      <Form
        layout="inline"
        className="components-table-demo-control-bar"
      >
        <Form.Item>
          <SearchSelect
            mode='multiple'
            placeholder='Avatar'
            onSearch={handleSearchAvatar}
            onChange={handleChangeAvatar}
            value={newAvatarIds}
            defaultOption={avatars}
            style={{width: 300}}
          />
        </Form.Item>
        <Form.Item>
          <SearchSelect
            mode='multiple'
            placeholder='Client'
            onSearch={handleSearchClient}
            onChange={handleChangeClient}
            value={newClientIds}
            defaultOption={clients}
            style={{width: 300}}
          />
        </Form.Item>
        <Form.Item>
          <SearchSelect
            mode='multiple'
            placeholder='Manager'
            onSearch={handleSearchManager}
            onChange={handleChangeManager}
            value={newManagerIds}
            defaultOption={managers}
            style={{width: 300}}
          />
        </Form.Item>
        <Form.Item>
          <Select
            mode='multiple'
            placeholder='Employee'
            options={employees}
            notFoundContent={null}
            filterOption={true}
            optionFilterProp='label'
            allowClear
            onChange={handleChangeDeveloper}
            style={{ width: 220 }}
          />
        </Form.Item>
        <Form.Item>
          <SearchSelect
            mode='multiple'
            placeholder='Officer'
            onSearch={handleSearchOfficer}
            onChange={handleChangeOfficer}
            value={newOfficerIds}
            defaultOption={officers}
            style={{ width: 220 }}
          />
        </Form.Item>
        <Form.Item>
          <Select
            placeholder='Job status'
            value={searchParams.getAll('statuses[]')}
            options={statusOptions}
            onChange={handleChangeJobStatuses}
            mode='multiple'
            maxTagCount='responsive'
            style={{width: 300}}
            allowClear
          />
        </Form.Item>
        <Form.Item>
          <Select
            placeholder='Type'
            value={searchParams.getAll('types[]')}
            options={typeOptions}
            onChange={handleChangeTypes}
            mode='multiple'
            maxTagCount='responsive'
            style={{width: 300}}
            allowClear
          />
        </Form.Item>
        <Form.Item>
          <Select
            placeholder='Platform'
            value={searchParams.getAll('platforms[]')}
            options={platformOptions}
            onChange={handleChangePlatforms}
            mode='multiple'
            maxTagCount='responsive'
            style={{width: 300}}
            allowClear
          />
        </Form.Item>
        {!simple && <Form.Item>
          <Select
            placeholder='Group by'
            value={searchParams.get('group_by')}
            options={groupByOptions}
            onChange={handleChangeGroupBy}
            style={{width: 300}}
          />
        </Form.Item>}
      </Form>
      <Space>
        {rightSectionFilters}
        <CreateJobLink to={routes.jobs_create.path}>
          <Button type='primary'>Create</Button>
        </CreateJobLink>
      </Space>
    </div>
  )
};

export default React.memo(WorkLogTableFilter);