import React, {useCallback, useEffect} from 'react';
import moment from 'moment';
import {Button, DatePicker, Descriptions, Menu, Popover, Space, Table, Typography} from 'antd';
import {Link, useParams} from 'react-router-dom';
import {useQuery} from '@redux-requests/react';
import {
  avgWeeklyRate,
  formatToFixed, getAvgRateJob, getAvgRateWl, getTotalWeek,
} from '../../work_log/tableHelpers';
import { platformOptions} from '../../../constants/jobs';
import useFormLoader from '../../../hooks/useFormLoader';
import { fetchJobsTotalList} from '../../../store/jobs/actions';
import {fetchAvatar} from '../../../store/avatars/actions';
import useSearchParams from '../../../hooks/useSearchParams';
import mapUrlSearchParams from '../../../utils/mapUrlSearchParams';
import {fetchAvatarMeta} from '../../../store/avatars_meta/actions';
import {formatDate, getDate, getLabel, getStartOfMonth, hasAccessByRole} from '../../../helpers';
import AvatarMetaTableFilter from '../../../components/AvatarMetaTableFilter';
import './styles.scss';
import routes from "../../../constants/routes";
import {ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2} from "../../../constants/roles";
import withRole from "../../../hoc/withRole";
import {CommentOutlined, EditOutlined, InfoCircleOutlined, LinkOutlined, TagsOutlined} from "@ant-design/icons";
import {domains} from "../../../constants/employees";
import {IM_BASE_URL} from "../../../constants/config";
import {useSelector} from "react-redux";
import {accountSelector} from "../../../store/auth/selectors";


const avatarMetaColumns = [
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    fixed: 'left',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
];

const { RangePicker } = DatePicker;
const AvatarsMetaPage = () => {
  const {id} = useParams();
  const [ searchParams, setSearchParams ] = useSearchParams();

  /* hook add default dates range (current week) */
  useEffect(() => {
    if (!searchParams.has('start_date') || !searchParams.has('end_date')) {
      setSearchParams({start_date: formatDate(getStartOfMonth()), end_date: formatDate(moment())});
    }
  }, [searchParams, setSearchParams]);
  const { role } = useSelector(accountSelector);
  const hasAccess = hasAccessByRole([ADMIN, PM_L1, PM_L2, PM_L3], role);
  const hasAccessAdmin = hasAccessByRole([ADMIN, PM_L1], role);
  const startDate = searchParams.get('start_date');
  const endDate = searchParams.get('end_date');

  /* filter onChange */
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const handleChangeRangePicker = useCallback(
    ([startDate, endDate]) => {
      setSearchParams({start_date: formatDate(startDate), end_date: formatDate(endDate)})
    },
    [setSearchParams]
  );

  const { data: avatar } = useFormLoader(fetchAvatar, id);
  const {data: avatarMeta, loading: avatarMetaLoading} = useFormLoader(fetchAvatarMeta, id);

  const { data: jobsList, loading: jobListLoading } = useQuery({
    type: fetchJobsTotalList,
    action: fetchJobsTotalList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
  });
  useEffect(()=>setSearchParams({ 'avatar_ids[]': id }), []);
  const AvatarLink = avatar => {
    const { role } = useSelector(accountSelector);
    return hasAccessByRole([ADMIN, PM_L1, PM_L2, PM_L3, TECH_L1, TECH_L2], role) ? (
      <Link to={routes.avatars_meta.path.replace(':id', avatar.id)}>{avatar.short_name}</Link>) : avatar.short_name;
  };

  const EditJobLink = (props) => {
    const { role } = useSelector(accountSelector);
    return hasAccessByRole([ADMIN, PM_L1, PM_L2, PM_L3], role) ? (<Link {...props}>{props.children}</Link>) : null;
  };
  let columns = [
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      sorter: true,
      width: 85,
      render: (platform) => getLabel(platformOptions, platform)
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      width: 75,
      render: (type) => getLabel(domains, type),
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      sorter: true,
      width: 110,
      render: avatar => AvatarLink(avatar),
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      sorter: true,
      render: client => client?.full_name ?? '',
      width: 110,
    },
    {
      title: 'Job name',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      width: 140,
    },
    {
      title: 'Manager',
      dataIndex: 'manager',
      key: 'manager',
      sorter: true,
      render: manager => (
        manager && <Link to={`${routes.employees_view.path.replace(':id', manager.id)}`}>{manager?.full_name}</Link>
      ),
      width: 110,
    },
    {
      title: 'Employee',
      dataIndex: 'employee',
      key: 'employee',
      sorter: true,
      render: employee => (
        employee && <Link to={`${routes.employees_view.path.replace(':id', employee.id)}`}>{employee?.full_name}</Link>
      ),
      width: 110,
    },
    {
      title: 'Total',
      dataIndex: 'work_logs',
      key: 'total',
      render: (work_logs) => {
        return formatToFixed(work_logs.reduce((total, { time }) => total + time, 0));
      },
      width: 50,
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: 55,
      align: 'center',
    },
    {
      title: ' Avg',
      dataIndex: 'rate',
      key: 'rate',
      render: (rate, {work_logs}) => {
        return avgWeeklyRate(work_logs);
      },
      width: 50,
      align: 'center',
      className: 'hidden',
      hidden: true
    },
    {
      title: ' Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (rate) => {
        return formatToFixed(rate);
      },
      width: 50,
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'actions',
      render: (id, { cipher_link, im_channel_id, origin_id, comment,
        start_date, end_date}) => {
        if (cipher_link) {
          return (
            <Space>
              <EditJobLink to={routes.jobs_edit.path.replace(':id', origin_id ?? id)}>
                <Button type='link' size='small' icon={<EditOutlined />} />
              </EditJobLink>
              <Link to={routes.employee_reports.path.replace(':cipher', cipher_link)}>
                <Button type='link' size='small' icon={<LinkOutlined />} />
              </Link>
              <Button type='link' size='small' icon={<CommentOutlined/>}
                      href={`${IM_BASE_URL}/channels/${im_channel_id}`} />
              <Popover content={<Descriptions style={{ width: 500 }} size="small" layout="vertical" bordered>
                <Descriptions.Item label="Comment" span={3}>{comment}</Descriptions.Item>
                <Descriptions.Item label="Start date">{start_date}</Descriptions.Item>
                <Descriptions.Item label="End date">{end_date}</Descriptions.Item>
              </Descriptions>} title="Job Info" trigger="click"  placement="left">
                <Button type='text' size='small' icon={<InfoCircleOutlined />} />
              </Popover>
            </Space>
          );
        }
      },
      width: 120,
      align: 'center',
      fixed: 'right'
    }
  ]
  if(!hasAccess) {
    columns = columns.filter(col => col.dataIndex !== 'rate')
  }
  const EditAvatarLink = withRole([ADMIN, PM_L1])(Link);

  return (
    <>
      <Descriptions column={4}
                    title={
                      <Typography.Title level={3} >{avatar?.short_name}</Typography.Title>
                    }
                    extra={
                      <>
                        <EditAvatarLink to={routes.avatars_edit.path.replace(':id', id)}>
                          <Button type='primary'>Edit</Button>
                        </EditAvatarLink>
                        <Link to={routes.avatars_meta_edit.path.replace(':id', id)}>
                          <Button type='link' icon={<TagsOutlined />}/>
                        </Link>
                      </>
                    }
                    bordered
      />
      <Table
        columns={avatarMetaColumns}
        loading={avatarMetaLoading}
        dataSource={avatarMeta}
        pagination={false}
        rowKey='id'
        size='small'
        scroll={{ x: 650 }}
      />
      <Menu selectedKeys={['jobs']} mode="horizontal">
        <Menu.Item key="jobs">
          <Link to={routes.avatars_meta.path.replace(':id', id)}>
            Jobs
          </Link>
        </Menu.Item>
        <Menu.Item key="passwords">
          <Link to={routes.avatars_passwords.path.replace(':id', id)}>
            Passwords
          </Link>
        </Menu.Item>
      </Menu>
      <AvatarMetaTableFilter
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        rightSectionFilters={
          <RangePicker style={{width: 225}} value={[getDate(startDate), getDate(endDate)]} onChange={handleChangeRangePicker} />
        }
      />
      <Table
        columns={columns}
        loading={jobListLoading}
        dataSource={jobsList?.data}
        pagination={false}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
        scroll={{x: 1300, y: 1200}}
        bordered
        summary={data => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={7}>Total</Table.Summary.Cell>
                <Table.Summary.Cell>
                  {getTotalWeek(data)}
                </Table.Summary.Cell>
                <Table.Summary.Cell />
                {hasAccess && <Table.Summary.Cell>
                  {hasAccessAdmin && getAvgRateJob(data)}
                </Table.Summary.Cell>}
                {hasAccess && <Table.Summary.Cell>
                  {hasAccessAdmin && getAvgRateWl(data)}
                </Table.Summary.Cell>}
                <Table.Summary.Cell />
              </Table.Summary.Row>
            </>
          );
        }}
        className='worklog_table'
      />
    </>
  );
};

export default AvatarsMetaPage;