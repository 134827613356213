import React, {useCallback} from 'react';
import { useQuery } from '@redux-requests/react';
import {Table} from 'antd';
import useColumns from './hooks/useColumns';

import usePagination from '../../../hooks/usePagination'
import useSearchParams from '../../../hooks/useSearchParams';
import mapUrlSearchParams from '../../../utils/mapUrlSearchParams'
import {
  fetchWithBalancesEmployeeList
} from '../../../store/employees/actions';



const EmployeesBalancesPage = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();

  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  const { data, loading } = useQuery({
    type: fetchWithBalancesEmployeeList,
    action: fetchWithBalancesEmployeeList,
    variables: [searchParams],
    requestKey: searchParams.toString(),
    autoLoad: true,
  });
  const pagination = usePagination(data?.meta);
  const columns = useColumns();

  return (
    <>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.data}
        pagination={pagination}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
        scroll={{ x: 1300 }}
      />
    </>
  )
};

export default EmployeesBalancesPage;