import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../../constants/routes';


const useColumns = () => useMemo(() => ([
  {
    title: 'First name',
    dataIndex: 'first_name',
    key: 'first_name',
    sorter: true,
    render: (first_name, { id }) => <Link to={routes.employees_view.path.replace(':id', id)}>{first_name}</Link>
  },
  {
    title: 'Last name',
    dataIndex: 'last_name',
    key: 'last_name',
    sorter: true,
    render: (last_name, { id }) => <Link to={routes.employees_view.path.replace(':id', id)}>{last_name}</Link>
  },
  {
    title: 'vacations',
    dataIndex: 'vacation_balance',
    key: 'vacation_balance',
    filters: [
      {
        text: '0 - 5',
        value: [0, 5],
      },
      {
        text: '5 - 10',
        value: [5, 10],
      },
      {
        text: '10 - 15',
        value: [10, 15],
      },
      {
        text: '15 - 20',
        value: [15, 20],
      },
      {
        text: '> 20',
        value: [20, 1000],
      },
    ],
    onFilter: (value, record) => {
      return record.vacation_balance > value[0] && record.vacation_balance < value[1];
    },
  },
  {
    title: 'sickleaves',
    dataIndex: 'sickleave_balance',
    key: 'sickleave_balance',
    filters: [
      {
        text: '0',
        value: 0,
      },
      {
        text: '1',
        value: 1,
      },
      {
        text: '2',
        value: 2,
      },
      {
        text: '3',
        value: 3,
      },
      {
        text: '4',
        value: 4,
      },
      {
        text: '5',
        value: 5,
      },
    ],
    onFilter: (value, record) => {
      return record.sickleave_balance === value;
    },
  },

]), []);


export default useColumns;