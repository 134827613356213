import { Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import routes from '../../../../constants/routes';
import useFormHandlers from '../../../../hooks/useFormHandlers';
import useFormLoader from '../../../../hooks/useFormLoader';
import VacationLogForm from '../../../../components/VacationLogForm';
import { fetchVacationLog, updateVacationLog } from '../../../../store/vacation_logs/actions';


const VacationsLogEditPage = () => {
  const { id } = useParams();
  const [ handleSubmit, handleSuccess, handleCancel ] = useFormHandlers({
    action: updateVacationLog,
    successMessage: 'Log vacation updated successfully!',
    successPath: routes.vacations_log.path,
    actionParams: [id]
  });
  const { data, loading } = useFormLoader(fetchVacationLog, id);

  return (
    <>
      <Typography.Title level={3}>Log vacation</Typography.Title>
      { loading ? (
        <Spin/>
      ) : (
        <VacationLogForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          initialValues={data}
        />
      ) }
    </>
  )
}

export default VacationsLogEditPage;
