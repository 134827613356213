import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import CellView from './CellView';
import {useDispatch} from 'react-redux';
import {EditableContext} from '../../../pages/work_log/week_simple';
import {createWorkLog, updateWorkLog} from '../../../store/work_logs/actions';
import {convertTimeInHours, formatDate, formatTimeFromHours} from '../../../helpers';
import {findWorkLogByDate, shouldFill} from '../../../pages/work_log/tableHelpers';


const EditableCell = ({
                        date,
                        editable,
                        children,
                        dataIndex,
                        record,
                        ...restProps
                      }) => {
  const newDate = moment(date);
  const dispatch = useDispatch();

  const {form} = useContext(EditableContext);

  const foundWorkLog = findWorkLogByDate(record?.work_logs, newDate);

  const [editing, setEditing] = useState(false);
  const [jobComments, setJobComments] = useState([]);
  const [workLog, setWorkLog] = useState(foundWorkLog);

  let newRestProps = {...restProps};

  useEffect(() => {
    if (record?.comments) {
      const filteredComments = record.comments.filter( comment => comment.date === date);
      setJobComments(filteredComments);
    }
  }, [date, record?.comments])

  const toggleEdit = () => {
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    });
    setEditing(!editing);
  };

  const handleStore = async (time) => {
    try {
      return await dispatch(createWorkLog(record.id, {
        time: convertTimeInHours(time),
        date: formatDate(moment(date))
      }));
    } catch (errInfo) {
      console.log('Store failed:', errInfo);
    }
  };

  const handleUpdate = async (time) => {
    try {
      return await dispatch(updateWorkLog(workLog.id, {
        rate: workLog.rate,
        employee_id: workLog.employee.id,
        manager_id: workLog.manager.id,
        time: convertTimeInHours(time)
      }));
    } catch (errInfo) {
      console.log('Update failed:', errInfo);
    }
  };

  const handleAction = async () => {
    const isFieldTouched = form.isFieldTouched(dataIndex);

    if (!isFieldTouched) {
      return toggleEdit();
    }

    let time = form.getFieldValue(dataIndex);

    if(!workLog && time.format('HH:mm') === '00:00') {
      setEditing(!editing);
      return ;
    }

    const {data, error} = workLog ? await handleUpdate(time) : await handleStore(time);
    const response = data?.data;

    if (response) {
      response.time = formatTimeFromHours(response.time)
      setEditing(!editing);
      setWorkLog(response);
    }

    if (error?.response?.data?.errors) {
      form.setFields(error.response.data.errors.map(error => ({...error, value: form.getFieldValue(error.name)})));
    }
  }

  let childNode = children;
  const isEditableCell = record && shouldFill(children,record,date);

  if (isEditableCell) {
    childNode = <CellView
      jobComments={jobComments}
      setJobComments={setJobComments}
      date={date}
      setWorkLog={setWorkLog}
      workLog={workLog}
      job={record}
      childNode={childNode}
      editing={editing}
      dataIndex={dataIndex}
      onToggleEdit={toggleEdit}
      onAction={handleAction}
    />;
  }

  return <td {...newRestProps}>{childNode}</td>;
};

export default EditableCell;