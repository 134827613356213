import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../../constants/routes';
import Password from '../../../../components/Password';
import { Button, Popconfirm, Space, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';


const useColumns = (onConfirmDelete, role) => useMemo(() => ([
  {
    title: 'Url',
    dataIndex: 'url',
    key: 'url',
    sorter: true,
  },
  {
    title: 'Login',
    dataIndex: 'login',
    key: 'login',
    sorter: true,
    render: login => <Typography.Text copyable>{login}</Typography.Text>
  },
  {
    title: 'Password',
    dataIndex: 'password',
    key: 'password',
    render: (_, password ) => <Password {...password} />
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: (id ) => (
      <Space>
        <Link to={routes.passwords_edit.path.replace(':id', id)}>
          <Button type='link' size='small' icon={<EditOutlined />}/>
        </Link>
        <Popconfirm title='Are you sure?' onConfirm={() => onConfirmDelete(id)}>
          <Button type='link' size='small' icon={<DeleteOutlined />}/>
        </Popconfirm>
      </Space>
    ),
    width: 125,
    align: 'center',
    fixed: 'right'
  }
]), [onConfirmDelete, role]);


export default useColumns;