import { createAction } from 'redux-smart-actions';

/**
 * @param {URLSearchParams} searchParams
 */
export const fetchEmployeeList = createAction('EMPLOYEES/FETCH_LIST', (searchParams = '') => ({
  request: {
    url: `/employees?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
  },
}));

export const fetchDeletedEmployeesList = createAction('EMPLOYEES/FETCH_DELETED_LIST',(searchParams = '') => ({
  request: {
    url: `/employees?archive=1&${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
  },
}));

/**
 * @param {URLSearchParams} searchParams
 */
export const fetchWithBalancesEmployeeList = createAction('EMPLOYEES/FETCH_LIST', (searchParams = '') => ({
  request: {
    url: `/employees-balances?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
  },
}));


/**
 * @param {URLSearchParams} searchParams
 */
export const fetchWithWorklogsEmployeeList = createAction('EMPLOYEES/FETCH_WORKLOG_LIST', (searchParams = '') => ({
  request: {
    url: `/employees-overtimes?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: searchParams.toString(),
  },
}));

/**
 * @param {Object} data
 * @param {string} data.first_name
 * @param {string} data.last_name
 * @param {string} data.im_nickname
 * @param {string} data.email
 * @param {string} data.phone
 * @param {string} data.domain
 * @param {string} data.comment
 * @param {string} data.birthday
 * @param {string} data.started_at
 * @param {string} data.officer
 * @param {string} data.chief_officer
 */
export const createEmployee = createAction('EMPLOYEES/CREATE', data => ({
  request: {
    url: '/employees',
    method: 'POST',
    data
  },
}));

export const fetchEmployee = createAction('EMPLOYEES/FETCH', id => ({
  request: {
    url: `/employees/${id}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

export const updateEmployee = createAction('EMPLOYEES/UPDATE', (id, data) => ({
  request: {
    url: `/employees/${id}`,
    method: 'POST',
    data,
  },
  meta: {
    requestKey: id.toString(),
  },
}));

export const deleteEmployee = createAction('EMPLOYEES/DELETE', id => ({
  request: {
    url: `/employees/${id}`,
    method: 'DELETE',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {string} query
 */
export const searchEmployeeOptions = createAction('EMPLOYEES/ALL/SEARCH_OPTIONS', (query = '', searchParams= '') => ({
  request: {
    url: `/employees/search?query=${encodeURIComponent(query)}${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: query.toString() + searchParams.toString(),
    getData: ({ data }) => data.map( employee => ({...employee, value: employee.id, label: `${employee.first_name} ${employee.last_name}` }))
  },
}));

/**
 * @param {string} query
 */
export const searchOfficeManagerOptions = createAction('EMPLOYEES/OM/SEARCH_OPTIONS', (query = '', searchParams= '') => ({
  request: {
    url: `/employees/search?domains[]=om&query=${encodeURIComponent(query)}${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: query.toString() + searchParams.toString(),
    getData: ({ data }) => data.map( employee => ({...employee, value: employee.id, label: `${employee.first_name} ${employee.last_name}` }))
  },
}));

/**
 * @param {string} query
 */
export const searchPmOptions = createAction('EMPLOYEES/PM/SEARCH_OPTIONS', (query = '', searchParams= '') => ({
  request: {
    url: `/employees/search?domains[]=pm&query=${encodeURIComponent(query)}${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: query.toString() + searchParams.toString(),
    getData: ({ data }) => data.map( employee => ({...employee, value: employee.id, label: `${employee.first_name} ${employee.last_name}` }))
  },
}));

/**
 * @param {string} query
 */
export const searchChiefsOptions = createAction('EMPLOYEES/CHIEFS/SEARCH_OPTIONS', (query = '', searchParams= '') => ({
  request: {
    url: `/employees/search?roles[]=board_member&roles[]=cbdo&roles[]=ceo&roles[]=coo&roles[]=cto&roles[]=head_of_hr&roles[]=head_of_qa&roles[]=team_lead&query=${encodeURIComponent(query)}${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: query.toString() + searchParams.toString(),
    getData: ({ data }) => data.map( employee => ({...employee, value: employee.id, label: `${employee.first_name} ${employee.last_name}` }))
  },
}));

/**
 * @param {string} query
 */
export const searchDevOptions = createAction('EMPLOYEES/DEV/SEARCH_OPTIONS', (query = '', searchParams= '') => ({
  request: {
    url: `/employees/search?domains[]=developer&domains[]=qa&domains[]=designer&query=${encodeURIComponent(query)}${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: query.toString() + searchParams.toString(),
    getData: ({ data }) => data.map( employee => ({...employee, value: employee.id, label: `${employee.first_name} ${employee.last_name}` }))
  },
}));

export const fetchEmployeePersonalData = createAction('EMPLOYEES/PERSONAL_DATA/CIPHER', cipher => ({
  request: {
    url: `/profile/${cipher}/personal-data`,
    method: 'GET',
  },
  meta: {
    requestKey: cipher,
    getData: ({ data }) => data
  },
}));

export const fetchEmployeeFaqs = createAction('EMPLOYEES/FAQS/CIPHER', cipher => ({
  request: {
    url: `/profile/${cipher}/faqs`,
    method: 'GET',
  },
  meta: {
    requestKey: cipher,
    getData: ({ data }) => data
  },
}));

export const createAndUpdateEmployeeFaq = createAction('EMPLOYEES/FAQS/ACTION', (id, cipher) => ({
  request: {
    url: `/profile/${cipher}/faqs/${id}`,
    method: 'POST',
  },
}));

export const fetchEmployeeVacationBalanceByCipher = createAction('EMPLOYEES/VACATION_BALANCE/CIPHER', cipher => ({
  request: {
    url: `/profile/${cipher}/vacation-balance`,
    method: 'GET',
  },
  meta: {
    requestKey: cipher,
    getData: ({ data }) => data
  },
}));

export const fetchEmployeeSickLeaveBalanceByCipher = createAction('EMPLOYEES/SICK_LEAVE/CIPHER', cipher => ({
  request: {
    url: `/profile/${cipher}/sick-leave-balance`,
    method: 'GET',
  },
  meta: {
    requestKey: cipher,
    getData: ({ data }) => data
  },
}));

export const fetchEmployeeCommentsList = createAction('EMPLOYEES/COMMENTS/FETCH_LIST', (id, searchParams= '') => ({
  request: {
    url: `employees/${id}/comments?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString() + searchParams.toString(),
  },
}));

export const createComment = createAction('EMPLOYEES/COMMENTS/CREATE', (id, data) => ({
  request: {
    url: `employees/${id}/comments`,
    method: 'POST',
    data
  },
}));

export const fetchEmployeeVacationBalance = createAction('EMPLOYEES/VACATION_BALANCE/FETCH', id => ({
  request: {
    url: `/employees/${id}/vacation-balance`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
    getData: ({ data }) => data
  },
}));

export const fetchEmployeeSickLeaveBalance = createAction('EMPLOYEES/SICK_LEAVE_BALANCE/FETCH', id => ({
  request: {
    url: `/employees/${id}/sick-leave-balance`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
    getData: ({ data }) => data
  },
}));

export const fetchEmployeeVacationLogsList = createAction('EMPLOYEES/VACATION_LOGS/FETCH_LIST', (id, searchParams = '') => ({
  request: {
    url: `/employees/${id}/vacation-logs?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString() + searchParams.toString(),
  },
}));


export const fetchJobList = createAction('EMPLOYEES/JOBS/FETCH_LIST', id => ({
  request: {
    url: `/employees/${id}/jobs`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));

/**
 * @param {number} id
 * @param {URLSearchParams} searchParams
 */
export const fetchEmployeeVacationsList = createAction('EMPLOYEES/VACATIONS/FETCH_LIST', (id, searchParams = '') => ({
  request: {
    url: `/employees/${id}/scheduled-vacations?${searchParams}`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString() + searchParams.toString(),
    cache: 5,
    getData: ({ data }) => (data)
  },
}));

export const fetchAbsentEmployeeList = createAction('EMPLOYEES/ABSENT/FETCH_LIST', date => ({
  request: {
    url: `/employees/absent?date=${date}`,
    method: 'GET',
  }
}));

export const fetchWarehousesList = createAction('EMPLOYEES/WAREHOUSES/FETCH_LIST', id => ({
  request: {
    url: `/employees/${id}/warehouses`,
    method: 'GET',
  },
  meta: {
    requestKey: id.toString(),
  },
}));