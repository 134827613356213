import { Typography } from 'antd';
import { useParams } from 'react-router-dom';
import routes from '../../constants/routes';
import { newPassword } from '../../store/auth/actions';
import useFormHandlers from '../../hooks/useFormHandlers';
import ResetPasswordForm from '../../components/ResetPasswordForm';


const ResetPasswordPage = () => {
  const { token } = useParams();
  const [ handleSubmit, handleSuccess ] = useFormHandlers({
    action: newPassword,
    successMessage: 'Password updated, you can log in!',
    successPath: routes.auth_login.path
  });

  return (
    <>
      <Typography.Title level={3}>New password</Typography.Title>
      <ResetPasswordForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        token={token}
      />
    </>
  )
}

export default ResetPasswordPage;