import { Col, Row } from 'antd';

const PublicLayout = ({ children }) => (
  <Row type='flex' justify='center' align='middle' style={{ minHeight: '100vh' }}>
    <Col xxl={4} lg={6} md={8} xs={20}>
      {children}
    </Col>
  </Row>
);

export default PublicLayout;