import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import { Button, Typography, Space } from 'antd';
import {decryptPassword} from '../../store/passwords/actions';
import copyToClipboard from 'clipboard-copy';
import {CheckOutlined, CopyOutlined, EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';


// TODO: Add decrypt and master pass ui.
const Password = (props) => {
  const dispatch = useDispatch();
  const [ password, setPassword] = useState('');
  const [ visible, setVisible ] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleVisibleChange = useCallback(() => setVisible(visible => !visible), [setVisible]);
  const handleCopiedChange = useCallback(() => setCopied(copied => !copied), [setCopied]);
  const handleDispatch = useCallback(() => dispatch(decryptPassword(props.id)), [dispatch, props.id]);

  useEffect(() => {
    if (props?.password) {
      setPassword(props?.password);
    }
  }, [props?.password]);

  const handleClick = async() => {
    if (!visible) {
      const {data, error} = await handleDispatch();
      if (!error && data) {
        setPassword(data.data.password);
      }
    }
    handleVisibleChange();
  };

  const handleCopyable = async () => {
    handleCopiedChange();
    const {data, error} = await handleDispatch();
    if (!error && data) {
      setPassword(data.data.password);
      await copyToClipboard(data.data.password);
      setTimeout(() => handleCopiedChange(), 2800);
    }
  };

  return (
    <>
      <Space>
        <Typography.Text code={visible && password}>
          {visible && password ? password : '*****'}
        </Typography.Text>
          {copied
            ? <CheckOutlined style={{color: '#52c41a'}} />
            : <CopyOutlined onClick={handleCopyable} style={{color: '#1890ff'}} />
          }
      </Space>
      <Button
        type='link'
        size='small'
        onClick={handleClick}
        icon={ visible ? <EyeInvisibleOutlined /> : <EyeOutlined /> }/>
    </>
  );
}

export default Password;