import { useMemo } from 'react';
import { APP_TABLE_DEFAULT_SIZE } from '../constants/config';


const usePagination = data => useMemo(() => ({
  current: data?.current_page,
  pageSize: data?.per_page,
  total: data?.total,
  defaultPageSize: APP_TABLE_DEFAULT_SIZE,
  pageSizeOptions: [ APP_TABLE_DEFAULT_SIZE, 50, 100 ]
}), [ data?.current_page, data?.per_page, data?.total ]);


export default usePagination;