import React, { useMemo } from 'react';
import {Link} from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import routes from '../../../../constants/routes';
import LinkSpecial from '../../../../components/LinkSpecial';


const useColumns = (onConfirmRestore) => useMemo(() => ([
  {
    title: 'Employee',
    dataIndex: 'employee',
    key: 'employee',
    sorter: true,
    render: employee => <Link to={routes.employees_view.path.replace(':id', employee?.id)}>{employee?.full_name}</Link>
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    render: email => <LinkSpecial mode='email'>{email}</LinkSpecial>
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    sorter: true,
  },
  {
    title: 'Deleted at',
    dataIndex: 'deleted_at',
    key: 'deleted_at',
    sorter: true,
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: id => (
      <Popconfirm title='Are you sure?' onConfirm={() => onConfirmRestore(id)}>
        <Button type='link' size='small' icon={<UndoOutlined />}/>
      </Popconfirm>
    ),
    width: 65,
    align: 'center',
  }
]), [onConfirmRestore]);


export default useColumns;