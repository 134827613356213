import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { IM_BASE_URL } from '../../constants/config';
import {LinkOutlined} from "@ant-design/icons";


const LinkSpecial = ({ children, mode, ...otherProps }) => {
  const [href, text] = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (mode) {
      case 'email':
        return ['mailto:' + children, children]
      case 'phone':
        return ['tel:' + children, children]
      case 'im':
        return [`${IM_BASE_URL}/messages/@` + children, `@${children}`]
      case 'personal':
        return ['/personal-info/' + children, <LinkOutlined />]
    }
  }, [children, mode])

  return <a type='link' href={href} {...otherProps}>{text}</a>;
};

LinkSpecial.propTypes = {
  children: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['email', 'phone', 'im', 'personal']).isRequired,
}

export default LinkSpecial;