import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import useColumns from './hooks/useColumns';
import routes from '../../constants/routes';
import { useQuery } from '@redux-requests/react';
import {STATUSES} from '../../constants/statuses';
import { CommentOutlined } from '@ant-design/icons';
import { IM_BASE_URL } from '../../constants/config';
import ReportForm from '../../components/ReportForm';
import usePagination from '../../hooks/usePagination';
import { useHistory, useParams } from 'react-router-dom';
import useSearchParams from '../../hooks/useSearchParams';
import mapUrlSearchParams from '../../utils/mapUrlSearchParams';
import { Table, Spin, Typography, Descriptions, Button, notification } from 'antd';
import { createReport, fetchJob, fetchReport, fetchReportsList, updateReport } from '../../store/reports/actions';
import {formatDate} from '../../helpers';


const StyledDescriptions = styled(Descriptions)`
  padding-bottom: 15px;
`;
// TODO: can change report date?
const ReportsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentReport, setCurrentReport] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { cipher, id } = useParams();

  const { data: job, loading: jobLoading, pristine: jobPristine } = useQuery({
    type: fetchJob,
    action: fetchJob,
    variables: [cipher],
    requestKey: cipher,
    autoLoad: true,
  });
  const { data, loading, load } = useQuery({
    type: fetchReportsList,
    action: fetchReportsList,
    variables: [cipher, searchParams],
    requestKey: cipher + searchParams.toString(),
    autoLoad: true,
  });
  const handleSubmit = useCallback(
    data => dispatch(id ? updateReport(cipher, id, data) : createReport(cipher, data)),
    [id, cipher, dispatch]
  );
  const handleSuccess = useCallback(() => {
    setCurrentReport(null);
    (() => load())();
    let message = 'Report added successfully!';
    if (id) {
      message = 'Report updated successfully!';
      history.push(routes.employee_reports.path.replace(':cipher', cipher));
    }
    notification.success({ message });
  }, [id, cipher, history, load]);

  const jobOpened = job?.status === STATUSES.ACTIVE;
  const columns = useColumns(job, cipher, jobOpened);
  const pagination = usePagination(data?.meta);
  const handleChangeTable = useCallback(
    (pagination, filters, sorter) => setSearchParams(mapUrlSearchParams(pagination, filters, sorter)),
    [setSearchParams]
  );

  useEffect(() => {
    if (id) {
      (async () => {
        const { data } = await dispatch(fetchReport(cipher, id));
        setCurrentReport(data);
      })();
    } else {
      setCurrentReport({report: job?.report_template});
    }
  },[id, cipher, dispatch, job]);

  if (jobLoading || jobPristine) {
    return <Spin size='large'/>
  }

  return (
    <>
      <Typography.Title>{currentReport && id
        ? `Update report from ${formatDate(moment(currentReport.date))}`
        : 'Send report'}</Typography.Title>
      <StyledDescriptions column={8} layout='vertical' size='small' bordered>
        <Descriptions.Item label='Platform'>{job.platform}</Descriptions.Item>
        <Descriptions.Item label='Type'>{job.type}</Descriptions.Item>
        <Descriptions.Item label='Profile'>{job.avatar.short_name}</Descriptions.Item>
        <Descriptions.Item label='Client'>{job.client.full_name}</Descriptions.Item>
        <Descriptions.Item label='Job name'>{job.title}</Descriptions.Item>
        <Descriptions.Item label='Manager'>{job.manager.full_name}</Descriptions.Item>
        <Descriptions.Item label='Employee'>{job.employee.full_name}</Descriptions.Item>
        <Descriptions.Item label='IM channel'>
          <Button
            type='link'
            size='small'
            href={`${IM_BASE_URL}/messages/channels/${job.im_channel_id}`}
            icon={<CommentOutlined/>}
          />
        </Descriptions.Item>
      </StyledDescriptions>
      {jobOpened && <ReportForm
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        initialValues={currentReport}
      />}
      <Table
        bordered
        showHeader={false}
        columns={columns}
        loading={loading}
        dataSource={data?.data}
        pagination={pagination}
        onChange={handleChangeTable}
        rowKey='id'
        size='small'
      />
    </>
  )
};

export default ReportsPage;
