import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu, notification } from 'antd';
import CommentForm from '../WorkLogDropDown/CommentForm';
import { createComment } from '../../store/jobs/actions';
import { hasAccessByRole } from '../../helpers';
import { accountSelector } from '../../store/auth/selectors';
import TooltipComments from '../WorkLogDropDown/TooltipComments';
import { ADMIN, PM_L1, PM_L2, PM_L3 } from '../../constants/roles';
import _ from 'lodash';
import { StyledDropdownContextMenu } from '../WorkLogDropDown/styles/styles';


const DailyDropdown = ({job, date, childNode, onToggleEdit, jobComments, setJobComments}) => {
  const dispatch = useDispatch();
  const [isModalVisible, setModalVisible] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const { role } = useSelector(accountSelector);

  const handleShowModal = useCallback(() => setModalVisible(!isModalVisible), [isModalVisible]);
  const handleVisibleChange = useCallback(flag => setDropdownVisible(flag), [setDropdownVisible]);
  const handleSubmit = useCallback(async (value) => {
    const {
      data,
      error
    } = await dispatch(createComment(job.id, {...value, date }));

    const comment = data?.data;
    if (!error && comment) {
      const newComments = jobComments?.slice() || [];
      newComments.push(comment);
      setJobComments(newComments);
    }

    return error;
  }, [dispatch, job.id, date, jobComments, setJobComments]);

  const handleSuccess = useCallback(() => notification.success({ message: 'Comment added successfully!' }), []);

  const commentsOrderByDesc = useMemo(() => _.orderBy(jobComments, 'created', 'desc'), [jobComments]);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <CommentForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
        />
      </Menu.Item>
    </Menu>
  )

  const hasAccess = hasAccessByRole([ADMIN, PM_L1, PM_L2, PM_L3], role);

  return (
    <div className='editable-cell-value-wrap'>
      <Dropdown
        disabled={!hasAccess}
        overlay={menu}
        visible={isDropdownVisible}
        onVisibleChange={handleVisibleChange}
        trigger={['contextMenu']}
        onClick={onToggleEdit}
      >
        <StyledDropdownContextMenu>
          {childNode}
        </StyledDropdownContextMenu>
      </Dropdown>
      { (hasAccess && !!jobComments?.length) && <TooltipComments
        comments={jobComments}
        handleShowModal={handleShowModal}
        commentsOrderByDesc={commentsOrderByDesc}
      />}
    </div>
  )
}

export default React.memo(DailyDropdown);