import { useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import debounce from 'lodash/debounce';

// TODO: add props type check.
const SearchSelect = ({ onSearch, defaultOption, ...rest }) => {
  const [ searchValue, setSearchValue ] = useState('');
  const [ options, setOptions ] = useState(defaultOption ?? []);
  const [ loading, setLoading ] = useState(false);

  const debouncedOnSearch = useMemo(() => debounce(input => {
    setLoading(true);
    onSearch(input)
      .then(({ data }) => setOptions(data))
      .catch()
      .finally(() => setLoading(false));
  }, 800), [ onSearch ]);

  useEffect(() => {
    if (searchValue?.length > 1) {
      debouncedOnSearch(searchValue);
    } else {
      defaultOption?.length ? setOptions(defaultOption) : setOptions([]);
    }
  }, [searchValue, debouncedOnSearch, defaultOption]);

  return (
    <Select
      searchValue={searchValue}
      options={options}
      onSearch={setSearchValue}
      loading={loading}
      notFoundContent={null}
      filterOption={false}
      allowClear
      showSearch
      {...rest}
    />
  );
};

export default SearchSelect;